import Header from "components/ui/common/Header";
import React from "react";
import { numberFormat } from "utils/fmt";
import { useNavigate } from "react-router-dom";

const CompleteOrNotPayment = ({
  handleGoMain,
  selectedBill,
  setIsBillDetail,
  setIsSummaryPayment,
  setIsCardCheckPayment,
  setIsCompleteOrNotPayment,
  billInfoChange,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    setIsCompleteOrNotPayment(false);
    setIsCardCheckPayment(true);
  };

  const handleGoFirstPayMent = () => {
    setIsCompleteOrNotPayment(false);
    setIsCardCheckPayment(false);
    setIsSummaryPayment(false);
    setIsBillDetail(true);
  };

  return (
    <div className="wrapper payment-complete">
      <Header Title="관리비 결제" handleGoBack={handleGoBack} structure={"menuHome"} />
      <div className="content pt60">
        {billInfoChange === "PayComplete" && (
          <div className="title-box">
            <i className="ico-complete mb10"></i>
            <h2>
              <span className="month">{selectedBill.billMonth.substring(4, 6)}</span>월 관리비
              <br />
              <strong className="main-color">{numberFormat(selectedBill.billSum)}</strong>원을
              <br />
              카드로 결제하였습니다.
            </h2>
          </div>
        )}

        {billInfoChange === "PayFailed" && (
          <div className="title-box ">
            <i className="ico-fail mb10"></i>
            <h2>
              <span className="month">{selectedBill.billMonth.substring(4, 6)}</span>월 관리비
              <br />
              결제가 완료되지 않았습니다.
            </h2>
          </div>
        )}

        <div className="button-box mt30">
          <button
            type="button"
            className="btn1 type3 sub-color"
            onClick={() => {
              setIsCompleteOrNotPayment(false);
              localStorage.setItem("isCompleteOrNotPayment", JSON.stringify(true));
              navigate("/paymentHistory");
            }}
          >
            결제내역관리로 이동
          </button>
        </div>
      </div>

      <div className="fixed-button-box">
        <div className="button-box">
          {billInfoChange === "PayComplete" && (
            <button type="button" className="btn1 main-color" onClick={handleGoMain}>
              처음으로
            </button>
          )}

          {billInfoChange === "PayFailed" && (
            <button type="button" className="btn1 main-color" onClick={handleGoFirstPayMent}>
              다시 결제
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteOrNotPayment;
