import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import BoardNoticeDetail from "./BoardNoticeDetail";
import Header from "components/ui/common/Header";
import { numberFormat } from "utils/fmt";
import { useNavigate } from "react-router-dom";

const SellBuy = ({
  receivedState,
  title,
  setTitle,
  boardNm,
  setBoardNm,
  boardType,
  setBoardType,
  boardList,
  setDetailBoardCd,
  setIsEnrollBoard,
  setIsCommonBoardDetail,
  setCheckEdit,
  set_show_bottom_sheet,
  set_show_search_box,
  inViewRef,
  setModalMsg,
  setIsAlert,
  dt,
  order_by,
  is_loading,
  set_is_loading,
}) => {
  const navigate = useNavigate();
  const [boardNotice, setBoardNotice] = useState([]);
  const [isBoardNoticeDetail, setIsBoardNoticeDetail] = useState(false);
  const [noticeBoardCd, setNoticeBoardCd] = useState("");

  //게시판공지 api
  const handleGetBoardNotice = async () => {
    try {
      const response = await request(PATH.게시판공지, {
        boardNm: boardNm,
        lastBoardCd: "",
      });
      if (response.data.code === "0000") {
        setBoardNotice(response.data.boardNotice);
      } else if (response.data.code !== "0000" && !receivedState && boardNm !== "") {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const pageChange = (page) => {
    if (window.ReactNativeWebView) {
      const message = { navigate: page };
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      navigate("/" + page);
    }
  };

  const handleGoBack = () => {
    if (receivedState) {
      pageChange("main");
    } else {
      setTitle("");
    }
  };

  useEffect(() => {
    if (title === "팝니다") {
      setBoardNm("벼룩시장");
      setBoardType("팝니다");
    } else if (title === "삽니다") {
      setBoardNm("벼룩시장");
      setBoardType("삽니다");
    }
  }, [title]);

  useEffect(() => {
    handleGetBoardNotice();
  }, [boardNm, boardType]);

  return (
    <React.Fragment>
      {isBoardNoticeDetail ? (
        <BoardNoticeDetail
          noticeBoardCd={noticeBoardCd}
          setNoticeBoardCd={setNoticeBoardCd}
          setIsBoardNoticeDetail={setIsBoardNoticeDetail}
          is_loading={is_loading}
          set_is_loading={set_is_loading}
        />
      ) : (
        <div className="wrapper community">
          <Header Title={title} handleGoBack={handleGoBack} />
          <div className="content p0">
            <div className="notice-community">
              {boardNotice &&
                boardNotice.map((v, i) => (
                  <div
                    key={i}
                    className="item"
                    onClick={() => {
                      setNoticeBoardCd(v.boardCd);
                      setIsBoardNoticeDetail(true);
                    }}
                  >
                    <i className="ico-notice"></i>
                    <p>{v.boardTitle}</p>
                  </div>
                ))}
            </div>

            <div className="filter-bar">
              <div className="left-box">
                <ul className="txt-box">
                  <li>{dt}</li>
                  <li>{order_by === "DESC" ? "최신순" : "과거순"}</li>
                </ul>
              </div>
              <div className="right-box justify-between">
                <button
                  type="button"
                  className="ico-filter"
                  onClick={() => {
                    set_show_bottom_sheet(true);
                  }}
                ></button>
                <button
                  type="button"
                  className="ico-search"
                  onClick={() => {
                    set_show_search_box(true);
                  }}
                ></button>
              </div>
            </div>

            <div className="community-list community-list2">
              {boardList.length > 0 ? (
                boardList.map((v, i) => (
                  <div
                    key={i}
                    className="list-box"
                    onClick={() => {
                      setDetailBoardCd(v.boardCd);
                      setIsCommonBoardDetail(true);
                    }}
                  >
                    {v.boardPic && v.boardPic !== "" ? (
                      <img alt="이미지" src={v.boardPic} />
                    ) : (
                      <img alt="이미지" style={{ filter: "brightness(0) invert(1)" }} />
                    )}

                    <div className="desc-box">
                      <div className="txt-box">
                        <p className="tit txt1">{v.boardTitle}</p>
                        <p className="txt2">
                          {v.regDtm.substring(0, 4)}.{v.regDtm.substring(5, 7)}.{v.regDtm.substring(8, 10)}{" "}
                          {v.regDtm.substring(11, 16)}
                        </p>
                        {boardNm === "벼룩시장" && boardType === "팝니다" && (
                          <p className="price txt3">{numberFormat(v.boardAmount)}원</p>
                        )}
                      </div>
                      <ul className="num-box">
                        <li>
                          <i className="ico-view"></i>
                          {v.viewCount}
                        </li>
                        <li>
                          <i className="ico-comment"></i>
                          {v.refCount}
                        </li>
                      </ul>
                    </div>
                  </div>
                ))
              ) : boardList.length === 0 && !is_loading ? (
                <div className="no-content">
                  <i className="ico-no-content"></i>
                  <p>조회하신 내역이 없습니다.</p>
                </div>
              ) : null}
            </div>
            <div ref={inViewRef} style={{ height: "1px" }} />

            <div className="writing-btn">
              <button
                type="button"
                className="flex-center"
                onClick={() => {
                  setIsEnrollBoard(true);
                  setCheckEdit(false);
                }}
              >
                <i className="ico-plus-white"></i>
                글쓰기
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SellBuy;
