import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertImage from "components/ui/common/AlertImage";
import BoardBottomModal from "components/ui/modal/BoardBottomModal";
import Header from "components/ui/common/Header";
import imageCompression from "browser-image-compression";
import { numberFormat } from "utils/fmt";

const EnrollBoard = ({
  boardNm,
  boardType,
  setBoardType,
  boardListDetail,
  checkEdit,
  setCheckEdit,
  setIsEnrollBoard,
  setIsAlert,
  setModalMsg,
  detailBoardCd,
  set_is_loading,
}) => {
  const [boardCategory, setBoardCategory] = useState("");
  const [area, setArea] = useState("");
  const [boardTitle, setBoardTitle] = useState("");
  const [boardAmount, setBoardAmount] = useState("");
  const [boardContent, setBoardContent] = useState("");

  const [isCategoryType, setIsCategoryType] = useState(false);
  const [isCategoryBottomSheet, setIsCategoryBottomSheet] = useState(false);
  const [isAreaType, setIsAreaType] = useState(false);
  const [isAreaBottomSheet, setIsAreaBottomSheet] = useState(false);
  const [enrollTitle, setEnrollTitle] = useState("등록");

  const [img_list, set_img_list] = useState([]);
  const [is_modal_img, set_is_modal_img] = useState(false);

  //게시글등록 api
  const handleRegBoard = async () => {
    if (boardNm === "구인구직" && boardType === "") {
      setIsAlert(true);
      setModalMsg("분류선택을 해주세요.");
    } else if (boardNm === "구인구직" && area === "") {
      setIsAlert(true);
      setModalMsg("지역선택을 해주세요.");
    } else if (boardNm !== "구인구직" && boardType !== "삽니다" && boardType !== "팝니다" && boardCategory === "") {
      setIsAlert(true);
      setModalMsg("분류선택을 해주세요.");
    } else if (
      boardNm !== "자유게시판" &&
      boardNm !== "구인구직" &&
      boardType !== "삽니다" &&
      boardType !== "팝니다" &&
      area === ""
    ) {
      setIsAlert(true);
      setModalMsg("지역선택을 해주세요.");
    } else {
      try {
        console.log("handleRegBoard");
        set_is_loading(true);
        let new_img_list = [];
        for (let i = 0; i < img_list.length; i++) {
          if (img_list[i].pic_url.indexOf("data:image") === -1) {
            new_img_list.push(img_list[i].pic_url);
          } else {
            let fileObj = img_list[i].pic_url.split(",");
            const img_response = await request(PATH.uploadImage, {
              locate: "userInfo",
              base64Img: [{ type: "image/jpg", data: fileObj[1] }],
            });
            // console.log("handleUpdateUserPic", [img_response.data]);
            if (img_response.data.code !== "0000") {
              setIsAlert(true);
              setModalMsg(img_response.data.message);
              throw new Error("이미지 업로드 에러");
            } else if (img_response.data.code === "0000") {
              new_img_list.push(img_response.data.savedPathArr[0]);
            }
          }
        }
        const response = await request(PATH.regBoard, {
          boardNm: boardNm,
          boardType: boardType,
          boardTitle: boardTitle,
          boardContent: boardContent,
          boardAmount: boardAmount,
          area: area,
          boardCategory: boardNm === "구인구직" ? boardType : boardCategory,
          imgUrl: new_img_list,
        });

        if (response.data.code === "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
          handleCloseBtn();
        } else {
          setIsAlert(true);
          setModalMsg(response.data.message);
        }
      } catch (error) {
        console.error(error);
      } finally {
        set_is_loading(false);
      }
    }
  };

  //게시물수정 api
  const handleUpdateBoard = async () => {
    try {
      console.log("handleUpdateBoard");
      set_is_loading(true);
      let new_img_list = [];
      for (let i = 0; i < img_list.length; i++) {
        if (img_list[i].pic_url.indexOf("data:image") === -1) {
          new_img_list.push(img_list[i].pic_url);
        } else {
          let fileObj = img_list[i].pic_url.split(",");
          const img_response = await request(PATH.uploadImage, {
            locate: "userInfo",
            base64Img: [{ type: "image/jpg", data: fileObj[1] }],
          });
          // console.log("handleUpdateUserPic", [img_response.data]);
          if (img_response.data.code !== "0000") {
            setIsAlert(true);
            setModalMsg(img_response.data.message);
            throw new Error("이미지 업로드 에러");
          } else if (img_response.data.code === "0000") {
            new_img_list.push(img_response.data.savedPathArr[0]);
          }
        }
      }

      const response = await request(PATH.게시물수정, {
        boardCd: detailBoardCd,
        boardNm: boardNm,
        boardType: boardType,
        boardTitle: boardTitle,
        boardContent: boardContent,
        boardAmount: boardAmount,
        area: area,
        boardCategory: boardNm === "구인구직" ? boardType : boardCategory,
        imgUrl: new_img_list,
      });
      console.log(response);
      if (response.data.code === "0000") {
        setIsAlert(true);
        setModalMsg(response.data.message);
        handleCloseBtn();
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const setImage = async (e) => {
    console.log("이미지 변경");
    const selectedFile = e.target.files[0];

    const options = {
      maxSizeMB: 2, // 2MB 미만으로 압축
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      set_is_loading(true);
      const compressedFile = await imageCompression(selectedFile, options);
      const compressedBase64 = await convertBlobToBase64(compressedFile);
      let seq = 1;
      if (img_list.length > 0) {
        seq = img_list[img_list.length - 1].seq + 1;
      }
      set_img_list((prevPushList) => [...prevPushList, ...[{ seq: seq, pic_url: compressedBase64 }]]);
    } catch (error) {
      console.error("이미지 압축 실패:", error);
    } finally {
      set_is_loading(false);
      set_is_modal_img(false);
    }
  };

  const handleBoardTitleChange = (e) => {
    let input = e.target.value;
    if (input.length > 40) {
      input = input.slice(0, 40);
    }
    setBoardTitle(input);
  };

  const handleBoardAmountChange = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");
    if (input.length > 16) {
      input = input.slice(0, 16);
    }
    setBoardAmount(input);
  };

  const handleBoardContentChange = (e) => {
    let input = e.target.value;
    if (input.length > 1000) {
      input = input.slice(0, 1000);
    }
    setBoardContent(input);
  };

  const handleCloseBtn = () => {
    setBoardTitle("");
    setBoardAmount("");
    setBoardContent("");
    setBoardCategory("");
    setArea("");
    setIsEnrollBoard(false);
    set_img_list([]);
  };

  useEffect(() => {
    if (
      boardType === "지식산업센터" ||
      boardType === "오피스텔빌딩" ||
      boardType === "기타" ||
      (boardNm === "구인구직" && boardType === "") ||
      (boardNm === "자유게시판" && boardType === "")
    ) {
      setIsCategoryType(true);
    }

    if (
      boardType === "지식산업센터" ||
      boardType === "오피스텔빌딩" ||
      boardType === "기타" ||
      (boardNm === "구인구직" && boardType === "")
    ) {
      setIsAreaType(true);
    }
  }, [boardType]);

  useEffect(() => {
    if (checkEdit) {
      const boardType = boardListDetail.boardDetail.boardNm;
      const boardCategory = boardListDetail.boardDetail.boardCategory;
      const area = boardListDetail.boardDetail.area;
      const boardAmount = boardListDetail.boardDetail.boardAmount;
      const boardTitle = boardListDetail.boardDetail.boardTitle;
      const boardContent = boardListDetail.boardDetail.boardContent;
      setEnrollTitle("수정");
      setBoardType(boardType !== undefined && boardListDetail.boardDetail.boardNm !== "자유게시판" ? boardType : "");
      setBoardCategory(boardCategory !== undefined && boardCategory);
      setArea(area !== undefined && area);
      setBoardAmount(boardAmount !== undefined && boardAmount);
      setBoardTitle(boardTitle !== undefined && boardTitle);
      setBoardContent(boardContent !== undefined && boardContent);
      set_img_list(boardListDetail.boardPic || []);
    }
  }, []);

  return (
    <React.Fragment>
      {is_modal_img && <AlertImage title="사진 등록" set_is_modal_img={set_is_modal_img} setImage={setImage} />}
      {isCategoryBottomSheet && (
        <BoardBottomModal
          title={"분류선택"}
          boardType={boardType}
          closeModal={setIsCategoryBottomSheet}
          setElement={setBoardCategory}
          setCheckEdit={setCheckEdit}
          boardNm={boardNm}
          setBoardType={setBoardType}
        />
      )}
      {isAreaBottomSheet && (
        <BoardBottomModal
          title={"지역선택"}
          boardType={boardType}
          isAreaBottomSheet={isAreaBottomSheet}
          closeModal={setIsAreaBottomSheet}
          setElement={setArea}
          setCheckEdit={setCheckEdit}
        />
      )}
      <div className="wrapper community">
        <header className="right">
          <h1>{enrollTitle}</h1>
          <div className="h-btn-box">
            <button type="button" className="close-btn" onClick={handleCloseBtn} />
          </div>
        </header>
        <Header Title={enrollTitle} handleGoBack={handleCloseBtn} structure={"close"} />

        <div className="content">
          <div className="form-box-wrap">
            <div className="form-box">
              {/*  */}
              {isCategoryType && (
                <div className="tf-box-wrap">
                  <div className={`tf-box${(boardNm === "구인구직" && boardType) || boardCategory ? " focus" : ""}`}>
                    <label htmlFor="choice1">분류</label>
                    <div className="line-box">
                      <div
                        className="flex-box"
                        onClick={() => {
                          setIsCategoryBottomSheet(true);
                        }}
                      >
                        <input
                          type="button"
                          id="choice1"
                          className="tf"
                          value={
                            boardNm === "구인구직"
                              ? boardType === ""
                                ? "분류선택"
                                : boardType
                              : boardCategory === ""
                              ? "분류선택"
                              : boardCategory
                          }
                        />
                        <i className="ico-down-arr"></i>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*  */}
              {isAreaType && (
                <div className="tf-box-wrap">
                  <div className={`tf-box${area ? " focus" : ""}`}>
                    <label htmlFor="choice2">지역</label>
                    <div className="line-box">
                      <div
                        className="flex-box"
                        onClick={() => {
                          setIsAreaBottomSheet(true);
                        }}
                      >
                        <input type="button" id="choice2" className="tf" value={area === "" ? "지역선택" : area} />

                        <i className="ico-down-arr"></i>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="tf-box-wrap">
                <div className={`tf-box${boardTitle ? " focus" : ""}`}>
                  <label htmlFor="title">제목</label>
                  <div className="line-box">
                    <input
                      type="text"
                      id="title"
                      className="tf"
                      placeholder="제목을 입력하세요.(40자내)"
                      value={boardTitle}
                      onChange={handleBoardTitleChange}
                    />
                  </div>
                </div>
              </div>
              {boardNm === "벼룩시장" && boardType === "팝니다" && (
                <div className="tf-box-wrap">
                  <div className={`tf-box${boardAmount ? " focus" : ""}`}>
                    <label htmlFor="price">판매금액</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="tel"
                          id="price"
                          className="tf"
                          placeholder="판매금액을 입력하세요."
                          value={boardAmount && numberFormat(boardAmount)}
                          onChange={handleBoardAmountChange}
                        />

                        <span style={{ fontSize: "1.8rem" }}>원</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="textarea-box mt50">
                <label htmlFor="detail-desc">자세한 설명을 입력하세요</label>
                <textarea id="detail-desc" value={boardContent} onChange={handleBoardContentChange}></textarea>
                <span className="total">
                  <span className="num-word">{boardContent.length}</span>/1000
                </span>
              </div>
            </div>
          </div>
          <div className="attachment mt20">
            <button
              type="button"
              className="file-btn to-modal-btn"
              onClick={() => {
                if (img_list.length >= 10) {
                  setIsAlert(true);
                  setModalMsg("이미지 수량을 초과하였습니다.");
                } else {
                  set_is_modal_img(true);
                }
              }}
            >
              <span className="total">
                <span className="num-img">{img_list.length}</span>/10
              </span>
            </button>
            {img_list.length > 0 &&
              img_list.map((img_list_map, i) => (
                <div className="file-box represent" key={"img_list_map_" + i}>
                  <img src={img_list_map.pic_url} alt="이미지" />
                  {i === 0 ? <span>대표</span> : null}
                  <button
                    type="button"
                    className="remove-btn"
                    onClick={() => {
                      const new_img_list = img_list;
                      new_img_list.splice(i, 1);
                      set_img_list([...new_img_list]);
                    }}
                  ></button>
                </div>
              ))}
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${
                (boardType === "삽니다" && boardTitle && boardContent) ||
                (boardType === "팝니다" && boardTitle && boardContent) ||
                (boardType !== "자유게시판" && boardCategory && area && boardTitle && boardContent)
                  ? " main-color"
                  : boardNm === "구인구직"
                  ? boardType && area && boardTitle && boardContent
                    ? " main-color"
                    : " disabled"
                  : boardNm === "자유게시판" && boardCategory && boardTitle && boardContent
                  ? " main-color"
                  : " disabled"
              }`}
              onClick={() => {
                if (checkEdit) {
                  handleUpdateBoard();
                } else {
                  handleRegBoard();
                }
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EnrollBoard;
