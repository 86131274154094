import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";
import { formatBsnnNo, formatMobileNo, formatMobileNoSecret } from "utils/fmt";

import Header from "components/ui/common/Header";
import { signValidation } from "utils/Common";
import { useNavigate } from "react-router-dom";

const UserInfoEdit = ({
  setIsAlert,
  setModalMsg,
  userList,
  setUserList,
  setTwoWayGoBack,
  setIsUserInfoEdit,
  setIsUserPwEdit,
}) => {
  const navigate = useNavigate();
  const [roomTel, setRoomTel] = useState("");
  const [roomMail, setRoomMail] = useState("");

  //회사정보수정 api
  const handleUpdateCompanyInfo = async () => {
    if (roomTel.length < 8) {
      setIsAlert(true);
      setModalMsg("전화번호 형식을 확인해 주세요.");
    } else if (roomMail !== "" && !signValidation("email", roomMail)) {
      setIsAlert(true);
      setModalMsg("이메일 형식을 확인해 주세요.");
    } else {
      try {
        const response = await request(PATH.회사정보수정, {
          roomTel: roomTel,
          roomMail: roomMail,
        });
        if (response.data.code === "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
          const updatedUserList = {
            ...userList,
            roomTel: roomTel,
            roomMail: roomMail,
          };
          setUserList(updatedUserList);
          setIsUserInfoEdit(false);
        } else if (response.data.code === "9999") {
          setIsAlert(true);
          setModalMsg(response.data.message);
        } else {
          setIsAlert(true);
          setModalMsg("회원정보 수정 중\n오류가 발생했습니다.\n잠시 후 다시 시도해 주세요.");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleRoomTelReset = () => {
    setRoomTel("");
  };

  const handleRoomMailReset = () => {
    setRoomMail("");
  };

  const handleGoBack = () => {
    setIsUserInfoEdit(false);
    navigate("/userInfo");
  };

  const handleGoUserPwEdit = () => {
    setIsUserInfoEdit(false);
    setIsUserPwEdit(true);
    setTwoWayGoBack(true);
  };

  const handleRoomTel = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");
    if (input.length > 16) {
      input = input.slice(0, 16);
    }
    setRoomTel(input);
  };

  const handleRoomMailChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^\dㄱ-ㅎㅏ-ㅣ가-힣ㆍa-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }
    setRoomMail(input);
  };

  useEffect(() => {
    setRoomTel(userList.roomTel && userList.roomTel);
    setRoomMail(userList.roomMail && userList.roomMail);
  }, []);

  return (
    <React.Fragment>
      {/* {isAlert === true ? (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            setIsUserInfoEdit(false);
          }}
        />
      ) : null} */}
      <div className="wrapper user-info">
        <Header Title="회원정보 수정" handleGoBack={handleGoBack} />
        <div className="content p0 pb120">
          <div className="info-box">
            <div className="info">
              <p className="tit">아이디</p>
              <div className="desc">
                <p>{userList.userId}</p>
              </div>
            </div>
            {userList.joinType === "mobile" && (
              <div className="info">
                <p className="tit">비밀번호</p>
                <div className="desc">
                  <p>
                    <span className="secret">*************</span>
                  </p>
                  <button type="button" className="modification-btn" onClick={handleGoUserPwEdit}>
                    변경
                  </button>
                </div>
              </div>
            )}
            <div className="info">
              <p className="tit">호수</p>
              <div className="desc">
                <p>{userList.roomCd}호</p>
              </div>
            </div>
            <div className="info">
              <p className="tit">업체명</p>
              <div className="desc">
                <p>{userList.roomNm}</p>
              </div>
            </div>
            <div className="info">
              <p className="tit">대표자</p>
              <div className="desc">
                <p>{userList.roomRprsnNm}</p>
              </div>
            </div>
            <div className="info">
              <p className="tit">휴대폰번호</p>
              <div className="desc">
                <p>{formatMobileNoSecret(userList.userMobileNo)}</p>
                {/* <button type="button" className="alert-btn"></button> */}
              </div>
            </div>
            <div className="info">
              <p className="tit">담당자 연락처</p>
              <div className="desc">
                <p> {userList.roomTel !== "" ? formatMobileNo(userList.roomTel) : "-"}</p>
              </div>
            </div>
            <div className="info">
              <p className="tit">이메일 주소</p>
              <div className="desc">
                <p>{userList.roomMail !== "" ? userList.roomMail : "-"}</p>
              </div>
            </div>
            <div className="info">
              <p className="tit">사업자번호</p>
              <div className="desc">
                <p>{formatBsnnNo(userList.roomBizNo)}</p>
              </div>
            </div>
            <div className="info">
              <p className="tit">가입일</p>
              <div className="desc">
                <p>{userList.regDtm}</p>
              </div>
            </div>
          </div>

          <p className="exp">
            * 등록하셨던 휴대폰번호는 인증용으로 사용되기 때문에 <em>관리실로 문의</em> 후 변경이 가능합니다.
          </p>

          <div className="form-box-wrap" style={{ padding: "2rem" }}>
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box${roomTel ? " focus" : ""}`}>
                  <label htmlFor="phone-find">담당자 연락처</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="tel"
                        id="phone-find"
                        className="tf"
                        placeholder="휴대폰 번호(-없이 입력)"
                        value={roomTel}
                        onChange={handleRoomTel}
                        maxLength={11}
                      />
                      {roomTel ? <button type="button" className="remove-btn" onClick={handleRoomTelReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tf-box-wrap">
                <div className={`tf-box${roomMail ? " focus" : ""}`}>
                  <label htmlFor="phone-find">이메일</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="email-edit"
                        className="tf"
                        placeholder="0000000@email.com"
                        value={roomMail}
                        onChange={handleRoomMailChange}
                        maxLength={40}
                      />
                      {roomMail ? <button type="button" className="remove-btn" onClick={handleRoomMailReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box type2">
            <button type="button" className="btn1 narrow sub-color" onClick={handleGoBack}>
              취소
            </button>
            <button type="button" className="btn1 wide main-color" onClick={handleUpdateCompanyInfo}>
              수정
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserInfoEdit;
