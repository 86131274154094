import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import BottomSheet from "components/ui/common/BottomSheet";
import Header from "components/ui/common/Header";
import Loading from "components/active/Loading";
import { NOTICE_SEARCH_TYPE_LIST } from "utils/Common";
import SearchBox from "components/ui/common/SearchBox";
import { filterValue } from "utils/fmt";
import moment from "moment";
import { useInView } from "react-intersection-observer";

export default function NoticeList() {
  const [inViewRef, inView] = useInView();
  const [last_cd, set_last_cd] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [is_loading, set_is_loading] = useState(false);
  const [show_detail, set_show_detail] = useState(false);

  const [show_bottom_sheet, set_show_bottom_sheet] = useState(false);
  const [show_search_box, set_show_search_box] = useState("");

  const [detail, set_detail] = useState({});
  const [list, set_list] = useState([]);

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const order_by_list = [
    { name: "최신순", value: "new" },
    { name: "과거순", value: "old" },
  ];
  const [order_by, set_order_by] = useState("new");
  const [api_order_by, set_api_order_by] = useState("new");

  const notice_type_list = NOTICE_SEARCH_TYPE_LIST;
  const [notice_type, set_notice_type] = useState("all");
  const [api_notice_type, set_api_notice_type] = useState("all");

  const dt_list = [
    {
      name: "최근7일",
      str_dt: moment().subtract(7, "day").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    {
      name: "최근1개월",
      str_dt: moment().subtract(1, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    {
      name: "최근3개월",
      str_dt: moment().subtract(3, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    { name: "월별", str_dt: "", end_dt: "" },
    { name: "기간설정", str_dt: "", end_dt: "" },
  ];

  const [dt, set_dt] = useState("최근7일");
  const [str_dt, set_str_dt] = useState(dt_list[0].str_dt);
  const [end_dt, set_end_dt] = useState(dt_list[0].end_dt);
  const [api_str_dt, set_api_str_dt] = useState(dt_list[0].str_dt);
  const [api_end_dt, set_api_end_dt] = useState(dt_list[0].end_dt);

  const [search_text, set_search_text] = useState("");
  const [api_search_text, set_api_search_text] = useState("");

  const research = async () => {
    set_api_order_by(order_by);
    set_api_notice_type(notice_type);
    set_api_str_dt(str_dt);
    set_api_end_dt(end_dt);
    set_api_search_text(search_text);
    set_list([]);
    setHasMoreData(true);
    set_last_cd(0);
    set_show_bottom_sheet(false);
    set_show_search_box(false);
    set_search_text("");
  };

  const getList = async () => {
    if (is_loading || !hasMoreData) return;
    try {
      set_is_loading(true);
      const req = {
        str_dt: api_str_dt,
        end_dt: api_end_dt,
        sort: api_order_by,
        notice_type: api_notice_type,
        search_type: "title",
        search_text: api_search_text,
        page_no: last_cd,
      };
      const response = await request(PATH.getBldNoticeList, req);

      if (response.data.code === "0000") {
        if (response.data.notice_list.length > 0) {
          set_list((prevPushList) => [
            ...prevPushList,
            ...response.data.notice_list,
          ]);
          set_last_cd(last_cd + 1);
        } else {
          setHasMoreData(false);
        }
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const getDetail = async (notice_seq) => {
    try {
      set_is_loading(true);
      const req = {
        notice_seq: notice_seq,
      };
      const response = await request(PATH.getBldNoticeDetail, req);

      if (response.data.code === "0000") {
        set_detail(response.data.notice_info);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const close_detail = async () => {
    try {
      set_show_detail(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (last_cd === 0) {
      set_last_cd(1);
    } else if (last_cd === 1) {
      getList();
    }
  }, [last_cd]);

  useEffect(() => {
    if (inView && !is_loading && hasMoreData) {
      getList();
    }
  }, [inView]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {show_search_box && (
        <SearchBox
          search_text={search_text}
          set_search_text={set_search_text}
          set_show_search_box={set_show_search_box}
          research={research}
        />
      )}

      {show_bottom_sheet && (
        <BottomSheet
          set_show_bottom_sheet={set_show_bottom_sheet}
          order_by_list={order_by_list}
          order_by={order_by}
          set_order_by={set_order_by}
          dt_list={dt_list}
          dt={dt}
          set_dt={set_dt}
          str_dt={str_dt}
          set_str_dt={set_str_dt}
          end_dt={end_dt}
          set_end_dt={set_end_dt}
          type_name={"분류"}
          type_list={notice_type_list}
          type={notice_type}
          set_type={set_notice_type}
          research={research}
        />
      )}
      {show_detail ? (
        is_loading ? (
          <Loading />
        ) : (
          <div className="wrapper inquiry">
            <Header Title="공지사항" handleGoBack={close_detail} />

            <div className="content p0 pb120">
              <div className="inq-notice">
                <div className="inq-tit-box mt16">
                  <em className="sort type2 mb12">
                    {filterValue(NOTICE_SEARCH_TYPE_LIST, detail.notice_type)}
                  </em>
                  <p className="tit txt1">{detail.title}</p>
                  <div className="justify-between mt16">
                    <p className="txt2">{detail.reg_dtm.substring(0, 16)}</p>
                  </div>
                </div>

                <div className="inq-desc-box">
                  <p>{detail.content}</p>
                </div>

                <ul className="num-box type3 mt20">
                  <li>
                    <i className="ico-view"></i> {detail.view_cnt}
                  </li>
                </ul>
              </div>
            </div>

            <div className="fixed-button-box">
              <div className="button-box type2">
                <button
                  type="button"
                  className="btn1 sub-color narrow"
                  onClick={() => {
                    close_detail();
                  }}
                >
                  목록
                </button>
                <button
                  type="button"
                  className="btn1 main-color wide"
                  onClick={() => {
                    if (detail.pre_seq) {
                      getDetail(detail.pre_seq);
                    } else {
                      setIsAlert(true);
                      setModalMsg("마지막 내역입니다.");
                    }
                  }}
                >
                  다음
                </button>
              </div>
            </div>
          </div>
        )
      ) : (
        //빌딩공지 메인 페이지
        <div className="wrapper inquiry">
          <Header Title="공지사항" handleGoBack={"main"} />
          {is_loading && <Loading />}

          <div className="content p0">
            <div className="filter-bar">
              <div className="left-box">
                <ul className="txt-box">
                  <li>{filterValue(order_by_list, order_by)}</li>
                  <li>{dt}</li>
                  <li>{filterValue(notice_type_list, notice_type)}</li>
                </ul>
              </div>
              <div className="right-box justify-between">
                <button
                  type="button"
                  className="ico-filter"
                  onClick={() => {
                    set_show_bottom_sheet(true);
                  }}
                ></button>
                <button
                  type="button"
                  className="ico-search"
                  onClick={() => {
                    set_show_search_box(true);
                  }}
                ></button>
              </div>
            </div>

            <div className="community-list community-list3">
              {list.length !== 0
                ? list.map((list_map, index) => {
                    return (
                      <div
                        className="list-box"
                        key={`list_map_${index}`}
                        onClick={() => {
                          set_show_detail(true);
                          getDetail(list_map.notice_seq);
                        }}
                      >
                        <p className="tit txt1">
                          {"[" +
                            filterValue(
                              NOTICE_SEARCH_TYPE_LIST,
                              list_map.notice_type,
                            ) +
                            "] "}
                          {list_map.title}
                        </p>
                        <div className="justify-between">
                          <p className="txt2">{list_map.reg_dtm}</p>
                          <ul className="num-box">
                            <li>
                              <i className="ico-view"></i>
                              {list_map.view_cnt}
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            {list.length === 0 && !is_loading && (
              <div className="no-content type2">
                <i className="ico-no-content"></i>
                <p>조회하신 내역이 없습니다.</p>
              </div>
            )}
            <div ref={inViewRef} style={{ height: "1px" }} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
