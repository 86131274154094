import React from "react";

const PwTabComponent = ({
  userId,
  setUserId,
  userNm,
  setUserNm,
  userMobileNo,
  setUserMobileNo,
  value,
  setValue,
  sendValue,
  handleMobileAuth,
  handleLoginFind,
  handleUserIdReset,
  handleNmReset,
  handleMobileNoReset,
  handleValueReset,
  verificationCodeInputRef,
  userNmInputRef,
  userMobileNoInputRef,
  userIdInputRef,
}) => {
  const handleUserMobileNo = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");
    if (input.length > 11) {
      input = input.slice(0, 11);
    }
    setUserMobileNo(input);
  };

  const handleUserNm = (e) => {
    let input = e.target.value;

    input = input.replace(/[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣ㆍ]/g, "");

    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setUserNm(input);
  };

  return (
    <div className="tab-content pt40">
      <div className="title-box mb38">
        <h2>
          아이디와 휴대폰 번호로
          <br />
          비밀번호 찾기
        </h2>
      </div>

      <div className="form-box-wrap">
        <div className="form-box">
          <div className="tf-box-wrap">
            <div className={`tf-box${userId ? " focus" : ""}`}>
              <label htmlFor="id-find">아이디 입력</label>
              <div className="line-box">
                <div className="flex-box">
                  <input
                    type="text"
                    id="id-find"
                    className="tf"
                    placeholder="아이디 입력"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    maxLength={20}
                    ref={userIdInputRef}
                  />
                  {userId ? <button type="button" className="remove-btn" onClick={handleUserIdReset} /> : null}
                </div>
              </div>
            </div>
          </div>

          <div className="tf-box-wrap">
            <div className={`tf-box${userNm ? " focus" : ""}`}>
              <label htmlFor="name-find">이름 입력</label>
              <div className="line-box">
                <div className="flex-box">
                  <input
                    type="text"
                    id="name-find"
                    className="tf"
                    placeholder="이름 입력"
                    value={userNm}
                    onChange={handleUserNm}
                    ref={userNmInputRef}
                  />
                  {userNm ? <button type="button" className="remove-btn" onClick={handleNmReset} /> : null}
                </div>
              </div>
            </div>
          </div>

          <div className="tf-box-wrap">
            <div className={`tf-box${userMobileNo ? " focus" : ""}`}>
              <label htmlFor="phone-find">휴대폰 번호</label>
              <div className="line-box type2">
                <div className="flex-box">
                  <input
                    type="tel"
                    id="phone-find"
                    className="tf"
                    placeholder="휴대폰 번호(-없이 입력)"
                    value={userMobileNo}
                    onChange={handleUserMobileNo}
                    ref={userMobileNoInputRef}
                  />
                  {userMobileNo ? <button type="button" className="remove-btn" onClick={handleMobileNoReset} /> : null}
                </div>
                <button
                  type="button"
                  className={`btn-tf${userMobileNo ? " on" : " off"}`}
                  onClick={handleMobileAuth}
                  disabled={!userMobileNo}
                >
                  {sendValue ? "다시요청" : "인증요청"}
                </button>
              </div>
            </div>
          </div>

          <div className="tf-box-wrap">
            {sendValue ? (
              <div className={`tf-box${value ? " focus" : ""}`}>
                <label htmlFor="v-code">인증번호 입력</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input
                      type="tel"
                      id="v-code"
                      className="tf"
                      placeholder="인증번호 입력"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      disabled={!sendValue}
                      ref={verificationCodeInputRef}
                    />
                    {value ? <button type="button" className="remove-btn" onClick={handleValueReset} /> : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="fixed-button-box">
        <div className="button-box">
          <button type="button" className={`btn1${value ? " main-color" : " disabled"}`} onClick={handleLoginFind}>
            비밀번호 찾기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PwTabComponent;
