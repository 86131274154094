import React, { useEffect } from "react";

import Loading from "components/active/Loading";
import { setToken } from "components/shared/Api";
import { useNavigate } from "react-router-dom";

const Main = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setToken(window.localStorage.getItem("token"));
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <React.Fragment>
      {window.ReactNativeWebView ? (
        <Loading />
      ) : (
        <div className="menu-box-wrap">
          <div className="menu-box">
            <h4>메인화면으로 이동되었습니다. 메인화면입니다.</h4>
            <ul>
              <li className="main-menu menu1-3">
                <button type="button" onClick={() => navigate("/notice")}>
                  <span>
                    <i className="ico-menu"></i>알림함
                  </span>
                  <i className="ico-right-arr"></i>
                </button>
              </li>

              <li className="main-menu menu1-2">
                <button type="button" onClick={() => navigate("/menu")}>
                  <span>
                    <i className="ico-menu"></i>전체메뉴
                  </span>
                  <i className="ico-right-arr"></i>
                </button>
              </li>

              <li className="main-menu menu1-1">
                <button type="button" onClick={() => navigate("/community")}>
                  <span>
                    <i className="ico-menu"></i>커뮤니티
                  </span>
                  <i className="ico-right-arr"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Main;
