import React, { useEffect, useState } from "react";

import Header from "components/ui/common/Header";
import { numberFormat } from "utils/fmt";

const BillView = ({
  billList,
  handleListClick,
  handleLastListClick,
  handleAverageListClick,
  inViewRef,
  is_loading,
}) => {
  const [isScroll, setIsScroll] = useState(false);

  const firstElement =
    billList.length > 0 ? (
      <div
        key={0}
        className={`maintenance-cost-box round-box${isScroll ? " hidden" : ""}`}
        style={{ boxShadow: "0 0 0.4rem 0 rgba(0, 0, 0, 0.25)" }}
        onClick={() => {
          handleListClick(0);
          handleLastListClick(1);
          handleAverageListClick(0);
        }}
      >
        <div className="justify-between mb24">
          <p className="month">
            {billList[0].billMonth.substring(0, 4)}년 {billList[0].billMonth.substring(4)}월
          </p>
          <span className={`state ${billList[0].paymentYn === "Y" ? "blue" : "red"}`}>
            {billList[0].paymentYn === "Y" ? "수납완료" : "미납"}
          </span>
        </div>
        <p className="cost">
          <strong>{numberFormat(billList[0].billSum)}</strong>원
        </p>
        <div className={`due-date-bar${isScroll ? " hidden" : ""}`}>
          <p className="tit">납부마감일</p>
          <p className="desc">
            {billList[0].billDeadline.substring(0, 4)}.{billList[0].billDeadline.substring(4, 6)}.
            {billList[0].billDeadline.substring(6)}
          </p>
        </div>
      </div>
    ) : null;

  const restElements = billList.slice(1).map((v, i) => (
    <div
      key={i + 1}
      className="list"
      onClick={() => {
        handleListClick(i + 1);
        handleLastListClick(i + 2);
        handleAverageListClick(i + 1);
      }}
    >
      <div className="justify-between">
        <p className="month">
          {v.billMonth.substring(0, 4)}년 {v.billMonth.substring(4)}월
        </p>
        <span className={`state ${v.paymentYn === "Y" ? "blue" : "red"}`}>
          {v.paymentYn === "Y" ? "수납완료" : "미납"}
        </span>
      </div>
      <p className="cost">{numberFormat(v.billSum)}원</p>
    </div>
  ));

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom = window.scrollY >= 100;
      setIsScroll(scrolledToBottom);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      {billList.length !== 0 ? (
        <div className="wrapper claim-history">
          <Header Title="관리비 청구내역" handleGoBack={"main"} />
          <div className="content pt20 pb40">
            <div className="maintenance-cost-box-wrap">{firstElement}</div>

            <div className="history-box">
              <div className="history-list-wrap">
                <div className="history-list">
                  {restElements.length !== 0 && !is_loading ? (
                    restElements
                  ) : restElements.length === 0 && !is_loading ? (
                    <div className="no-content type2">
                      <i className="ico-no-content"></i>
                      <p>조회하신 내역이 없습니다.</p>
                    </div>
                  ) : null}
                </div>
                <div ref={inViewRef} style={{ height: "1px" }} />
              </div>
            </div>
          </div>
        </div>
      ) : billList.length === 0 && !is_loading ? (
        <React.Fragment>
          <Header Title="관리비 청구내역" handleGoBack={"main"} />
          <div className="no-content type2">
            <i className="ico-no-content"></i>
            <p>조회하신 내역이 없습니다.</p>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default BillView;
