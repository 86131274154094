import { JoinContext, JoinInitialValue, joinLocalRemove } from "../components/shared/JoinContext";
import { PATH, request } from "../components/shared/Api";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AlertModal from "../components/ui/modal/AlertModal";
import Loading from "../components/active/Loading";

const NaverLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [login_false, set_login_false] = useState(false);
  const { setJoinProps } = useContext(JoinContext);
  const navigate = useNavigate();

  const Login = async () => {
    let location = window.location.href;
    try {
      const response = await request(PATH.socialSignOn, {
        socialType: "naver",
        code: searchParams.get("code"),
        redirect_uri: location.substring(0, location.indexOf("?")),
      });
      if (response.data.code === "0000") {
        if (response.data.accessToken !== "") {
          window?.localStorage && window.localStorage.setItem("token", response.data.accessToken);
          navigate("/");
        } else {
          joinLocalRemove();
          setJoinProps({
            ...JoinInitialValue,
            userId: response.data.userInfo.email,
            receipEmail: response.data.userInfo.email,
            userMobileNo: response.data.userInfo.mobile,
            userNm: response.data.userInfo.name,
            social: "Y",
          });
          navigate("/terms");
        }
      } else {
        setIsAlert(true);
        setModalMsg("네이버 로그인에 실패하였습니다.");
        set_login_false(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    if (searchParams.get("code")) {
      Login();
    } else {
      setIsAlert(true);
      setModalMsg("네이버 로그인에 실패하였습니다.");
      set_login_false(true);
    }
  }, []);

  useEffect(() => {
    if (login_false && !isAlert) {
      navigate("/");
    }
  }, [login_false, isAlert]);

  return (
    <React.Fragment>
      {isAlert ? <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} /> : <Loading />}
    </React.Fragment>
  );
};

export default NaverLogin;
