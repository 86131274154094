import "react-calendar/dist/Calendar.css";

import React, { useState } from "react";

import Calendar from "react-calendar";
import moment from "moment";

const BottomSheet = ({
  set_show_bottom_sheet,
  order_by_list,
  order_by,
  set_order_by,
  dt_list,
  dt,
  set_dt,
  str_dt,
  set_str_dt,
  end_dt,
  set_end_dt,
  type_name,
  type_list,
  type,
  set_type,
  category_name,
  category_list,
  category,
  set_category,
  research,
  title,
}) => {
  const month = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
  const [view_month, set_view_month] = useState(false);
  const [view_day, set_view_day] = useState("");

  const dateChange = (selectedDate) => {
    if (view_day === "str_dt") {
      set_str_dt(moment(selectedDate).format("YYYYMMDD"));
    } else if (view_day === "end_dt") {
      set_end_dt(moment(selectedDate).format("YYYYMMDD"));
    }
  };

  return (
    <div className="bottom-sheet-modal filter-modal calendar">
      {view_day !== "" ? (
        <div className="box type2">
          <div className="tit-box justify-between">
            <h2 className="type2">날짜 선택</h2>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                set_view_day("");
              }}
            ></button>
          </div>
          <div className="con-box type2">
            <Calendar
              onChange={dateChange}
              value={moment(view_day === "str_dt" ? str_dt : end_dt, "YYYYMMDD").format("YYYY-MM-DD")}
              formatDay={(locale, date) => moment(date).format("D")}
            ></Calendar>
          </div>
          <div className="button-box">
            <button
              type="button"
              className="btn1 main-color"
              onClick={() => {
                set_view_day("");
              }}
            >
              확인
            </button>
          </div>
        </div>
      ) : view_month ? (
        <div className="box">
          <div className="tit-box justify-between">
            <h2 className="type2">년월 선택</h2>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                set_view_month(false);
              }}
            ></button>
          </div>

          <div className="con-box type2">
            <div className="flex-box">
              <div className="year-box">
                <button
                  type="button"
                  className="prev"
                  onClick={() => {
                    set_str_dt(moment(str_dt).subtract(1, "year").format("YYYYMM01"));
                    set_end_dt(moment(str_dt).subtract(1, "year").endOf("month").format("YYYYMMDD"));
                  }}
                ></button>
                <span className="year">{moment(str_dt).format("YYYY")}</span>
                <button
                  type="button"
                  className="next"
                  onClick={() => {
                    set_str_dt(moment(str_dt).add(1, "year").format("YYYYMM01"));
                    set_end_dt(moment(str_dt).add(1, "year").endOf("month").format("YYYYMMDD"));
                  }}
                ></button>
              </div>
              <div className="month-box">
                {month.map((month_map, index) => {
                  return (
                    <button
                      type="button"
                      className={String(index + 1).padStart(2, "0") === moment(str_dt).format("MM") ? "active" : null}
                      ket={"month_map_" + index}
                      onClick={() => {
                        let str_dt_month = moment(str_dt).format("YYYY" + String(index + 1).padStart(2, "0") + "01");
                        set_str_dt(str_dt_month);
                        set_end_dt(moment(str_dt_month).endOf("month").format("YYYYMMDD"));
                      }}
                    >
                      {month_map}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="button-box">
            <button
              type="button"
              className="btn1 main-color"
              onClick={() => {
                set_view_month(false);
              }}
            >
              확인
            </button>
          </div>
        </div>
      ) : (
        <div className="box">
          <div className="tit-box justify-between">
            <h2 className="type2">필터</h2>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                set_show_bottom_sheet(false);
              }}
            ></button>
          </div>

          <div className="con-box type2">
            <div className="filter-box">
              <span className="h4">보기</span>
              <div className="btn-box">
                {order_by_list.map((order_by_list_map, index) => {
                  return (
                    <button
                      type="button"
                      className={order_by_list_map.value === order_by ? "type2 active" : "type2"}
                      key={"order_by_list_map_" + index}
                      onClick={() => {
                        set_order_by(order_by_list_map.value);
                      }}
                    >
                      {order_by_list_map.name}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="filter-box">
              <span className="h4">기간</span>
              <div className="btn-box">
                {dt_list.map((dt_list_map, index) => {
                  return (
                    <button
                      type="button"
                      className={[dt_list_map.name === dt ? "active " : "", index > 2 ? "type2 " : ""].join("")}
                      key={"dt_list_map_" + index}
                      onClick={() => {
                        set_dt(dt_list_map.name);
                        if (index > 2) {
                        } else {
                          set_str_dt(dt_list_map.str_dt);
                          set_end_dt(dt_list_map.end_dt);
                        }
                      }}
                    >
                      {dt_list_map.name}
                    </button>
                  );
                })}
              </div>
              {dt === "월별" ? (
                <div className="to-modal-box mt18 mb48">
                  <button
                    type="button"
                    className="to-modal-btn"
                    onClick={() => {
                      set_view_month(true);
                    }}
                  >
                    {moment(str_dt).format("YYYY년 M월")}
                  </button>
                </div>
              ) : dt === "기간설정" ? (
                <div className="to-modal-inp items-center mt18 mb48">
                  <div
                    className="relative"
                    onClick={() => {
                      set_view_day("str_dt");
                    }}
                    style={{ width: "calc((100% - 2.6rem) / 2)" }}
                  >
                    <input
                      type="text"
                      className="type2"
                      value={moment(str_dt, "YYYYMMDD").format("YYYY.MM.DD")}
                      disabled
                    />
                    <i className="ico-calendar"></i>
                  </div>
                  <span>~</span>
                  <div
                    className="relative"
                    onClick={() => {
                      set_view_day("end_dt");
                    }}
                    style={{ width: "calc((100% - 2.6rem) / 2)" }}
                  >
                    <input
                      type="text"
                      className="type2"
                      value={moment(end_dt, "YYYYMMDD").format("YYYY.MM.DD")}
                      disabled
                    />
                    <i className="ico-calendar"></i>
                  </div>
                </div>
              ) : null}
            </div>

            {title !== "자유게시판" && type_list && type_list.length > 0 ? (
              <div className="filter-box">
                <span className="h4">{type_name}</span>
                <div className="btn-box">
                  {type_list.map((type_list_map, index) => {
                    return (
                      <button
                        type="button"
                        className={type_list_map.value === type ? "active" : ""}
                        key={"type_list_map_" + index}
                        onClick={() => {
                          set_type(type_list_map.value);
                        }}
                      >
                        {type_list_map.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {category_list && category_list.length > 0 ? (
              <div className="filter-box">
                <span className="h4">{category_name}</span>
                <div className="btn-box">
                  {category_list.map((category_list_map, index) => {
                    return (
                      <button
                        type="button"
                        className={category_list_map.value === category ? "active" : ""}
                        key={"category_list_map_" + index}
                        onClick={() => {
                          set_category(category_list_map.value);
                        }}
                      >
                        {category_list_map.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>

          <div className="button-box">
            <button
              type="button"
              className="btn1 main-color"
              onClick={() => {
                research();
              }}
            >
              적용
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomSheet;
