import { PATH, request } from "components/shared/Api";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AlertModal from "components/ui/modal/AlertModal";
import { JoinContext } from "components/shared/JoinContext";
import SelectBottomSheet from "components/ui/common/SelectBottomSheet";
import { isEmpty } from "utils/Common";

const RoomJoin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { joinProps, setJoinProps } = useContext(JoinContext);

  const default_name = {
    city: "시/도 선택",
    district: "시/구/군 선택",
    dong: "동/면/읍 선택",
    bld: "빌딩 선택",
    room: "호 선택",
  };

  const [selectedAddr, setSelectAddr] = useState({
    city: "",
    district: "",
    dong: "",
    bld: "",
    room: "",
  });
  const { city, district, dong, bld, room } = selectedAddr;

  const [bldCode, setBldCode] = useState(0);

  const [title, set_title] = useState("주소 선택");
  const [is_bottomsheet, set_is_bottomsheet] = useState(false);
  const [bottomsheet_list, set_bottomsheet_list] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  //빌딩 조회 api
  const handleAccountBld = async (target) => {
    setIsLoading(true);
    try {
      let requestBody = {};

      switch (target) {
        case "district":
          requestBody = {
            addr1: city,
            addr2: "",
          };
          break;
        case "dong":
          requestBody = {
            addr1: city,
            addr2: district,
            addr3: "",
          };
          break;
        case "bld":
          requestBody = {
            addr1: city,
            addr2: district,
            addr3: dong,
            bldCd: "",
          };
          break;
        case "room":
          requestBody = {
            addr1: city,
            addr2: district,
            addr3: dong,
            bldCd: bldCode,
            roomCd: "",
          };
          break;
        default: //'city'
          requestBody = {
            addr1: "",
          };
          break;
      }

      const response = await request(PATH.빌딩조회, requestBody);

      if (response.data.code === "0000") {
        console.log(response.data);
        if (!response.data.hasOwnProperty("addrList")) {
          setIsAlert(true);
          setModalMsg("조회 내역이 없습니다. \n건물관리자에게 문의주세요.");
        }
        let copyAddrList = [default_name[target], ...response.data.addrList];
        let tempList = [];
        for (let i = 0; i < copyAddrList.length; i++) {
          tempList.push({
            name: copyAddrList[i],
            value: () => {
              if (target === "bld") setBldCode(response.data.addrListCD[i - 1]);

              handleReset(target, i === 0 ? "" : copyAddrList[i]);
              set_bottomsheet_list([]);
              set_is_bottomsheet(false);
            },
          });
        }

        set_title(default_name[target]);
        set_bottomsheet_list([...tempList]);
        set_is_bottomsheet(true);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    if (joinProps.social === "Y") {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  const handleGoNext = () => {
    setJoinProps((prevData) => ({
      ...prevData,
      city: city,
      district: district,
      dong: dong,
      bld: bld,
      bldCd: bldCode,
      roomCd: room,
    }));
    navigate("/detailJoin");
  };

  //상위 값이 바뀌었을 경우 하위 값들 초기화 진행
  const handleReset = (target, compareValue) => {
    if (selectedAddr[target] !== compareValue) {
      switch (target) {
        case "district":
          setSelectAddr({ ...selectedAddr, [target]: compareValue, dong: "", bld: "", room: "" });
          break;
        case "dong":
          setSelectAddr({ ...selectedAddr, [target]: compareValue, bld: "", room: "" });
          break;
        case "bld":
          setSelectAddr({ ...selectedAddr, [target]: compareValue, room: "" });
          break;
        case "room":
          setSelectAddr({ ...selectedAddr, [target]: compareValue });
          break;
        default: //'city'
          setSelectAddr({ ...selectedAddr, [target]: compareValue, district: "", dong: "", bld: "", room: "" });
          break;
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (joinProps.roomCd && joinProps.roomCd !== "") {
      setSelectAddr({
        city: joinProps.city,
        district: joinProps.district,
        dong: joinProps.dong,
        bld: joinProps.bld,
        room: joinProps.roomCd,
      });
      setBldCode(joinProps.bldCd);
    } else {
      return;
    }
  }, [joinProps]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}

      {!isLoading && is_bottomsheet && (
        <SelectBottomSheet title={title} set_is_bottomsheet={set_is_bottomsheet} bottomsheet_list={bottomsheet_list} />
      )}
      <div>
        <div className="wrapper id-join">
          <header>
            <div className="h-btn-box">
              <button type="button" className="back-btn" onClick={handleGoBack} />
            </div>
            <h1>회원가입</h1>
          </header>

          <div className="content">
            <ul className="indicator mt16 mb12">
              <li className="flex-center">1</li>
              <li className="flex-center">2</li>
              <li className="flex-center">3</li>
              <li className="flex-center">4</li>
              <li className="flex-center on">5</li>
              <li className="flex-center">6</li>
            </ul>

            <div className="title-box mb60">
              <h2>
                호실 정보를
                <br />
                입력해 주세요.
              </h2>
            </div>

            <div className="form-box-wrap">
              <div className="form-box">
                <button
                  type="button"
                  className="to-modal-btn mb28"
                  onClick={() => {
                    handleAccountBld("city");
                  }}
                >
                  {city === "" ? default_name.city : city}
                </button>

                {city && (
                  <button
                    type="button"
                    className="to-modal-btn mb28"
                    onClick={() => {
                      handleAccountBld("district");
                    }}
                  >
                    {district === "" ? default_name.district : district}
                  </button>
                )}

                {district && (
                  <button
                    type="button"
                    className="to-modal-btn mb28"
                    onClick={() => {
                      handleAccountBld("dong");
                    }}
                  >
                    {dong === "" ? default_name.dong : dong}
                  </button>
                )}

                {dong && (
                  <button
                    type="button"
                    className="to-modal-btn mb28"
                    onClick={() => {
                      handleAccountBld("bld");
                    }}
                  >
                    {bld === "" ? default_name.bld : bld}
                  </button>
                )}

                {bld && (
                  <button
                    type="button"
                    className="to-modal-btn mb28"
                    onClick={() => {
                      handleAccountBld("room");
                    }}
                  >
                    {room === "" ? default_name.room : room}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="fixed-button-box">
            <div className="button-box">
              <button
                type="button"
                className={`btn1${!isEmpty(selectedAddr) ? " main-color" : " disabled"}`}
                onClick={() => {
                  if (isEmpty(selectedAddr)) {
                    setIsAlert(true);
                    setModalMsg("호실 정보를 입력해 주세요.");
                  } else {
                    handleGoNext();
                  }
                }}
              >
                다음
              </button>
            </div>
          </div>
        </div>

        <div className="slide-modal">
          <div className="box"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RoomJoin;
