import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import SettingNavigateModal from "components/ui/modal/SettingNavigateModal";
import Withdrawal from "components/ui/withdrawal/Withdrawal";
import WithdrawalPw from "components/ui/withdrawal/WithDrawalPw";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const navigate = useNavigate();
  const [isWithdrawal, setWithdrawal] = useState(false);
  const [isWithdrawalPw, setWithdrawalPw] = useState(false);
  const [pushInfo, setPushInfo] = useState([]);
  const [billChecked, setBillChecked] = useState(false);
  const [noticeChecked, setNoticeChecked] = useState(false);
  const [communityChecked, setCommunityChecked] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  //서비스알림확인 api
  const handleGetPushInfo = async () => {
    try {
      const response = await request(PATH.서비스알림확인, {});
      const pushInfo = response.data.pushInfo;

      if (response.data.code === "0000" && pushInfo && pushInfo.length > 0 && pushInfo[0].receipBill !== undefined) {
        const pushInfoList = pushInfo[0];
        setPushInfo(pushInfoList);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //서비스알림변경 api
  const handleUpdatePushInfo = async (type) => {
    try {
      let updatedType = "";
      let updatedValue = "";

      switch (type) {
        case "bill":
          updatedType = "bill";
          updatedValue = !billChecked ? "Y" : "N";
          setBillChecked(!billChecked);
          break;
        case "notice":
          updatedType = "notice";
          updatedValue = !noticeChecked ? "Y" : "N";
          setNoticeChecked(!noticeChecked);
          break;
        case "community":
          updatedType = "community";
          updatedValue = !communityChecked ? "Y" : "N";
          setCommunityChecked(!communityChecked);
          break;
        default:
          break;
      }
      await request(PATH.서비스알림변경, { receipTypr: updatedType, yn: updatedValue });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    navigate("/menu");
  };

  useEffect(() => {
    handleGetPushInfo();
  }, []);

  useEffect(() => {
    if (pushInfo) {
      setBillChecked(pushInfo.receipBill === "Y");
      setNoticeChecked(pushInfo.receipNotice === "Y");
      setCommunityChecked(pushInfo.receipCommunity === "Y");
    }
  }, [pushInfo]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {isNavigate ? (
        <SettingNavigateModal modalMsg={"로그아웃합니다"} closeModal={setIsNavigate} isLogOut={isLogOut} />
      ) : null}
      {isWithdrawal ? (
        <Withdrawal setWithdrawal={setWithdrawal} setWithdrawalPw={setWithdrawalPw} />
      ) : isWithdrawalPw ? (
        <WithdrawalPw setWithdrawal={setWithdrawal} setWithdrawalPw={setWithdrawalPw} />
      ) : (
        <div className="wrapper setting">
          <header>
            <div className="h-btn-box">
              <button type="button" className="back-btn" onClick={handleGoBack} />
            </div>
            <h1>설정</h1>
          </header>

          <div className="content pt28">
            <div className="switch-btn-wrap">
              <h4 className="mb10">서비스 알림</h4>
              <div className="switch-btn-box">
                <label htmlFor="switch-setting-1" className="switch-btn">
                  <span>청구서 발송 알림</span>
                  <input
                    type="checkbox"
                    id="switch-setting-1"
                    checked={billChecked}
                    onChange={() => handleUpdatePushInfo("bill")}
                  />
                </label>
                <label htmlFor="switch-setting-2" className="switch-btn">
                  <span>관리실 공지 알림</span>
                  <input
                    type="checkbox"
                    id="switch-setting-2"
                    checked={noticeChecked}
                    onChange={() => handleUpdatePushInfo("notice")}
                  />
                </label>
                <label htmlFor="switch-setting-3" className="switch-btn">
                  <span>커뮤니티 알림</span>
                  <input
                    type="checkbox"
                    id="switch-setting-3"
                    checked={communityChecked}
                    onChange={() => handleUpdatePushInfo("community")}
                  />
                </label>
              </div>
            </div>
            <button
              type="button"
              className="bar-btn"
              onClick={() => {
                setWithdrawal(true);
              }}
            >
              <h4>회원탈퇴</h4>
              <i className="ico-right-arr"></i>
            </button>
            <button
              type="button"
              className="bar-btn"
              onClick={() => {
                setIsNavigate(true);
                setIsLogOut(true);
              }}
            >
              <h4>로그아웃</h4>
              <i className="ico-right-arr"></i>
            </button>
          </div>

          <div className="version-info-box">
            <span>버전정보</span>
            <p className="main-color">1.0.0</p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Setting;
