import { PATH, request } from "components/shared/Api";
import React, { useLayoutEffect, useState } from "react";

import Header from "components/ui/common/Header";
import { formatTel } from "utils/fmt";
import office_img from "assets/images/icon/icon_office.png";
import { useNavigate } from "react-router-dom";

export default function BldInfo() {
  const navigate = useNavigate();
  const [bld_info, set_bld_info] = useState("-");
  const [bld_pic, set_bld_pic] = useState("");
  const [loading, set_loading] = useState(true);

  const getBldInfo = async () => {
    try {
      const response = await request(PATH.getBldInfo, {});
      if (response.data.code === "0000") {
        set_bld_info(response.data.bldInfo.bldTel);
        set_bld_pic(response.data.bldInfo.bldPic);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_loading(false);
    }
  };

  useLayoutEffect(() => {
    getBldInfo();
  }, []);

  return (
    <React.Fragment>
      <div className="wrapper inquiry">
        <Header Title="관리실 정보" handleGoBack={"main"} />

        <div className="content">
          <div className="office-info">
            <div className="img-box pt38 pb30">
              {loading ? null : bld_pic === "" ? (
                <img src={office_img} alt="이미지가 없습니다." />
              ) : (
                <img src={bld_pic} alt="이미지가 없습니다." />
              )}
            </div>
            <div className="info-box">
              <div className="info">
                <p className="txt1">대표번호</p>
                <p className="txt2">{formatTel(bld_info)}</p>
              </div>
              <div className="info">
                <p className="txt1">관리실 운영시간</p>
                <p className="txt2">24시간</p>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className="btn1 main-color"
              onClick={() => {
                navigate("/noticelist");
              }}
            >
              관리실 공지사항
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
