import { PATH, request } from "components/shared/Api";

import React from "react";

const DelBoardModal = ({
  closeModal,
  detailBoardCd,
  handleGoBack,
  refSeq,
  refStep,
  refLevel,
  handleGetBoardDetail,
  setIsAlert,
  setModalMsg,
}) => {
  const handleDelBoard = async () => {
    try {
      const response = await request(PATH.게시물삭제, {
        boardCd: detailBoardCd,
      });
      console.log("handleRegBoard", response.data);
      if (response.data.code === "0000") {
        handleGoBack();
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelRef = async () => {
    try {
      const response = await request(PATH.리플삭제, {
        boardCd: detailBoardCd,
        refSeq: refSeq,
        refStep: refStep,
        refLevel: refLevel,
      });
      console.log("handleRegRef", response.data);

      if (response.data.code === "0000") {
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleGetBoardDetail();
    }
  };

  // console.log("detailBoardCd:::" + detailBoardCd);
  // console.log("refLevel:::" + refLevel);
  // console.log("refSeq:::" + refSeq);
  // console.log("refStep:::" + refStep);

  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">
          <p>
            삭제하시겠습니까?
            <br />
            삭제한 내역은 복구가 불가능합니다.
          </p>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 sub-color"
            onClick={() => {
              closeModal(false);
            }}
          >
            취소
          </button>
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              if (refLevel || refStep || refSeq) {
                handleDelRef();
              } else {
                handleDelBoard();
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default DelBoardModal;
