import React, { useEffect, useRef } from "react";

import Chart from "chart.js/auto";
import moment from "moment";
import { numberFormat } from "utils/fmt";

const AnalyzeBill = ({ billList, averageBillList, selectedBill, selectedLastBill, selectedAverageBill }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = document.getElementById("comparisonChart");

    // 최대 6개의 데이터만 표시하도록 가공
    let myBill = [];
    let averageBill = [];
    //통계 그래프 데이터 처리 진행
    let labels = [];
    for (let i = 5; i >= 0; i--) {
      let date = moment(selectedBill.billMonth).subtract(i, "month").format("YYYYMM");

      labels.push(moment(date).format("MM월"));
      let myFilterList = billList.find((item) => item.billMonth === date);
      let averageFilterList = averageBillList.find((item) => item.billMonth === date);

      if (myFilterList) {
        myBill.push(Number(myFilterList.billSum));
      } else {
        myBill.push(0);
      }

      if (averageFilterList) {
        averageBill.push(Number(averageFilterList.billSum));
      } else {
        averageBill.push(0);
      }
    }

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "내 관리비",
          data: myBill,
          backgroundColor: "#00B1D8",
          barPercentage: 1.3,
          categoryPercentage: -0.8,
          z: 2,
        },
        {
          label: "빌딩CAS평균",
          data: averageBill,
          backgroundColor: "#E5E7EA",
          barPercentage: 1.3,
          categoryPercentage: -0.4,
          z: 1,
        },
      ],
    };

    if (!chartRef.current) {
      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: {
          plugins: {
            legend: {
              align: "end",
              display: true,
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
            },
            y: {
              display: false,
            },
          },
        },
      });
    } else {
      chartRef.current.data = chartData;
      chartRef.current.update();
    }
  }, [billList, averageBillList]);

  return (
    <div className="tab-content">
      <div className="chart-box-wrap">
        <div className="chart-box">
          {selectedLastBill && selectedLastBill.billSum !== undefined ? (
            <h2 className="type2 mb30">
              전월대비{" "}
              <span className="main-color">
                {selectedLastBill.billSum - selectedBill.billSum < 0
                  ? numberFormat(-(selectedLastBill.billSum - selectedBill.billSum))
                  : numberFormat(selectedLastBill.billSum - selectedBill.billSum)}
                원
              </span>
              <br />
              {selectedLastBill.billSum - selectedBill.billSum < 0 ? "늘었네요!" : "줄었네요!"}
            </h2>
          ) : (
            <h2 className="type2 mb30">이전 청구내역이 없습니다.</h2>
          )}

          <ul className="total-number items-center mb40">
            <li>
              내 관리비
              <span className="number">{numberFormat(selectedBill.billSum)}원</span>
            </li>
            <li>
              빌딩CAS 평균
              <span className="number">{numberFormat(selectedAverageBill?.billSum)}원</span>
            </li>
          </ul>

          <div>
            <canvas id="comparisonChart" width="100" height="100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeBill;
