import Header from "components/ui/common/Header";
import React from "react";
import { numberFormat } from "utils/fmt";
import packageJson from "../../../../package.json";
import { PATH, request } from "components/shared/Api";

const SummaryPayment = ({
  userList,
  selectedBill,
  billDetailList,
  setIsBillDetail,
  setIsInvociceMonths,
  setTwoWayGoBack,
  setIsSummaryPayment,
  setIsCardCheckPayment,
}) => {
  const accountBillList = [
    { billName: "청구월", date: selectedBill.billMonth },
    { billName: "사용기간", date2: selectedBill.billMonth },
    { billName: "당월부과액", billAmount: selectedBill.billSum },
    { billName: "할인총계", billAmount: 0 },
    ...billDetailList.filter((item) => item.billName === "미납액"),
    ...billDetailList.filter((item) => item.billName === "미납연체료"),
    ...billDetailList.filter((item) => item.billName === "납기후금액"),
    { billName: "납기내금액", billAmount: selectedBill.billSum },
    { billName: "납부기한", date3: selectedBill.billDeadline },
  ];
  const year = parseInt(selectedBill.billMonth.substring(0, 4), 10);
  const month = parseInt(selectedBill.billMonth.substring(4, 6), 10);
  const currentDate = new Date(year, month - 1, 1);
  currentDate.setMonth(currentDate.getMonth() - 1);
  // 이전 월의 첫 번째 날
  const previousMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const previousMonthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // 날짜를 원하는 형식으로 표시 (YYYY.MM.DD)
  const formattedPreviousMonthFirstDay = `${previousMonthFirstDay.getFullYear()}.${(
    previousMonthFirstDay.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}.${previousMonthFirstDay.getDate().toString().padStart(2, "0")}`;
  const formattedPreviousMonthLastDay = `${previousMonthLastDay.getFullYear()}.${(previousMonthLastDay.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${previousMonthLastDay.getDate().toString().padStart(2, "0")}`;

  const handleGoBack = () => {
    setIsSummaryPayment(false);
    setIsBillDetail(true);
  };

  const pgPay = async () => {
    let redirect_uri = "https://cas.shcommunication.co.kr";

    if (window.location.origin === "https://casappdev.shcommunication.co.kr") {
      redirect_uri = "https://casdev.shcommunication.co.kr"; // Service IDs 생성할 때 등록한 Return URLs
    }

    try {
      let src = `${redirect_uri}/pay/payMentKsnet?`;
      src += "billMonth=" + encodeURIComponent(selectedBill.billMonth);
      src += "&token=" + encodeURIComponent(window.localStorage.getItem("token"));
      src += "&returnUrl=" + encodeURIComponent(window.location.origin + "/bill");
      src += "&apiUrl=" + encodeURIComponent(redirect_uri);
      console.log(src);
      window.history.replaceState({}, "");
      window.location.href = src;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="wrapper payment">
      <Header Title="관리비 결제" handleGoBack={handleGoBack} />
      <div className="content pt60">
        <div className="title-box mb30">
          <h2>
            {userList.roomNm + userList.roomCd}호
            <span className="pri">
              <strong className="num">{numberFormat(selectedBill.billSum)}</strong>원
            </span>
          </h2>
        </div>

        <div className="round-box">
          <div className="list-box">
            {accountBillList.map((item, index) => (
              <div className="list" key={index}>
                <p className="tit">{item.billName}</p>
                {item.date && (
                  <p className="desc">
                    {item.date.substring(0, 4)}년 {item.date.substring(4, 6)}월
                  </p>
                )}
                {item.date2 && (
                  <p className="desc">
                    {formattedPreviousMonthFirstDay} ~ {formattedPreviousMonthLastDay}
                  </p>
                )}
                {item.date3 && (
                  <p className="desc">
                    {item.date3.substring(0, 4)}.{item.date3.substring(4, 6)}.{item.date3.substring(6, 10)}
                  </p>
                )}
                {item.billAmount && <p className="desc">{numberFormat(item.billAmount)}원</p>}
              </div>
            ))}
          </div>

          <button
            type="button"
            className="bar-btn"
            onClick={() => {
              setIsSummaryPayment(false);
              setTwoWayGoBack(true);
              setIsInvociceMonths(true);
            }}
          >
            <h3>상세내역</h3>
            <i className="ico-right-arr"></i>
          </button>
        </div>
      </div>

      <div className="fixed-button-box">
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              // setIsSummaryPayment(false);
              pgPay();
              // setIsCardCheckPayment(true);
            }}
          >
            관리비 결제
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummaryPayment;
