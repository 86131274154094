import { formatBsnnNo, formatMobileNo, formatMobileNoSecret } from "utils/fmt";

import Header from "components/ui/common/Header";
import React from "react";
import profile from "assets/images/icon/icon_user_profile.svg";
import { useNavigate } from "react-router-dom";

const UserInfoView = ({ userInfoFromStorage, userList, setIsPhoneUserInfo, setIsUserPwEdit, setIsUserInfoEdit }) => {
  console.log("userList", userList);
  const handleGoPhoneUserInfo = () => {
    setIsPhoneUserInfo(true);
  };

  const handleGoUserPwEdit = () => {
    setIsUserPwEdit(true);
  };

  const handleGoUserInfoEdit = () => {
    setIsUserInfoEdit(true);
  };

  const navigate = useNavigate();

  return (
    <div className="wrapper user-info">
      <Header Title="회원정보" handleGoBack={"main"} />
      <div className="content p0 pb120">
        <div className="user-profile-box pt40 pb40">
          <div className="img-box" onClick={handleGoPhoneUserInfo}>
            {userInfoFromStorage.userPic === "" ? (
              <img src={profile} alt="유저 이미지" />
            ) : (
              <img src={userInfoFromStorage.userPic} alt="유저 이미지" />
            )}

            {/* <i className="ico-round-camera"></i> */}
          </div>
          <button type="button" onClick={handleGoPhoneUserInfo}>
            <span className="txt">{userInfoFromStorage.nickName ? userInfoFromStorage.nickName : userList.roomNm}</span>
            <i className="ico-right-arr"></i>
          </button>
        </div>

        <div className="info-box">
          <div className="info">
            <p className="tit">아이디</p>
            <div className="desc">
              <p>{userList.userId}</p>
            </div>
          </div>
          {userList.joinType === "mobile" && (
            <div className="info">
              <p className="tit">비밀번호</p>
              <div className="desc">
                <p>
                  <span className="secret">*************</span>
                </p>
                <button type="button" className="modification-btn" onClick={handleGoUserPwEdit}>
                  변경
                </button>
              </div>
            </div>
          )}
          <div className="info">
            <p className="tit">호수</p>
            <div className="desc">
              <p>{userList.roomCd}호</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">업체명</p>
            <div className="desc">
              <p>{userList.roomNm}</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">대표자</p>
            <div className="desc">
              <p>{userList.roomRprsnNm}</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">휴대폰번호</p>
            <div className="desc">
              <p>{formatMobileNoSecret(userList.userMobileNo)}</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">담당자 연락처</p>
            <div className="desc">
              <p> {userList.roomTel !== "" ? formatMobileNo(userList.roomTel) : "-"}</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">이메일 주소</p>
            <div className="desc">
              <p>{userList.roomMail !== "" ? userList.roomMail : "-"}</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">사업자번호</p>
            <div className="desc">
              <p>{formatBsnnNo(userList.roomBizNo)}</p>
            </div>
          </div>
          <div className="info">
            <p className="tit">가입일</p>
            <div className="desc">
              <p>{userList.regDtm}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed-button-box">
        <div className="button-box type2">
          <button
            type="button"
            className="btn1 narrow sub-color"
            onClick={() => {
              navigate("/menu");
            }}
          >
            확인
          </button>
          <button type="button" className="btn1 wide main-color" onClick={handleGoUserInfoEdit}>
            수정
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoView;
