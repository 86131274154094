import Header from "components/ui/common/Header";
import React from "react";
import { useNavigate } from "react-router-dom";

const RegistrationComplete = ({ setIsCardRegComplete, setCardInfoChange }) => {
  const navigate = useNavigate();

  const pageChange = (page) => {
    if (window.ReactNativeWebView) {
      const message = { navigate: page };
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      navigate("/" + page);
    }
  };

  const handleGoBack = () => {
    setCardInfoChange("backToPaymentMethodSoRecount");
    setIsCardRegComplete(false);
  };

  return (
    <div className="wrapper registration-complete">
      <Header Title="결제 카드 등록" handleGoBack={handleGoBack} />
      <div className="content pt64">
        <div className="title-box">
          <i className="ico-complete mb10"></i>
          <h2>
            결제카드를
            <br />
            추가하였습니다.
          </h2>
        </div>
      </div>

      <div className="fixed-button-box">
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              setIsCardRegComplete(false);
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationComplete;
