import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import Header from "components/ui/common/Header";
import Loading from "components/active/Loading";
import { useInView } from "react-intersection-observer";

const Notice = () => {
  const [inViewRef, inView] = useInView();
  const [pushList, setPushList] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const handleGetPushList = async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    try {
      const lastPushCd = pushList.length > 0 ? pushList[pushList.length - 1].pushCD : "start";
      const response = await request(PATH.알림발송내역조회, { pushCd: lastPushCd });
      if (response.data.pushList.length > 0) {
        setPushList((prevPushList) => [...prevPushList, ...response.data.pushList]);
      } else {
        setHasMoreData(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    if (inView && !loading && hasMoreData) {
      handleGetPushList();
    }
  };

  const handleExpand = (i) => {
    setExpandedIndex((isExpanded) => (isExpanded === i ? -1 : i));
  };

  useEffect(() => {
    handleGetPushList();
  }, []);

  useEffect(() => {
    if (hasMoreData) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMoreData, loading, inView]);
  return (
    <React.Fragment>
      {pushList.length > 0 ? (
        <div className="wrapper notice">
          <Header Title="관리비 알림함" handleGoBack={"main"} />
          <div className="content pt40 pb40">
            {pushList.map((v, i) => {
              const isExpanded = expandedIndex === i;
              const contentLines = v.pushContent.split("\n");
              const showButton = contentLines.length > 5;

              return (
                <div className="notice-box recent" key={i}>
                  <span className="date">{v.pushDay}</span>
                  <div className="round-box">
                    <div className={`txt-box ${isExpanded ? "open" : ""}`}>
                      <p className="tit">{v.pushTitle}</p>
                      <p className={`desc ${isExpanded ? "expand" : ""}`}>{v.pushContent}</p>
                    </div>
                    {showButton && (
                      <button
                        type="button"
                        className={`down-btn${isExpanded ? " active" : ""}`}
                        onClick={() => handleExpand(i)}
                      />
                    )}
                  </div>
                  <span className="time">{v.pushTime}</span>
                </div>
              );
            })}
            {loading && <Loading />}
            <div ref={inViewRef} style={{ height: "1px" }} />
          </div>
        </div>
      ) : pushList.length === 0 && !loading ? (
        <React.Fragment>
          <Header Title="관리비 알림함" handleGoBack={"main"} />
          <div className="no-content type2">
            <i className="ico-no-content"></i>
            <p>조회하신 내역이 없습니다.</p>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default Notice;
