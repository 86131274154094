import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "",
  headers: {
    Accept: "application/json; charset=UTF-8",
    "Content-Type": "application/json; charset=UTF-8",
  },
  timeout: 1000 * 600,
  timeoutErrorMessage: "응답대기시간을 초과하였습니다.",
});

export const handleError = ({ message, data, status }) => {
  console.log("axios handleError", message, data, status);
  return Promise.reject({ message, data, status });
};

axiosInstance.interceptors.request.use(
  async (config) => {
    // 요청 전 처리 로직 추가
    // console.log('config', config);
    return config;
  },
  async (error) => {
    // 요청 에러 처리 로직 추가
    console.log("error", error);

    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // 로그인 토큰 설정
    if (response && response.data && response.data.accessToken) {
      // setToken(response.data.accessToken);
    } else if (response?.data?.comm_data?.accessToken) {
      // setToken(response?.data?.comm_data?.accessToken);
    } else {
      loadToken();
    }
    // console.log('response.data@@@@@@@@@', response.data);

    return response;
  },
  (error) => {
    console.debug("interceptors.response.use error", error);
    return Promise.reject(error);
  },
  ({ message, response: { data, status } }) => {
    return handleError({ message, data, status });
  },
);

// api 호출
export const request = async (path, data) => {
  // console.log("data:::", data);
  if (!axiosInstance.defaults.headers.Authorization) {
    axiosInstance.defaults.headers.Authorization = "Bearer " + window.localStorage.getItem("token");
  }
  try {
    let res = null;
    res = await axiosInstance.post(path, data);
    return res;
  } catch (error) {
    console.info("request error", error);
    return error;
  }
};

// token setting
export const setToken = (token) => {
  (async () => {
    try {
      if (token) {
        // console.log('setToken =', window?.localStorage);
        axiosInstance.defaults.headers.Authorization = "Bearer " + token;
        window?.localStorage && window.localStorage.setItem("token", token);
        try {
          const response = await request(PATH.사용자정보조회, {});
          console.log(response.data);
          if (response.data.code === "0000") {
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            if (window.ReactNativeWebView) {
              const accessToken = { accessToken: response.data.accessToken };
              window.ReactNativeWebView.postMessage(JSON.stringify(accessToken));
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("setToken delete");
        delete axiosInstance.defaults.headers.Authorization;
        window?.localStorage && window.localStorage.removeItem("token");
      }
    } catch (error) {
      console.debug("setToken", error);
      return error;
    }
  })();
};

export const loadToken = (navigate, location) => {
  try {
    if (window?.localStorage) {
      const token = window.localStorage.getItem("token");
      const allowedRoutes = [
        // login
        "/login",
        "/login/kakaologin",
        "/login/naverlogin",
        "/login/applelogin",
        "/find",
        "/findResult",
        "/resetPw",
        // join
        "/join",
        "/terms",
        "/phoneVerification",
        "/idJoin",
        "/pwJoin",
        "/roomJoin",
        "/detailJoin",
        "/joinComplete",
        //term
        "/privacy",
      ];

      if (!token && !allowedRoutes.includes(location.pathname)) {
        navigate("/login");
      }
    }
  } catch (error) {
    console.debug("loadToken", error);
  }
};

export const removeToken = async () => {
  // console.debug("removeToken");
  try {
    console.log("setToken delete");
    delete axiosInstance.defaults.headers.Authorization;
    window?.localStorage && window.localStorage.removeItem("token");
  } catch (error) {
    console.debug("removeToken", error);
    return error;
  }
};

export const PATH = {
  /* main */
  사용자정보조회: "/main/getUserInfo",

  /* login */
  로그인: "/login/login",
  소셜로그인: "/login/socialSignOn",
  휴대폰인증: "/login/mobileAuth",
  아이디비밀번호찾기: "/login/getId",
  socialSignOn: "/login/socialSignOn",

  /* join */
  약관목록조회: "/join/getTermsList",
  아이디중복조회: "/join/getId",
  빌딩조회: "/join/getBldInfo",
  사용자회원가입: "/join/joinUser",

  /* user */
  사용자회원목록: "/user/getUserInfo",
  유저비밀번호변경: "/user/updatePW",
  유저이미지업데이트: "/user/updateUserPic",
  회사정보수정: "/user/updateCompanyInfo",
  서비스알림확인: "/user/getPushInfo",
  서비스알림변경: "/user/updatePushInfo",
  유저탈퇴: "/user/updateUserOut",

  /* push */
  알림발송내역조회: "/push/getPushList",

  /* pay */
  카드내역조회: "/pay/getCardInfo",
  카드내역삭제: "/pay/delCardInfo",
  카드내역등록: "/pay/regCardInfo",
  카드자동결제수정: "/pay/updateCardAuth",
  카드결제: "/pay/payMent",
  최종납부일조회: "/pay/getLastBill",
  관리비결제내역조회: "/pay/getBillList",
  관리비결제내역상세조회: "/pay/getBillDetail",
  payMentKsnet: "/pay/payMentKsnet",

  /* bill */
  청구목록조회: "/bill/getBillList",
  청구상세조회: "/bill/getBillDetail",
  카스평균청구목록조회: "/bill/getCasBillList",

  /* board */
  게시판메인: "/board/getBoardMain",
  내가쓴글: "/board/getMyBoard",
  게시판공지: "/board/getBoardNotice",
  게시판공지내역: "/board/getBoardNoticeDetail",
  게시판내역조회: "/board/getBoardList",
  게시판상세내역: "/board/getBoardDetail",
  regBoard: "/board/regBoard",
  게시물수정: "/board/updateBoard",
  게시물삭제: "/board/delBoard",
  리플추가: "/board/regRef",
  리플수정: "/board/updateRef",
  리플삭제: "/board/delRef",
  regBoardAccusation: "/board/regBoardAccusation",

  /* guide */
  getBldInfo: "/guide/getBldInfo",
  getBldNoticeList: "/guide/getBldNoticeList",
  getBldNoticeDetail: "/guide/getBldNoticeDetail",
  getQnaList: "/guide/getQnaList",
  getQnaDetail: "/guide/getQnaDetail",
  updateQna: "/guide/updateQna",
  delQna: "/guide/delQna",
  regQna: "/guide/regQna",

  /* ad */
  getAdList: "/ad/getAdList",
  getAdDetail: "/ad/getAdDetail",

  /* comm */
  uploadImage: "/comm/uploadImage",
};
