import React from "react";

const EditNavigateModal = ({
  nickName,
  closeModal,
  modalMsg,
  setIsUserInfoEdit,
  setIsUserPwEdit,
  setIsPhoneUserInfo,
  twoWayGoBack,
}) => {
  const navigateToUserInfo = () => {
    setIsPhoneUserInfo(false);
  };

  const navigateToUserInfoEdit = () => {
    if (twoWayGoBack) {
      setIsUserPwEdit(false);
      setIsUserInfoEdit(true);
    } else {
      setIsUserPwEdit(false);
    }
  };
  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">
          <p>{modalMsg}</p>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              if (nickName) {
                navigateToUserInfo();
              } else {
                navigateToUserInfoEdit();
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNavigateModal;
