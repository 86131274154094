import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

import Header from "components/ui/common/Header";
import { formatCardNumber } from "utils/fmt";

const AutoPayRegistration = ({
  userList,
  cardInfo,
  setIsAutoPayReg,
  setIsAlert,
  setModalMsg,
  set_is_loading,
  setAlertOnClickName,
}) => {
  const [cardHldr, setCardHldr] = useState("");

  // 카드자동결제수정 api 호출
  const handleUpdateCardAuth = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.카드자동결제수정, {
        cardHldr: cardHldr,
        useYN: "Y",
      });
      console.log("response", response);
      if (response.data.code === "0000") {
        setAlertOnClickName("isAuto");
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else {
        setIsAlert(true);
        setModalMsg("카드정보를 확인 후\n다시 입력해 주세요.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const handleCardHldrChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣ㆍ]/g, "");
    if (input.length > 30) {
      input = input.slice(0, 30);
    }
    setCardHldr(input);
  };

  const handleCardHldrReset = () => {
    setCardHldr("");
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        <Header Title="자동 결제 등록" handleGoBack={() => setIsAutoPayReg(false)} />
        <div className="content pt60">
          <div className="title-box mb38">
            <h2>
              관리비를
              <br />
              자동결제할 정보를 입력해주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className="tf-box">
                  <label htmlFor="04-01">빌딩명</label>
                  <div className="line-box" style={{ borderColor: "#000" }}>
                    <div className="flex-box">
                      <input type="text" id="04-01" className="tf" defaultValue={userList.roomNm} disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className="tf-box">
                  <label htmlFor="04-02">호수</label>
                  <div className="line-box" style={{ borderColor: "#000" }}>
                    <div className="flex-box">
                      <input type="text" id="04-02" className="tf" defaultValue={userList.roomCd + "호"} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-box mt50">
              <div className="tf-box-wrap">
                <div className="tf-box focus">
                  <label htmlFor="04-03">자동결제일</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input type="text" id="04-03" className="tf" defaultValue={cardInfo.payDay + "일"} disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className="tf-box focus">
                  <label htmlFor="04-04">자동결제 수단</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input type="text" id="04-04" className="tf" defaultValue="카드" disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className="tf-box focus">
                  <label htmlFor="04-05">카드번호</label>
                  <div className="line-box">
                    <div className="flex-box">
                      {/* defaultValue="0000 0000 0000 0000" */}
                      <input
                        type="text"
                        id="04-05"
                        className="tf"
                        defaultValue={formatCardNumber(cardInfo.cardNo, " ")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className={`tf-box${cardHldr ? " focus" : ""}`}>
                  <label htmlFor="04-06">카드 소유자명 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="04-06"
                        className="tf"
                        placeholder="카드 소유자명 입력"
                        value={cardHldr}
                        onChange={handleCardHldrChange}
                      />
                      {cardHldr ? <button type="button" className="remove-btn" onClick={handleCardHldrReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {cardHldr.length >= 2 ? (
          <div className="fixed-button-box">
            <div className="button-box">
              <button type="button" className="btn1 main-color" onClick={handleUpdateCardAuth}>
                자동결제 등록
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default AutoPayRegistration;
