import React from "react";
import { useNavigate } from "react-router-dom";

const FindNaivgateModal = ({ responseItems, userId, closeModal, modalMsg, userNm, value, userMobileNo, userPw }) => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  const navigateToFindResult = () => {
    navigate("/findResult", { state: { responseItems: responseItems } });
  };

  const navigateToResetPw = () => {
    navigate("/resetPw", { state: { id: responseItems.id, userNm: userNm, userMobileNo: userMobileNo, value: value } });
  };

  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          {/* <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          /> */}
        </div>
        <div className="desc">
          <p>{modalMsg}</p>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              if (userPw) {
                navigateToLogin();
              } else if (!userId) {
                navigateToFindResult();
              } else {
                navigateToResetPw();
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default FindNaivgateModal;
