import React from "react";

const CardEditNavigateModal = ({ closeModal, modalMsg, setIsCardEdit }) => {
  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">
          <p>{modalMsg}</p>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              setIsCardEdit(false);
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardEditNavigateModal;
