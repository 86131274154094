import React, { useState } from "react";

import DateBottomModal from "components/ui/modal/DateBottomModal";
import Header from "components/ui/common/Header";
import { numberFormat } from "utils/fmt";

const InvoiceMonths = ({
  userList,
  billList,
  twoWayGoBack,
  setTwoWayGoBack,
  frominVoiceMonths,
  setFrominVoiceMonths,
  selectedBill,
  setIsBillDetail,
  setIsInvociceMonths,
  setIsSummaryPayment,
  billDetailList,
  handleListClick,
}) => {
  const excludedBillList = billDetailList.filter((item) => item.billName !== "미납액");
  const [isPopup, setIsPopup] = useState(false);

  const handleGoBack = () => {
    if (!twoWayGoBack) {
      setIsInvociceMonths(false);
      setIsBillDetail(true);
    } else {
      setIsInvociceMonths(false);
      setIsSummaryPayment(true);
      setTwoWayGoBack(false);
    }
  };

  return (
    <>
      {isPopup && (
        <DateBottomModal
          billList={billList}
          closeModal={setIsPopup}
          selectedBill={selectedBill}
          handleListClick={handleListClick}
          setIsBillDetail={setIsBillDetail}
          frominVoiceMonths={frominVoiceMonths}
          setIsInvociceMonths={setIsInvociceMonths}
        />
      )}
      <div className="wrapper history-detail2">
        <Header Title="상세내역" handleGoBack={handleGoBack} structure={"close"} />

        <div className="content pb20">
          <div className="round-box">
            <button
              type="button"
              className="to-modal-btn2 mb22"
              onClick={() => {
                setIsPopup(true);
                setFrominVoiceMonths(true);
              }}
            >
              {selectedBill.billMonth.substring(0, 4)}년 {selectedBill.billMonth.substring(4)}월
            </button>

            <div className="title-box mb50">
              <h2>
                {userList.roomNm + userList.roomCd}호
                <span className="cost">
                  <strong className="num">{numberFormat(selectedBill.billSum)}</strong>원
                </span>
              </h2>
            </div>

            <div className="list-box">
              <div className="list">
                <p className="tit">청구서 번호</p>
                <p className="desc">NO{userList.roomCd + selectedBill.billMonth}</p>
              </div>
              <div className="list">
                <p className="tit">청구일</p>
                <p className="desc">
                  {selectedBill.regDtm.substring(0, 4)}.{selectedBill.regDtm.substring(4, 6)}.
                  {selectedBill.regDtm.substring(6)}
                </p>
              </div>
              <div className="list">
                <p className="tit">납기일</p>
                <p className="desc">
                  {selectedBill.billDeadline.substring(0, 4)}.{selectedBill.billDeadline.substring(4, 6)}.
                  {selectedBill.billDeadline.substring(6)}
                </p>
              </div>
            </div>
            <div className="list-box-wrap mt90">
              <h2 className="mb12">관리비 항목</h2>
              <div className="list-box type2">
                {excludedBillList.map((item, index) => (
                  <div className="list" key={index}>
                    <p className="tit">{item.billName}</p>
                    <p className="desc">{numberFormat(item.billAmount)}원</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceMonths;
