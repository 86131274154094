import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";
import Loading from "components/active/Loading";
import { QNA_TYPE_LIST } from "utils/Common";
import SelectBottomSheet from "components/ui/common/SelectBottomSheet";
import { filterValue } from "utils/fmt";
import moment from "moment";

const QnaDetail = ({ detail, show_detail_change, show_regpage_change }) => {
  const [is_bottomsheet, set_is_bottomsheet] = useState(false);
  const [is_loading, set_is_loading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const bottomsheetClose = async () => {
    try {
      set_is_bottomsheet(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateQna = async () => {
    try {
      show_detail_change();
      show_regpage_change();
    } catch (error) {
      console.error(error);
    }
  };

  const delQna = async () => {
    try {
      set_is_loading(true);
      const req = {
        qnaCd: detail.qna_seq,
      };
      const response = await request(PATH.delQna, req);
      set_is_loading(false);
      if (response.data.code === "0000") {
        show_detail_change();
      } else {
        set_is_bottomsheet(false);
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const onClick = async () => {
    setIsAlert(true);
    setModalMsg("삭제하시겠습니까?\n삭제한 내역은 복구가 불가능합니다.");
    set_is_bottomsheet(false);
  };

  const bottomsheet_list = [
    { name: "수정", value: updateQna },
    { name: "삭제", value: onClick },
    { name: "취소", value: bottomsheetClose },
  ];

  return (
    <React.Fragment>
      {isAlert && (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            if (modalMsg === "삭제하시겠습니까?\n삭제한 내역은 복구가 불가능합니다.") {
              delQna();
            }
          }}
          isCancel={true}
        />
      )}
      {is_loading && <Loading />}
      {is_bottomsheet && (
        <SelectBottomSheet
          title={"게시물"}
          set_is_bottomsheet={set_is_bottomsheet}
          bottomsheet_list={bottomsheet_list}
        />
      )}
      <div className="wrapper inquiry">
        <Header Title="1:1 문의" handleGoBack={show_detail_change} />

        <div className="content p0 pb120">
          <div className="inq-tit-box mt16">
            <div className="justify-between mb16">
              <em className="sort"> {filterValue(QNA_TYPE_LIST, detail.qna_ctc)}</em>
              <span className={detail.res_yn === 0 ? "state main-color" : "state"}>
                {detail.res_yn === 0 ? "대기중" : "답변완료"}
              </span>
            </div>
            <p className="tit txt1">{detail.title}</p>
            <div className="justify-between mt16">
              <p className="txt2">{moment(detail.inq_dt).format("YYYY.MM.DD HH:mm")}</p>
              {detail.res_yn === 0 ? (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => {
                    set_is_bottomsheet(true);
                  }}
                ></button>
              ) : null}
            </div>
          </div>

          <div className="inq-desc-box">
            <p
              style={{
                whiteSpace: "pre-wrap",
                textOverflow: "clip",
                lineHeight: "1.6",
              }}
            >
              {detail.content}
            </p>
          </div>

          {detail.res_yn === 0 ? (
            <div className="no-content">
              <i className="ico-no-content"></i>
              <p>답변내역이 없습니다.</p>
            </div>
          ) : (
            <div className="inq-reply-box">
              <div className="round-box type2">
                <p>{detail.answer}</p>
              </div>
              <p className="txt2 pt10">{moment(detail.ans_dt).format("YYYY.MM.DD HH:mm")}</p>
            </div>
          )}
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className="btn1 main-color"
              onClick={() => {
                show_detail_change();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QnaDetail;
