import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

import AlertImage from "components/ui/common/AlertImage";
import Header from "components/ui/common/Header";
import imageCompression from "browser-image-compression";
import { onChange } from "utils/Common";
import profile from "assets/images/icon/icon_user_profile.svg";
import { useNavigate } from "react-router-dom";

const PhoneUserInfo = ({ setIsPhoneUserInfo, setIsAlert, setModalMsg }) => {
  const navigate = useNavigate();

  const [inputs, inputsSet] = useState({
    nickName: JSON.parse(localStorage.getItem("userInfo")).nickName,
  });

  const [userpic, set_userpic] = useState(JSON.parse(localStorage.getItem("userInfo")).userPic || "");
  const [is_modal_img, set_is_modal_img] = useState(false);

  const handleUpdateUserPic = async () => {
    let img_url = "";
    try {
      if (userpic.indexOf("data:image") === -1) {
        img_url = userpic;
      } else {
        let fileObj = userpic.split(",");
        const img_response = await request(PATH.uploadImage, {
          locate: "userInfo",
          base64Img: [{ type: "image/jpg", data: fileObj[1] }],
        });

        if (img_response.data.code !== "0000") {
          setIsAlert(true);
          setModalMsg(img_response.data.message);
          throw new Error("이미지 업로드 에러");
        } else if (img_response.data.code === "0000") {
          img_url = img_response.data.savedPathArr[0];
        }
      }

      const response = await request(PATH.유저이미지업데이트, {
        nickName: inputs.nickName,
        userPic: img_url,
      });
      // console.log("handleUpdateUserPic", response.data);
      if (response.data.code !== "0000") {
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else if (response.data.code === "0000") {
        const user_response = await request(PATH.사용자정보조회, {});
        if (user_response.data.code === "0000") {
          localStorage.setItem("userInfo", JSON.stringify(user_response.data));
          setIsAlert(true);
          setModalMsg("프로필 변경이 완료되었습니다.");
          setIsPhoneUserInfo(false);
        } else {
          setIsAlert(true);
          setModalMsg(response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNickNameReset = () => {
    inputsSet({ nickName: "" });
  };

  const handleGoBack = () => {
    setIsPhoneUserInfo(false);
    navigate("/userInfo");
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const setImage = async (e) => {
    console.log("이미지 변경");
    const selectedFile = e.target.files[0];

    const options = {
      maxSizeMB: 2, // 2MB 미만으로 압축
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(selectedFile, options);
      const compressedBase64 = await convertBlobToBase64(compressedFile);

      set_userpic(compressedBase64);
    } catch (error) {
      console.error("이미지 압축 실패:", error);
    }
    set_is_modal_img(false);
  };

  return (
    <React.Fragment>
      {is_modal_img && <AlertImage title="프로필 이미지" set_is_modal_img={set_is_modal_img} setImage={setImage} />}
      <div>
        <div className="wrapper user-info phone-user">
          <Header Title="프로필 변경" handleGoBack={handleGoBack} structure={"close"} />

          <div className="content">
            <div className="user-profile-box pt40 pb40">
              <div
                className="img-box"
                onClick={() => {
                  set_is_modal_img(true);
                }}
              >
                {userpic === "" ? <img src={profile} alt="유저 이미지" /> : <img src={userpic} alt="유저 이미지" />}

                <i className="ico-round-camera"></i>
              </div>
            </div>

            <div className="title-box mb38">
              <h2>
                사용할 닉네임을
                <br />
                입력해 주세요.
              </h2>
            </div>

            <div className="form-box-wrap">
              <div className="form-box">
                <div className="tf-box-wrap">
                  <div className={`tf-box${inputs.nickName ? " focus" : ""}`}>
                    <label htmlFor="nickname-user">닉네임 입력</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="text"
                          id="nickname-user"
                          className="tf"
                          placeholder="닉네임 입력"
                          name="nickName"
                          value={inputs.nickName}
                          onChange={(e) => onChange(e, inputs, inputsSet, 20, "")}
                        />
                        {inputs.nickName !== "" ? (
                          <button type="button" className="remove-btn" onClick={handleNickNameReset} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fixed-button-box">
            <div className="button-box">
              <button type="button" className="btn1 main-color" onClick={handleUpdateUserPic}>
                변경
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PhoneUserInfo;
