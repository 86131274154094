import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";
import Loading from "components/active/Loading";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";

export default function Ad() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inViewRef, inView] = useInView();
  const [last_adcd, set_last_adcd] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [is_loading, set_is_loading] = useState(false);
  const [show_detail, set_show_detail] = useState(false);

  const [detail_ad, set_detail_ad] = useState({});
  const [list, set_list] = useState([]);

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const [fristOpen, setFristOpen] = useState(true);

  const getAdList = async () => {
    if (is_loading || !hasMoreData) return;
    try {
      set_is_loading(true);
      const response = await request(PATH.getAdList, { lastAdCd: last_adcd });
      if (response.data.code === "0000") {
        if (response.data.adList.length > 0) {
          set_list((prevPushList) => [...prevPushList, ...response.data.adList]);
          set_last_adcd(response.data.adList[response.data.adList.length - 1].adCd);
        } else {
          setHasMoreData(false);
        }
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const getNextAdList = async ({ adCd, moveAd }) => {
    try {
      set_is_loading(true);
      const response = await request(PATH.getAdDetail, { adCd: adCd, moveAd: moveAd });

      if (response.data.code === "0000") {
        set_detail_ad(response.data.adDetail);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const close_detail = async () => {
    try {
      set_show_detail(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAdList();
  }, []);

  useEffect(() => {
    if (inView && !is_loading && hasMoreData) {
      getAdList();
    }
  }, [inView]);

  useEffect(() => {
    if (list.length > 0 && fristOpen === true) {
      setFristOpen(false);
      if (searchParams.get("page")) {
        const index = searchParams.get("page");
        set_show_detail(true);
        getNextAdList({ adCd: Number(index), moveAd: "" });
      }
    }
  }, [list]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {show_detail ? (
        is_loading ? (
          <Loading />
        ) : (
          <div className="wrapper advertisement-detail">
            <Header Title="업체 광고" handleGoBack={close_detail} />
            <div className="content pt40">
              <h2>{detail_ad.adTitle}</h2>
              <p className="txt2 mt10">{detail_ad.regDtm}</p>
              <img
                alt="이미지가 없습니다."
                src={detail_ad.adPic}
                onClick={() => {
                  if (detail_ad.adUrl.length > 1) {
                    window.open(detail_ad.adUrl, "_blank", "noopener, noreferrer");
                  }
                }}
              />
              <ul className="num-box type2">
                <li>
                  <i className="ico-view"></i>
                  {detail_ad.viewCount}
                </li>
              </ul>

              <div className="fixed-button-box">
                <div className="button-box type2">
                  <button
                    type="button"
                    className="btn1 narrow sub-color"
                    onClick={() => {
                      close_detail();
                    }}
                  >
                    목록
                  </button>
                  <button
                    type="button"
                    className="btn1 wide main-color"
                    onClick={() => {
                      if (detail_ad.preCd !== null) {
                        getNextAdList({ adCd: detail_ad.adCd, moveAd: "after" });
                      } else {
                        setIsAlert(true);
                        setModalMsg("마지막 내역입니다.");
                      }
                    }}
                  >
                    다음
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        //업체광고 메인 페이지
        <div className="wrapper advertisement">
          <Header Title="업체 광고" handleGoBack={"main"} />
          <div className="content pt40 pb40">
            {is_loading && <Loading />}
            {list.length !== 0
              ? list.map((list_map, index) => {
                  return (
                    <div
                      className="ad-box"
                      key={`list_map_${index}`}
                      onClick={() => {
                        getNextAdList({ adCd: list_map.adCd, moveAd: "" });
                        set_show_detail(true);
                        // set_detail_ad(list_map);
                      }}
                    >
                      <h2>{list_map.adTitle}</h2>
                      <img alt="이미지가 없습니다." src={list_map.adListPic} />
                      <div className="justify-between">
                        <p className="txt2">{list_map.regDtm}</p>
                        <ul className="num-box">
                          <li>
                            <i className="ico-view"></i>
                            {list_map.viewCount}
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              : null}

            {list.length === 0 && !is_loading && (
              <div className="no-content type2">
                <i className="ico-no-content"></i>
                <p>조회하신 내역이 없습니다.</p>
              </div>
            )}
            <div ref={inViewRef} style={{ height: "1px" }} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
