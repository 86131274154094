import { JoinContext, JoinInitialValue, joinLocalRemove } from "components/shared/JoinContext";
import React, { useContext, useEffect, useState } from "react";
import { appleLogin, kakaoLogin, naverLogin } from "components/shared/SocialLogin";

import { useNavigate } from "react-router-dom";

const Join = () => {
  const navigate = useNavigate();
  const { setJoinProps } = useContext(JoinContext);

  const handleGoBack = () => {
    navigate("/login");
  };

  useEffect(() => {
    joinLocalRemove();
    setJoinProps({ ...JoinInitialValue });
  }, []);
  return (
    <div className="wrapper join">
      <header>
        <div className="h-btn-box">
          <button type="button" className="back-btn" onClick={handleGoBack} />
        </div>
        <h1>회원가입</h1>
      </header>

      <div className="content pt60 pb20">
        <div className="title-box mb40">
          <h2>
            가입 방법중 하나를
            <br />
            선택해 주세요.
          </h2>
        </div>

        <ul className="social-join">
          <li>
            <button type="button" onClick={kakaoLogin}>
              <i className="ico-kakao"></i>
              <span>카카오로 회원가입</span>
            </button>
          </li>
          <li>
            <button type="button" onClick={naverLogin}>
              <i className="ico-naver"></i>
              <span>네이버로 회원가입</span>
            </button>
          </li>
          <li>
            <button type="button" onClick={appleLogin}>
              <i className="ico-apple"></i>
              <span>애플 회원가입</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                navigate("/terms");
              }}
            >
              <i className="ico-user"></i>
              <span>일반 회원가입</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Join;
