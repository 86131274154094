import React, { useState } from "react";

import AutoPayCancelModal from "components/ui/modal/AutoPayCancelModal";
import Header from "components/ui/common/Header";
import { formatCardNumber } from "utils/fmt";
import moment from "moment";

const AutoPayCancel = ({ userList, cardInfo, setIsAutoPayDel, setCardInfoChange, set_is_loading }) => {
  const [isComplete, setIsComplete] = useState(false);

  const handleGoBack = () => {
    setIsAutoPayDel(false);
  };

  return (
    <React.Fragment>
      {isComplete && (
        <AutoPayCancelModal
          cardInfo={cardInfo}
          closeModal={setIsComplete}
          setIsAutoPayDel={setIsAutoPayDel}
          setCardInfoChange={setCardInfoChange}
          set_is_loading={set_is_loading}
        />
      )}
      <div className="wrapper">
        <Header Title="자동 결제 해지" handleGoBack={handleGoBack} />
        <div className="content pt60">
          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className="tf-box focus">
                  <label htmlFor="04-07">카드번호</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="04-07"
                        className="tf"
                        defaultValue={formatCardNumber(cardInfo.cardNo, " ")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className="tf-box focus">
                  <label htmlFor="04-08">소유자명</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input type="text" id="04-08" className="tf" defaultValue={cardInfo.cardHldr} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tf-box-wrap">
              <div className="tf-box focus">
                <label htmlFor="04-09">빌딩명</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input type="text" id="04-09" className="tf" defaultValue={userList.roomNm} disabled />
                  </div>
                </div>
              </div>
            </div>
            <div className="tf-box-wrap">
              <div className="tf-box focus">
                <label htmlFor="04-10">호수</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input type="text" id="04-10" className="tf" defaultValue={userList.roomCd + "호"} disabled />
                  </div>
                </div>
              </div>
            </div>
            <div className="tf-box-wrap">
              <div className="tf-box focus">
                <label htmlFor="04-11">자동결제 등록일</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input
                      type="text"
                      id="04-11"
                      className="tf"
                      defaultValue={moment(cardInfo.cardAutoTime).format("YYYY년 MM월 DD일")}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className="btn1 main-color"
              onClick={() => {
                setIsComplete(true);
              }}
            >
              자동결제 해지
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AutoPayCancel;
