import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";
import SettingNavigateModal from "../modal/SettingNavigateModal";

const WithdrawalPw = ({ setWithdrawal, setWithdrawalPw }) => {
  const [userPw, setUserPw] = useState("");
  const [isNavigate, setIsNavigate] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [social, set_social] = useState(JSON.parse(localStorage.getItem("userInfo")).socialType || "");

  const handleGoBack = () => {
    setWithdrawal(true);
    setWithdrawalPw(false);
  };

  const handleUserPwReset = () => {
    setUserPw("");
  };

  useEffect(() => {
    if (social !== "") {
      setUserPw("social");
    }
  }, []);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {isNavigate === true ? (
        <SettingNavigateModal
          closeModal={setIsNavigate}
          userPw={userPw}
          modalMsg={"회원탈퇴 후 신청한 회원의 모든 정보는 삭제됩니다."}
        />
      ) : null}
      <div className="wrapper edit-pw">
        <Header Title="회원탈퇴" handleGoBack={handleGoBack} />
        <div className="content pt60">
          {social !== "" ? (
            <div className="title-box mb60">
              <h2>탈퇴 하시겠습니까?</h2>
            </div>
          ) : (
            <React.Fragment>
              <div className="title-box mb60">
                <h2>
                  사용중이셨던 로그인 비밀번호를
                  <br />
                  입력해주세요.
                </h2>
              </div>

              <div className="form-box-wrap">
                <div className="form-box">
                  <div className="tf-box-wrap">
                    <div className={`tf-box${userPw ? " focus" : ""}`}>
                      <label htmlFor="origin-pw" className="sr-only">
                        사용중인 비밀번호 입력
                      </label>
                      <div className="line-box">
                        <div className="flex-box">
                          <input
                            type="password"
                            id="origin-pw"
                            className="tf"
                            placeholder="사용중인 비밀번호 입력"
                            value={userPw}
                            onChange={(e) => setUserPw(e.target.value)}
                          />
                          {userPw ? <button type="button" className="remove-btn" onClick={handleUserPwReset} /> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="fixed-button-box">
          <div className="button-box type2">
            <button type="button" className="btn1 narrow sub-color" onClick={handleGoBack}>
              취소
            </button>
            <button
              type="button"
              className={`btn1 wide${userPw ? " main-color" : " disabled"}`}
              onClick={() => {
                if (userPw) {
                  setIsNavigate(true);
                } else {
                  setIsAlert(true);
                  setModalMsg("비밀번호를 입력해 주세요.");
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WithdrawalPw;
