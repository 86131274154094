import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import Header from "components/ui/common/Header";
import Loading from "components/active/Loading";
import { QNA_TYPE_LIST } from "utils/Common";
import SelectBottomSheet from "components/ui/common/SelectBottomSheet";
import { filterValue } from "utils/fmt";
import { onChange } from "utils/Common";

const QnaReg = ({ detail, show_regpage_change, setIsAlert, setModalMsg }) => {
  const [is_bottomsheet, set_is_bottomsheet] = useState(false);
  const [is_loading, set_is_loading] = useState(false);

  const [qna_category, set_qna_category] = useState("");
  const [qna_category_name, set_qna_category_name] = useState("문의 유형 선택");
  const [personal_info_terms, set_personal_info_terms] = useState("");
  const [terms_check, set_terms_check] = useState(false);

  const [inputs, inputsSet] = useState({
    qna_title: "",
    qna_content: "",
  });

  const getAccountTerms = async (code) => {
    try {
      const response = await request(PATH.약관목록조회, {
        code: "personal_info_terms",
      });
      if (response.data.code === "0000") {
        set_personal_info_terms(response.data.terms_list.terms_content);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const regQna = async () => {
    try {
      if (!detail.content && !terms_check) {
        setIsAlert(true);
        setModalMsg("개인정보 처리방침 동의가 필요합니다.");
        throw new Error("개인정보 처리방침 동의 필요");
      }
      set_is_loading(true);
      let req = {
        qnaCategory: qna_category,
        qnaTitle: inputs.qna_title,
        qnaContent: inputs.qna_content,
      };
      let response = {};

      if (detail.hasOwnProperty("title")) {
        req.qnaCd = detail.qna_seq;
        response = await request(PATH.updateQna, req);
      } else {
        response = await request(PATH.regQna, req);
      }

      set_is_loading(false);
      if (response.data.code === "0000") {
        show_regpage_change();
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const categoryChange = async (value, name) => {
    try {
      set_qna_category(value);
      set_qna_category_name(name);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const bottomsheet_list = [
    {
      name: "결제관련",
      value: () => {
        categoryChange("pay", "결제관련");
        set_is_bottomsheet(false);
      },
    },
    {
      name: "오류관련",
      value: () => {
        categoryChange("err", "오류관련");
        set_is_bottomsheet(false);
      },
    },
    {
      name: "취소관련",
      value: () => {
        categoryChange("cancle", "취소관련");
        set_is_bottomsheet(false);
      },
    },
    {
      name: "이용관련",
      value: () => {
        categoryChange("use", "이용관련");
        set_is_bottomsheet(false);
      },
    },
    {
      name: "기타",
      value: () => {
        categoryChange("etc", "기타");
        set_is_bottomsheet(false);
      },
    },
  ];

  useEffect(() => {
    getAccountTerms();
    if (detail.hasOwnProperty("title")) {
      set_qna_category(detail.qna_ctc);
      set_qna_category_name(filterValue(QNA_TYPE_LIST, detail.qna_ctc));
      inputsSet({ qna_title: detail.title, qna_content: detail.content });
    }
  }, []);

  return (
    <React.Fragment>
      {is_loading && <Loading />}
      {is_bottomsheet && (
        <SelectBottomSheet
          title={"문의유형"}
          set_is_bottomsheet={set_is_bottomsheet}
          bottomsheet_list={bottomsheet_list}
        />
      )}
      <div className="wrapper inquiry">
        <Header
          Title={detail.content ? "1:1 문의 수정" : "1:1 문의 등록"}
          handleGoBack={show_regpage_change}
          structure={"close"}
        />
        <div className="content">
          <div className="form-box-wrap mt34">
            <div className="form-box">
              <button
                type="button"
                className="to-modal-btn mb28"
                onClick={() => {
                  set_is_bottomsheet(true);
                }}
              >
                {qna_category_name}
              </button>

              <div className="tf-box-wrap">
                <div className="tf-box">
                  <label htmlFor="title-inq">제목</label>
                  <div className="line-box">
                    <input
                      type="text"
                      id="title-inq"
                      className="tf"
                      placeholder="제목을 입력하세요.(40자내)"
                      name="qna_title"
                      value={inputs.qna_title}
                      onChange={(e) => onChange(e, inputs, inputsSet, 40, "", setIsAlert, setModalMsg)}
                    />
                  </div>
                </div>
              </div>

              <div className="textarea-box mt50">
                <label htmlFor="detail-desc">문의 내용을 입력하세요</label>
                <textarea
                  id="detail-desc"
                  name="qna_content"
                  value={inputs.qna_content}
                  onChange={(e) => onChange(e, inputs, inputsSet, 1000, "", setIsAlert, setModalMsg)}
                ></textarea>
                <span className="total">
                  <span className="num-word" />
                  {inputs.qna_content.length}/1000
                </span>
              </div>
            </div>
          </div>

          {detail.content ? null : (
            <div className="inq-agree-box mt50">
              <h2>개인정보 처리방침 동의</h2>
              <div className="round-box type4">
                <p style={{ whiteSpace: "pre-wrap", textOverflow: "clip" }}>{personal_info_terms}</p>
              </div>
              <div className="inq-agree items-center mt12">
                <input
                  type="checkbox"
                  id="agree-inq"
                  checked={terms_check}
                  onChange={() => set_terms_check(!terms_check)}
                />
                <label htmlFor="agree-inq">개인정보 처리방침에 동의합니다.</label>
              </div>
            </div>
          )}
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${
                qna_category_name && inputs.qna_title && inputs.qna_content && (detail.content || terms_check)
                  ? " main-color"
                  : " disabled"
              }`}
              onClick={() => {
                regQna();
              }}
            >
              {detail.content ? "수정" : "등록"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QnaReg;
