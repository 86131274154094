import React, { createContext, useState } from "react";

const JoinContext = createContext();

const JoinInitialValue = {
  receipApp: "",
  receipSms: "",
  receipEmail: "",
  userNm: "",
  userMobileNo: "",
  value: "",
  userId: "",
  userPw: "",
  city: "",
  district: "",
  dong: "",
  bld: "",
  bldCd: "",
  roomCd: "",
  social: "N",
};
const JoinProvider = ({ children }) => {
  const [joinProps, setJoinProps] = useState({ ...JoinInitialValue });

  return <JoinContext.Provider value={{ joinProps, setJoinProps }}>{children}</JoinContext.Provider>;
};

const joinLocalRemove = () => {
  localStorage.removeItem("allAgreeChecked");
  localStorage.removeItem("termsChecked");
  localStorage.removeItem("personalInfoChecked");
  localStorage.removeItem("thirdPartyChecked");
  localStorage.removeItem("receipAllChecked");
  localStorage.removeItem("receipAppChecked");
  localStorage.removeItem("receipSmsChecked");
  localStorage.removeItem("receipEmailChecked");
};

export { JoinInitialValue, JoinContext, JoinProvider, joinLocalRemove };
