import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import BottomSheet from "components/ui/common/BottomSheet";
import Loading from "components/active/Loading";
import PaymentHistoryDetail from "components/ui/paymentHistory/PaymentHistoryDetail";
import PaymentHistoryView from "components/ui/paymentHistory/PaymentHistoryView";
import moment from "moment";
import { useInView } from "react-intersection-observer";

export default function PaymentHistory() {
  const [lastBillInfo, setLastBillInfo] = useState([]);
  const [billInfo, setBillInfo] = useState([]);
  const [bldInfo, setBldInfo] = useState([]);
  const [billDetailInfo, setBillDetailInfo] = useState([]);
  const [isPaymentHistoryDetail, setIsPaymentHistoryDetail] = useState(false);
  const [selectedBill, setSelectedBill] = useState("");

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const [inViewRef, inView] = useInView();
  const [last_cd, set_last_cd] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [is_loading, set_is_loading] = useState(false);

  const [show_bottom_sheet, set_show_bottom_sheet] = useState(false);

  const order_by_list = [
    { name: "최신순", value: "DESC" },
    { name: "과거순", value: "ASC" },
  ];
  const [order_by, set_order_by] = useState("DESC");
  const [api_order_by, set_api_order_by] = useState("DESC");

  const dt_list = [
    {
      name: "최근3개월",
      str_dt: moment().subtract(3, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    {
      name: "최근6개월",
      str_dt: moment().subtract(6, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    {
      name: "최근12개월",
      str_dt: moment().subtract(12, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    { name: "월별", str_dt: "", end_dt: "" },
    { name: "기간설정", str_dt: "", end_dt: "" },
  ];

  const [dt, set_dt] = useState("최근6개월");
  const [str_dt, set_str_dt] = useState(dt_list[2].str_dt);
  const [end_dt, set_end_dt] = useState(dt_list[2].end_dt);
  const [api_str_dt, set_api_str_dt] = useState(dt_list[0].str_dt);
  const [api_end_dt, set_api_end_dt] = useState(dt_list[0].end_dt);

  const research = async () => {
    set_api_order_by(order_by);
    set_api_str_dt(str_dt);
    set_api_end_dt(end_dt);

    setBillInfo([]);
    setHasMoreData(true);
    set_last_cd(0);
    set_show_bottom_sheet(false);
  };

  // 최종납부일조회 api 호출
  const handleGetLastBill = async () => {
    try {
      const response = await request(PATH.최종납부일조회, {});
      if (response.data.code === "0000") {
        setLastBillInfo(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 관리비결제내역 api 호출
  const handlePayGetBillList = async () => {
    if (is_loading || !hasMoreData) return;
    try {
      set_is_loading(true);
      const response = await request(PATH.관리비결제내역조회, {
        strDt: api_str_dt,
        endDt: api_end_dt,
        orderBy: api_order_by,
        lastBillMonth: last_cd,
      });
      // console.log("handlePayGetBillList::::", response.data);
      if (response.data.code === "0000") {
        if (response.data.billInfo.length > 0) {
          setBldInfo(response.data.bldInfo);
          setBillInfo((prevPushList) => [...prevPushList, ...response.data.billInfo]);
          set_last_cd(response.data.billInfo[response.data.billInfo.length - 1].billMonth);
        } else {
          setHasMoreData(false);
        }
      } else {
        setBillInfo([]);
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  // 관리비결제내역상세조회 api 호출
  const handlePayGetBillDetail = async () => {
    try {
      set_is_loading(true);
      const response = await request(PATH.관리비결제내역상세조회, {
        billMonth: selectedBill?.billMonth,
      });
      if (response.data.code === "0000") {
        setBillDetailInfo(response.data.billDetail);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  useEffect(() => {
    set_order_by("DESC");
    set_str_dt(dt_list[2].str_dt);
    set_end_dt(dt_list[2].end_dt);
    set_dt("최근6개월");

    set_api_order_by("DESC");
    set_api_str_dt(dt_list[0].str_dt);
    set_api_end_dt(dt_list[0].end_dt);

    handleGetLastBill();
  }, []);

  useEffect(() => {
    if (last_cd === 0) {
      setBillInfo([]);
      setHasMoreData(true);
      set_last_cd("");
    } else if (last_cd === "") {
      handlePayGetBillList();
    }
  }, [last_cd]);

  useEffect(() => {
    if (inView && !is_loading && hasMoreData) {
      handlePayGetBillList();
    }
  }, [inView]);

  useEffect(() => {
    if (selectedBill !== "") {
      handlePayGetBillDetail();
    }
  }, [selectedBill]);

  const renderItems = () => {
    let result;
    if (isPaymentHistoryDetail) {
      result = (
        <PaymentHistoryDetail
          setIsPaymentHistoryDetail={setIsPaymentHistoryDetail}
          selectedBill={selectedBill}
          bldInfo={bldInfo}
          billDetailInfo={billDetailInfo}
          is_loading={is_loading}
        />
      );
    } else {
      result = (
        <PaymentHistoryView
          billInfo={billInfo}
          lastBillInfo={lastBillInfo}
          setIsPaymentHistoryDetail={setIsPaymentHistoryDetail}
          setSelectedBill={setSelectedBill}
          set_show_bottom_sheet={set_show_bottom_sheet}
          inViewRef={inViewRef}
          dt={dt}
          order_by={order_by}
          is_loading={is_loading}
        />
      );
    }

    return result;
  };

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {is_loading && <Loading />}
      {show_bottom_sheet && (
        <BottomSheet
          set_show_bottom_sheet={set_show_bottom_sheet}
          order_by_list={order_by_list}
          order_by={order_by}
          set_order_by={set_order_by}
          dt_list={dt_list}
          dt={dt}
          set_dt={set_dt}
          str_dt={str_dt}
          set_str_dt={set_str_dt}
          end_dt={end_dt}
          set_end_dt={set_end_dt}
          research={research}
        />
      )}
      {renderItems()}
    </React.Fragment>
  );
}
