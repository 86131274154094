import React, { useEffect, useState } from "react";

const BoardBottomModal = ({
  setElement,
  boardType,
  isAreaBottomSheet,
  setIsEnrollBoard,
  closeModal,
  boardNm,
  setIsDelModal,
  setBoardType,
  isEditBottomSheet,
  isRegBottomSheet,
  setClickEdit,
  propsIndex,
  boardListDetail,
  setBoardListDetail,
  refLevel,
}) => {
  const EDIT_LIST = [
    { value: "수정", name: "수정" },
    { value: "삭제", name: "삭제" },
    { value: "취소", name: "취소" },
  ];

  const BOARD_CATEGORY_LIST = [
    { value: "주식", name: "주식" },
    { value: "IT", name: "IT" },
    { value: "자료실", name: "자료실" },
    { value: "취업", name: "취업" },
    { value: "취미", name: "취미" },
    { value: "지식", name: "지식" },
    { value: "생활", name: "생활" },
    { value: "기타", name: "기타" },
  ];

  const BOARD_CATEGORY_LIST2 = [
    { value: "구인", name: "구인" },
    { value: "구직", name: "구직" },
  ];

  const BOARD_CATEGORY_LIST3 = [
    { value: "자유", name: "자유" },
    { value: "유머", name: "유머" },
    { value: "스포츠", name: "스포츠" },
    { value: "게임", name: "게임" },
    { value: "연애", name: "연예" },
  ];

  const BOARD_AREA_LIST = [
    { value: "서울", name: "서울" },
    { value: "경기", name: "경기" },
    { value: "강원도", name: "강원도" },
    { value: "충청도", name: "충청도" },
    { value: "경상도", name: "경상도" },
    { value: "전라도", name: "전라도" },
    { value: "제주도", name: "제주도" },
  ];
  const [bottomsheet_list, set_bottomsheet_list] = useState([]);
  const [bottom_title, set_bottom_title] = useState("");

  const handleListClick = (index) => {
    if (isEditBottomSheet && !isRegBottomSheet && bottomsheet_list[index].name === "수정") {
      setIsEnrollBoard(true);
      closeModal(false);
    } else if (!isEditBottomSheet && isRegBottomSheet && bottomsheet_list[index].name === "수정") {
      setClickEdit("수정");
      let new_boardListDetail = boardListDetail;
      new_boardListDetail.boardReply[propsIndex].write = "true";
      setBoardListDetail({ ...new_boardListDetail });
      closeModal(false);
    } else if (bottomsheet_list[index].name === "삭제") {
      setIsDelModal(true);
      closeModal(false);
    } else if (bottomsheet_list[index].name === "취소") {
      closeModal(false);
    } else if (bottomsheet_list[index].name === "구인" || bottomsheet_list[index].name === "구직") {
      const clickedBill = bottomsheet_list[index].name;
      setBoardType(clickedBill);
      closeModal(false);
    } else {
      const clickedBill = bottomsheet_list[index].name;
      setElement(clickedBill);
      closeModal(false);
    }
  };

  useEffect(() => {
    if (isAreaBottomSheet) {
      set_bottom_title("지역선택");
      set_bottomsheet_list(BOARD_AREA_LIST);
    } else if (boardType === "지식산업센터" || boardType === "오피스텔빌딩" || boardType === "기타") {
      set_bottom_title("분류선택");
      set_bottomsheet_list(BOARD_CATEGORY_LIST);
    } else if (
      (boardNm === "구인구직" && boardType === "") ||
      (boardNm === "구인구직" && boardType === "구인") ||
      (boardNm === "구인구직" && boardType === "구직")
    ) {
      set_bottom_title("분류선택");
      set_bottomsheet_list(BOARD_CATEGORY_LIST2);
    } else if (boardNm === "자유게시판") {
      set_bottom_title("분류선택");
      set_bottomsheet_list(BOARD_CATEGORY_LIST3);
    } else if (isEditBottomSheet && !isRegBottomSheet) {
      if (boardListDetail.boardReply.length !== 0) {
        const updatedList = EDIT_LIST.filter((item) => item.value !== "삭제");
        set_bottom_title("게시물");
        set_bottomsheet_list(updatedList);
      } else {
        set_bottom_title("게시물");
        set_bottomsheet_list(EDIT_LIST);
      }
    } else if (!isEditBottomSheet && isRegBottomSheet) {
      if (refLevel === "0") {
        set_bottom_title("댓글");
      } else {
        set_bottom_title("답글");
      }

      set_bottomsheet_list(EDIT_LIST);
    }
  }, [boardType]);

  return (
    <div className="bottom-sheet-modal type2">
      <div className="box">
        <div className="tit-box justify-between">
          <h2 className="type2">{bottom_title}</h2>
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>

        <div className="con-box">
          <ul className="mod-list-box">
            {bottomsheet_list.map((v, i) => (
              <li key={i}>
                <button
                  type="button"
                  onClick={() => {
                    handleListClick(i);
                  }}
                >
                  {v.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BoardBottomModal;
