import Header from "components/ui/common/Header";
import React from "react";

const CommunityView = ({ boardMainInfo, setIsMyBoard, handleMainListClick, setTitle, setBoardType }) => {
  const setBoardTypeByCategory = (boardNm) => {
    switch (boardNm) {
      case "삽니다":
        setBoardType("삽니다");
        break;
      case "팝니다":
        setBoardType("팝니다");
        break;
      case "지식산업센터":
        setBoardType("지식산업센터");
        break;
      case "오피스텔빌딩":
        setBoardType("오피스텔빌딩");
        break;
      case "구인":
      case "구직":
      case "자유게시판":
        setBoardType("");
        break;
      default:
        setBoardType("기타");
    }
  };
  console.log(boardMainInfo);
  return (
    <div className="wrapper community-main">
      <Header Title="커뮤니티" handleGoBack={"main"} />
      <div className="content pb40">
        <ul className="category-box mb30">
          <li className="category1">
            <button
              type="button"
              onClick={() => {
                setTitle("팝니다");
              }}
            >
              <i className="ico-community-category"></i>팝니다
            </button>
          </li>
          <li className="category2">
            <button
              type="button"
              onClick={() => {
                setTitle("삽니다");
              }}
            >
              <i className="ico-community-category"></i>삽니다
            </button>
          </li>
          <li className="category3">
            <button
              type="button"
              onClick={() => {
                setTitle("지식산업센터");
              }}
            >
              <i className="ico-community-category"></i>지식산업센터
            </button>
          </li>
          <li className="category4">
            <button
              type="button"
              onClick={() => {
                setTitle("오피스텔빌딩");
              }}
            >
              <i className="ico-community-category"></i>오피스텔, 빌딩
            </button>
          </li>
          <li className="category5">
            <button
              type="button"
              onClick={() => {
                setTitle("구인구직");
              }}
            >
              <i className="ico-community-category"></i>구인, 구직
            </button>
          </li>
          <li
            className="category6"
            onClick={() => {
              setTitle("자유게시판");
            }}
          >
            <button type="button">
              <i className="ico-community-category"></i>자유게시판
            </button>
          </li>
        </ul>
        <div
          className="banner mb40"
          onClick={() => {
            setIsMyBoard(true);
          }}
        >
          <p>
            내가 등록한 글<br />
            보러가기
          </p>
          <i className="ico-right-arr"></i>
        </div>
        <div className="ranking-list-wrap mb40">
          <h2>이번주 조회수 탑!</h2>
          <div className="ranking-list">
            {boardMainInfo.viewsTop && boardMainInfo.viewsTop.length > 0 ? (
              boardMainInfo.viewsTop.map((v, i) => (
                <div
                  key={i}
                  className="list round-box type4"
                  onClick={() => {
                    handleMainListClick(i, "viewsTop");
                    setBoardTypeByCategory(v.boardNm);
                  }}
                >
                  <div className="number-box"></div>
                  <div className="desc-box">
                    <p className="txt1">{v.boardTitle}</p>
                    <p className="txt2">
                      <span className="category">{v.boardNm}</span>
                      {/* <span className="num">조회수 {v.viewCount}</span> */}
                    </p>
                  </div>
                  <div className="view-box">
                    <i className="ico-view"></i>
                    <span className="num">{v.viewCount}</span>
                  </div>
                </div>
              ))
            ) : (
              <p>등록된 내용이 없습니다</p>
            )}
          </div>
        </div>
        <div className="today-list-wrap">
          <h2>투데이</h2>
          <div className="today-list">
            {boardMainInfo.newBoard && boardMainInfo.newBoard.length > 0 ? (
              boardMainInfo.newBoard.map((v, i) => (
                <div
                  key={i}
                  className="list-box round-box type4"
                  onClick={() => {
                    handleMainListClick(i, "newBoard");
                    setBoardTypeByCategory(v.boardNm);
                  }}
                >
                  <div className="desc-box">
                    <div className="txt-box">
                      <em
                        className={`sort${
                          v.boardNm === "팝니다"
                            ? " sell"
                            : v.boardNm === "삽니다"
                            ? " buy"
                            : v.boardNm === "지식산업센터"
                            ? " knowledge"
                            : v.boardNm === "오피스텔빌딩"
                            ? " building"
                            : v.boardNm === "자유게시판"
                            ? " free"
                            : " job"
                        }`}
                      >
                        {v.boardNm}
                      </em>
                      <p className="txt">{v.boardTitle}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>등록된 내용이 없습니다</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityView;
