import { useLocation, useNavigate } from "react-router-dom";

import React from "react";

const Header = ({ Title, handleGoBack, structure }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const pageChange = (page) => {
    if (window.ReactNativeWebView) {
      const message = { navigate: page };
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
    navigate("/" + page);
  };

  function checkType(data) {
    if (typeof data === "function") {
      return "function";
    } else {
      return "string";
    }
  }

  return (
    <React.Fragment>
      {structure === "close" ? (
        <header className="right">
          <h1>{Title}</h1>
          <div className="h-btn-box">
            <button type="button" className="close-btn" onClick={handleGoBack} />
          </div>
        </header>
      ) : (
        <header>
          <div className="h-btn-box">
            {structure !== "menuHome" ? (
              <button
                type="button"
                className="back-btn"
                onClick={() => {
                  const dataType = checkType(handleGoBack);

                  if (dataType === "function") {
                    handleGoBack();
                  } else if (dataType === "string") {
                    pageChange(handleGoBack);
                  }
                }}
              />
            ) : null}
          </div>
          <h1>{Title}</h1>
          <div className="h-btn-box">
            <button
              type="button"
              className="home-btn"
              onClick={() => {
                pageChange("main");
              }}
            />
            <button
              type="button"
              className="menu-btn"
              onClick={() => {
                pageChange("menu?page=" + location.pathname);
              }}
            />
          </div>
        </header>
      )}
    </React.Fragment>
  );
};

export default Header;
