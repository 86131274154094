import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AlertModal from "components/ui/modal/AlertModal";
import FindNaivgateModal from "components/ui/modal/FindNaivgateModal";
import { isPasswordValid } from "views/pages/join/PwJoin";

const ResetPw = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userNm, userMobileNo, id, value } = location.state || {};

  const [userPw, setUserPw] = useState("");
  const [confirmPw, setConfirmPw] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  // 비밀번호 재설정 api
  const handleResetPw = async () => {
    if (userPw === "" || confirmPw === "") {
      setIsAlert(true);
      setModalMsg("비밀번호를 확인하세요.");
    } else if (confirmPw.length < 8) {
      setIsAlert(true);
      setModalMsg("새로운 비밀번호를\n입력해 주세요.");
    } else if (!isPasswordValid(confirmPw)) {
      setIsAlert(true);
      setModalMsg("비밀번호를 조합 조건에 따라\n다시 입력해 주세요.");
    } else if (userPw.length < 8) {
      setIsAlert(true);
      setModalMsg("비밀번호 확인을\n입력해 주세요.");
    } else if (userPw !== confirmPw) {
      setIsAlert(true);
      setModalMsg("비밀번호가 일치하지 않습니다.");
    } else {
      try {
        const response = await request(PATH.아이디비밀번호찾기, {
          userNm: userNm,
          userMobileNo: userMobileNo,
          userId: id,
          value: value,
          userPw: userPw,
        });
        if (response.data.code && response.data.code !== "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
        } else if (response.data.code === "0000") {
          setIsNavigate(true);
          setModalMsg("비밀번호 변경이 완료되었습니다. 재로그인 해 주세요.");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePwInput = (e, setInput) => {
    let input = e.target.value;

    // 한글 및 공백 제거, 나머지는 다 허용
    input = input.replace(/[ㄱ-ㅎ가-힣ㆍ\s]/g, "");

    const maxLength = 20;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setInput(input);
  };

  const handleConfirmPwReset = () => {
    setConfirmPw("");
    setUserPw("");
  };

  const handleUserPwReset = () => {
    setUserPw("");
  };

  return (
    <React.Fragment>
      {isAlert === true ? <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} /> : null}
      {isNavigate === true ? (
        <FindNaivgateModal modalMsg={modalMsg} closeModal={setIsNavigate} userPw={userPw} />
      ) : null}

      <div className="wrapper reset-pw">
        <header className="right">
          <h1>비밀번호 재설정</h1>
          <div className="h-btn-box">
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                navigate("/login");
              }}
            ></button>
          </div>
        </header>

        <div className="content pt60">
          <div className="title-box mb60">
            <h2>
              사용하실
              <br />
              비밀번호를 다시 설정해 주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className="tf-box">
                  <label htmlFor="new-pw" className="sr-only">
                    새로운 비밀번호
                  </label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="password"
                        id="new-pw"
                        className="tf"
                        placeholder="영어와 숫자를 조합한 8~20자"
                        value={confirmPw}
                        onChange={(e) => handlePwInput(e, setConfirmPw)}
                        maxLength={20}
                      />
                      {confirmPw ? (
                        <button type="button" className="remove-btn" onClick={handleConfirmPwReset} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="validity-box-wrap">
                  <ul className={`validity-box mt12${/[A-Za-z]/.test(confirmPw) ? " check" : " "}`}>
                    {/[A-Za-z]/.test(confirmPw) ? (
                      <li>
                        영문<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>영문</li>
                    )}
                  </ul>
                  <ul className={`validity-box mt12${/\d/.test(confirmPw) ? " check" : " "}`}>
                    {/\d/.test(confirmPw) ? (
                      <li>
                        숫자<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>숫자</li>
                    )}
                  </ul>
                  <ul
                    className={`validity-box mt12${confirmPw.length >= 8 && confirmPw.length <= 20 ? " check" : " "}`}
                  >
                    {confirmPw.length >= 8 && confirmPw.length <= 20 ? (
                      <li>
                        8~20자 이내<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>8~20자 이내</li>
                    )}
                  </ul>
                </div>
              </div>

              {confirmPw && confirmPw.length >= 8 ? (
                <div className="tf-box-wrap">
                  <div className="tf-box">
                    <label htmlFor="pw-check" className="sr-only">
                      비밀번호 확인
                    </label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="password"
                          id="pw-check"
                          className="tf"
                          placeholder="비밀번호 입력"
                          value={userPw}
                          onChange={(e) => handlePwInput(e, setUserPw)}
                          maxLength={20}
                        />
                        {userPw ? <button type="button" className="remove-btn" onClick={handleUserPwReset} /> : null}
                      </div>
                    </div>
                  </div>
                  <ul className={`validity-box mt12${userPw && userPw === confirmPw ? " check" : " "}`}>
                    {userPw && userPw === confirmPw ? (
                      <li>
                        비밀번호 일치<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>비밀번호 일치</li>
                    )}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${userPw ? " main-color" : " disabled"}`}
              onClick={() => {
                if (!confirmPw || !userPw) {
                  setIsAlert(true);
                  setModalMsg("입력 내용을 다시 확인해 주세요.");
                } else {
                  handleResetPw();
                }
              }}
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPw;
