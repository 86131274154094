import { JoinContext, JoinInitialValue, joinLocalRemove } from "components/shared/JoinContext";
import { PATH, request, setToken } from "components/shared/Api";
import React, { useContext, useEffect } from "react";

import building from "assets/images/bg/bg_join_complete_building.png";
import { useNavigate } from "react-router-dom";

const JoinComplete = () => {
  const navigate = useNavigate();
  const { joinProps, setJoinProps } = useContext(JoinContext);
  const handleGoNext = () => {
    setJoinProps({ ...JoinInitialValue });
    joinLocalRemove();
    navigate("/");
  };

  //로그인 api
  const handleLoginCheck = async () => {
    try {
      const response = await request(PATH.로그인, {
        userId: joinProps.userId,
        userPw: joinProps.userPw,
        type: "service",
      });
      if (response.data.code === "0000") {
        setToken(response.data.accessToken);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleLoginCheck();
  }, []);

  return (
    <div className="wrapper join-complete">
      <header>
        <h1>회원가입</h1>
      </header>

      <div className="content pt60">
        <div className="title-box">
          <i className="ico-complete mb10"></i>
          <h2>
            <span className="main-color">{joinProps.userNm}님,</span> 안녕하세요
            <br />
            빌딩카스 회원이 되신 것을
            <br />
            환영합니다!
          </h2>
        </div>

        <img src={building} alt="빌딩" className="img-building" />
      </div>

      <div className="fixed-button-box">
        <div className="button-box">
          <button type="button" className="btn1 main-color" onClick={handleGoNext}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinComplete;
