import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

const AutoPayCancelModal = ({ closeModal, cardInfo, setIsAutoPayDel, setCardInfoChange, set_is_loading }) => {
  const [modalMsg, setModalMsg] = useState("");

  // 카드자동결제수정 api 호출
  const handleUpdateCardAuth = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.카드자동결제수정, { cardHldr: cardInfo.cardHldr, useYN: "N" });
      if (response.data.code === "0000") {
        await setCardInfoChange("autoPayDel");
        setIsAutoPayDel(false);
      } else {
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">
          {modalMsg === "" ? (
            <p>
              등록된 자동결제를
              <br />
              해지합니다.
            </p>
          ) : (
            modalMsg
          )}
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 sub-color"
            onClick={() => {
              closeModal(false);
            }}
          >
            취소
          </button>
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              handleUpdateCardAuth();
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default AutoPayCancelModal;
