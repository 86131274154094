import React from "react";
import { useNavigate } from "react-router-dom";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="wrapper terms">
        <header className="right">
          <h1>메인으로 가기</h1>
          <div className="h-btn-box">
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                navigate("/main");
              }}
            />
          </div>
        </header>

        <div className="content pb0">
          <div className="privacy-box">
            <p>
              <strong>빌딩CAS 개인정보 처리방침</strong>
              <br />
              <br />
              빌딩CAS(이하”회사”)는 이용자의 개인정보를 소중히 생각하며, 수집한 개인정보를 가장 적극적이고 효과적으로
              처리하기 위하여 항상 최선을 다해 노력하고 있습니다. 개인정보 처리방침은 관련 법률 및 지침의 변경과 내부
              운영방침에 따라 변경 될 수 있습니다.
              <br />
              <br />
              회사의 개인정보처리방침은 다음과 같은 내용으로 구성되어 있습니다.
              <br />
              1. 수집하는 개인정보의 항목 및 수집방법
              <br />
              2. 개인정보의 수집 및 이용목적
              <br />
              3. 개인정보의 공유 및 제공
              <br />
              4. 개인정보의 위탁
              <br />
              5. 개인정보의 보유 및 이용기간
              <br />
              6. 개인정보의 파기절차 및 방법
              <br />
              7. 이용자 및 법정대리인의 권리와 그 행사방법
              <br />
              8. 개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항
              <br />
              9. 개인정보의 기술적/관리적 보호 대책
              <br />
              10. 개인정보관리책임자 및 담당자
              <br />
              <br />
              1. 수집하는 개인정보의 항목 및 수집방법
              <br />
              가. 수집하는 개인정보의 항목
              <br />
              1) 회사는 회원가입 및 각종 서비스 제공을 위해 아래와 같은 항목의 개인정보를 수집하고 있습니다.
              <br />
              - 수집항목 : 본인명의 휴대폰 인증/아이핀 인증, 성명, 실명인증값, 생년월일, 아이디, 주소, 휴대폰번호,
              이메일
              <br />
              - 아이핀 회원은 성명, 아이핀 번호, 생년월일, 성별, 아이디, 비밀번호, 이메일, 휴대폰번호
              <br />
              2) 서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다. 아이피, 방문 일시, 서비스 이용
              기록, 불량 이용 기록
              <br />
              3) 관리비 수납/결제 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
              <br />
              - 신용카드 결제시 : 거래내역 승인 리스트
              <br />
              나. 개인정보 수집방법
              <br />
              1) 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
              <br />
              - 빌딩CAS 어드민(https://casadmdev.shcommunication.co.kr), 전화 등
              <br />
              <br />
              2. 개인정보의 수집 및 이용목적
              <br />
              가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 관리비 비용 결제
              <br />
              1) 콘덴츠 제공, 관리비 내역 조회, 관리비 수납 결제
              <br />
              나. 회원관리
              <br />
              회원제 서비스 이용에 따른 본인여부 확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지,
              연령확인, 가입의사확인, 가입 및 가입횟수 제한, 법적대리인 본인확인, 분쟁 조정을 위한 기록보존, 민원처리,
              고지사항 전달
              <br />
              다. 수집한 개인정보의 이용목적 구분
              <br />
              1) 관리비 정보 : 건물 입주한 세입자 및 건물 소유자의 매월 관리비 청구내역 확인
              <br />
              2) 관리비 수납 : 매월 발생되는 관리비 결제 처리
              <br />
              <br />
              3. 개인정보의 공유 및 제공
              <br />
              회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위내에서 사용하며, 이용자의
              사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다.
              다만, 아래의 경우에는 예외로 합니다.
              <br />
              법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
              <br />
              <br />
              4. 개인정보의 위탁
              <br />
              서비스 제공을 위해 아래와 같은 업무를 위탁하고 있습니다.
              <br />
              필요한 업무 중 일부를 외부 업체로 하여금 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가
              관계 법령을 준수하도록 관리·감독하고 있습니다.
              <br />
              가. 네이버 클라우드 - 본인확인(별도로 저장하지 않음)
              <br />
              나. 본인확인 - 결제수단 별 거래내역-신용카드(회원탈퇴 시 혹은 위탁 계약 종료 시까지)
              <br />
              <br />
              5. 개인정보의 보유 및 이용기간
              <br />
              회사는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에
              대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
              <br />
              가. 회사 내부 방침에 의한 정보보유 사유
              <br />
              1) 부정이용기록
              <br />
              2) 보존 이유 : 부정 이용 방지
              <br />
              3) 보존 기간 : 해당 제한기간(3년)
              <br />
              나. 관련법령에 의한 정보보유 사유
              <br />
              상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우
              회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
              목적으로만 이용하며 보존기간은 아래와 같습니다.
              <br />
              1) 계약 또는 청약철회 등에 관한 기록
              <br />
              보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              보존 기간 : 5년
              <br />
              2) 대금결제 및 재화 등의 공급에 관한 기록
              <br />
              보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              보존 기간 : 5년
              <br />
              3) 소비자의 불만 또는 분쟁처리에 관한 기록
              <br />
              보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
              <br />
              보존 기간 : 3년
              <br />
              4) 방문에 관한 기록
              <br />
              보존 이유 : 통신비밀보호법
              <br />
              보존 기간 : 3개월
              <br />
              <br />
              6. 개인정보의 파기절차 및 방법
              <br />
              이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
              <br />
              가. 파기절차
              <br />
              이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)
              내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후
              파기됩니다.
              <br />
              별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
              <br />
              나. 파기방법
              <br />
              전자적 파일 형태로 저장된 개인정보는 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 기록물, 인쇄물,
              서면 등의 경우 분쇄하여 파기합니다.
              <br />
              <br />
              7. 이용자 및 법정대리인의 권리와 그 행사방법
              <br />
              가. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며
              가입해지를 요청할 수도 있습니다.
              <br />
              나. 이용자의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지
              (동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
              개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
              <br />
              다. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용
              또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게
              지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
              <br />
              라. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “5. 개인정보의 보유 및
              이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
              <br />
              <br />
              8. 개인정보 자동수집 장치의 설치/운영 및 거부에 관한 사항
              <br />
              쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의
              컴퓨터에 저장됩니다. 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.
              <br />
              가. 쿠키 등 사용 목적
              <br />
              이용자가 선호하는 설정 등을 저장하여 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에
              활용합니다.
              <br />
              나. 쿠키 설정 거부 방법
              <br />
              이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써
              모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.
              다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
              <br />
              <br />
              9. 개인정보의 기술적/관리적 보호 대책
              <br />
              이용자의 개인정보를 가장 소중한 가치로 여기고 개인정보를 처리함에 있어서 다음과 같은 노력을 다하고
              있습니다.
              <br />
              가. 비밀번호 암호화
              <br />
              이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중요정보는 암호화하여 보관하고
              있습니다.
              <br />
              나. 해킹 등에 대비한 대책
              <br />
              해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터
              접근이 통제된 구역에 시스템을 설치하고 있습니다. 해커 등의 침입을 탐지하고 차단할 수 있는 시스템을
              설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지
              않도록 노력하고 있습니다.
              <br />
              다. 처리직원의 최소화 및 교육
              <br />
              개인정보를 처리하는 직원을 최소한으로 한정시키고 담당자들을 대상으로 개인정보보호 의무와 보안에 대한
              정기적인 교육과 캠페인을 실시하고 있습니다.
              <br />
              <br />
              10. 개인정보관리책임자 및 담당자
              <br />
              이용자의 정보주체가 열람 등 요구를 고객센터나 전화, 이메일 또는 서면으로 연락하시면 지체 없이
              조치하겠습니다. 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은
              개인정보 보호책임자 및 담당부서로 연락해 주시기 바랍니다. 신속하고 충분한 답변을 드릴 수 있도록 최선을
              다하겠습니다.
              <br />
              개인정보 관리 책임자
              <br />
              – 성명 : 김정환 부장
              <br />
              - 전화 : 02-6949-3662
              <br />
              - 팩스 : 070-8650-8550
              <br />
              - 이메일: poot21@sh-data.co.kr
              <br />
              기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
              <br />
              개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
              <br />
              대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
              <br />
              경찰청 사이버안전국 (www.ctrc.go.kr / 국번없이 182)
            </p>

            <p className="mt70">
              Privacy Policy
              <br />
              <br />
              SHDATA built the 빌딩CAS app as a Free app. This SERVICE is provided by SHDATA at no cost and is intended
              for use as is.
              <br />
              <br />
              This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
              Personal Information if anyone decided to use our Service.
              <br />
              <br />
              If you choose to use our Service, then you agree to the collection and use of information in relation to
              this policy. The Personal Information that we collect is used for providing and improving the Service. We
              will not use or share your information with anyone except as described in this Privacy Policy.
              <br />
              <br />
              The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are
              accessible at 빌딩CAS unless otherwise defined in this Privacy Policy.
              <br />
              <br />
              Information Collection and Use
              <br />
              <br />
              For a better experience, while using our Service, we may require you to provide us with certain personally
              identifiable information. The information that we request will be retained by us and used as described in
              this privacy policy.
              <br />
              <br />
              The app does use third-party services that may collect information used to identify you.
              <br />
              <br />
              Link to the privacy policy of third-party service providers used by the app
              <br />
              <br />
              Google Play Services
              <br />
              Log Data
              <br />
              We want to inform you that whenever you use our Service, in a case of an error in the app we collect data
              and information (through third-party products) on your phone called Log Data. This Log Data may include
              information such as your device Internet Protocol (“IP”) address, device name, operating system version,
              the configuration of the app when utilizing our Service, the time and date of your use of the Service, and
              other statistics.
              <br />
              <br />
              Cookies
              <br />
              <br />
              Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
              These are sent to your browser from the websites that you visit and are stored on your device's internal
              memory.
              <br />
              <br />
              This Service does not use these “cookies” explicitly. However, the app may use third-party code and
              libraries that use “cookies” to collect information and improve their services. You have the option to
              either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
              to refuse our cookies, you may not be able to use some portions of this Service.
              <br />
              <br />
              Service Providers
              <br />
              <br />
              We may employ third-party companies and individuals due to the following reasons:
              <br />
              <br />
              To facilitate our Service;
              <br />
              To provide the Service on our behalf;
              <br />
              To perform Service-related services; or
              <br />
              To assist us in analyzing how our Service is used.
              <br />
              We want to inform users of this Service that these third parties have access to their Personal
              Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other purpose.
              <br />
              <br />
              Security
              <br />
              <br />
              We value your trust in providing us your Personal Information, thus we are striving to use commercially
              acceptable means of protecting it. But remember that no method of transmission over the internet, or
              method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
              <br />
              <br />
              Links to Other Sites
              <br />
              <br />
              This Service may contain links to other sites. If you click on a third-party link, you will be directed to
              that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to
              review the Privacy Policy of these websites. We have no control over and assume no responsibility for the
              content, privacy policies, or practices of any third-party sites or services.
              <br />
              <br />
              Children’s Privacy
              <br />
              <br />
              These Services do not address anyone under the age of 13. We do not knowingly collect personally
              identifiable information from children under 13 years of age. In the case we discover that a child under
              13 has provided us with personal information, we immediately delete this from our servers. If you are a
              parent or guardian and you are aware that your child has provided us with personal information, please
              contact us so that we will be able to do the necessary actions.
              <br />
              <br />
              Changes to This Privacy Policy
              <br />
              <br />
              We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
              for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
              <br />
              This policy is effective as of 2024-02-05
              <br />
              <br />
              Contact Us
              <br />
              <br />
              If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
              shdata001@sh-data.co.kr.
              <br />
              <br />
              This privacy policy page was created at privacypolicytemplate.net and modified/generated by App Privacy
              Policy Generator
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Privacy;
