import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";

const Withdrawal = ({ setWithdrawal, setWithdrawalPw }) => {
  const [withdrawalChecked, setWithdrawalChecked] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [terms_content, set_terms_content] = useState("");

  //약관목록조회 api
  const handleAccountTerms = async () => {
    try {
      const response = await request(PATH.약관목록조회, {
        code: "personal_info_terms",
      });
      if (response.data.code === "0000") {
        set_terms_content(response.data.terms_list.terms_content);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    setWithdrawal(false);
  };

  useEffect(() => {
    handleAccountTerms();
  }, []);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      <div className="wrapper withdrawal">
        <Header Title="회원탈퇴" handleGoBack={handleGoBack} />
        <div className="content pt60">
          <div className="title-box mb20">
            <h2>
              회원탈퇴 약관을
              <br />
              동의해 주세요.
            </h2>
          </div>

          <div className="terms-box round-box">
            <div className="scroll-box">
              <p className="desc-terms">
                <p
                  style={{
                    whiteSpace: "pre-wrap",
                    textOverflow: "clip",
                    lineHeight: "1.6",
                  }}
                >
                  {terms_content}
                </p>
              </p>
            </div>
          </div>

          <div className="all-agree mt15">
            <label htmlFor="all-agree2" className={`all-agree${withdrawalChecked ? " check" : ""}`}>
              <input
                type="checkbox"
                id="all-agree2"
                checked={withdrawalChecked}
                onChange={() => setWithdrawalChecked(!withdrawalChecked)}
              />
              <span>위 이용약관에 동의합니다.</span>
            </label>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box type2">
            <button type="button" className="btn1 narrow sub-color" onClick={handleGoBack}>
              취소
            </button>
            <button
              type="button"
              className={`btn1 wide${withdrawalChecked ? " main-color" : " disabled"}`}
              onClick={() => {
                if (withdrawalChecked) {
                  setWithdrawal(false);
                  setWithdrawalPw(true);
                } else {
                  setIsAlert(true);
                  setModalMsg("회원탈퇴 약관을 동의해주세요.");
                }
              }}
            >
              회원탈퇴
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Withdrawal;
