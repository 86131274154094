import { PATH, request } from "components/shared/Api";
import React, { useContext, useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import { JoinContext } from "components/shared/JoinContext";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();
  const { setJoinProps } = useContext(JoinContext);
  const [allAgreeChecked, setAllAgreeChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [personalInfoChecked, setPersonalInfoChecked] = useState(false);
  const [thirdPartyChecked, setThirdPartyChecked] = useState(false);
  const [receipAllChecked, setReceipAllChecked] = useState(false);
  const [receipAppChecked, setReceipAppChecked] = useState(false);
  const [receipSmsChecked, setReceipSmsChecked] = useState(false);
  const [receipEmailChecked, setReceipEmailChecked] = useState(false);
  const [receipApp, setReceipApp] = useState("N");
  const [receipSms, setReceipSms] = useState("N");
  const [receipEmail, setReceipEmail] = useState("N");

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [show_privacy, set_show_privacy] = useState(false);
  const [terms_title, set_terms_title] = useState("");
  const [terms_content, set_terms_content] = useState("");

  //약관목록조회 api
  const handleAccountTerms = async (code) => {
    try {
      const response = await request(PATH.약관목록조회, {
        code: code,
      });
      if (response.data.code === "0000") {
        set_show_privacy(true);
        set_terms_title(response.data.terms_list.terms_title);
        set_terms_content(response.data.terms_list.terms_content);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //MarketingContext
  const handleGoNext = () => {
    setJoinProps((prevData) => ({
      ...prevData,
      receipApp: receipApp,
      receipSms: receipSms,
      receipEmail: receipEmail,
    }));
    navigate("/phoneVerification");
  };

  const handleCheckboxChange = async (type, isChecked) => {
    switch (type) {
      case "all":
        setAllAgreeChecked(isChecked);
        setTermsChecked(isChecked);
        setPersonalInfoChecked(isChecked);
        setThirdPartyChecked(isChecked);
        setReceipAllChecked(isChecked);
        setReceipAppChecked(isChecked);
        setReceipSmsChecked(isChecked);
        setReceipEmailChecked(isChecked);
        break;

      case "terms":
        setTermsChecked(isChecked);
        setAllAgreeChecked(isChecked && personalInfoChecked && thirdPartyChecked && receipAllChecked);
        break;

      case "personalInfo":
        setPersonalInfoChecked(isChecked);
        setAllAgreeChecked(termsChecked && isChecked && thirdPartyChecked && receipAllChecked);
        break;

      case "thirdParty":
        setThirdPartyChecked(isChecked);
        setAllAgreeChecked(termsChecked && personalInfoChecked && isChecked && receipAllChecked);
        break;

      case "receipAll":
        setReceipAllChecked(isChecked);
        setReceipAppChecked(isChecked);
        setReceipSmsChecked(isChecked);
        setReceipEmailChecked(isChecked);
        setAllAgreeChecked(isChecked && termsChecked && personalInfoChecked && thirdPartyChecked);
        break;

      case "receipApp":
        setReceipAppChecked(isChecked);
        setReceipAllChecked(isChecked && receipSmsChecked && receipEmailChecked);
        setAllAgreeChecked(
          isChecked &&
            receipSmsChecked &&
            receipEmailChecked &&
            termsChecked &&
            personalInfoChecked &&
            thirdPartyChecked
        );
        break;

      case "receipSms":
        setReceipSmsChecked(isChecked);
        setReceipAllChecked(isChecked && receipAppChecked && receipEmailChecked);
        setAllAgreeChecked(
          isChecked &&
            receipAppChecked &&
            receipEmailChecked &&
            termsChecked &&
            personalInfoChecked &&
            thirdPartyChecked
        );
        break;

      case "receipEmail":
        setReceipEmailChecked(isChecked);
        setReceipAllChecked(isChecked && receipAppChecked && receipSmsChecked);
        setAllAgreeChecked(
          isChecked && receipAppChecked && receipSmsChecked && termsChecked && personalInfoChecked && thirdPartyChecked
        );
        break;

      default:
        break;
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const loadStateFromLocalStorage = (key, defaultValue) => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    };

    setAllAgreeChecked(loadStateFromLocalStorage("allAgreeChecked", false));
    setTermsChecked(loadStateFromLocalStorage("termsChecked", false));
    setPersonalInfoChecked(loadStateFromLocalStorage("personalInfoChecked", false));
    setThirdPartyChecked(loadStateFromLocalStorage("thirdPartyChecked", false));
    setReceipAllChecked(loadStateFromLocalStorage("receipAllChecked", false));
    setReceipAppChecked(loadStateFromLocalStorage("receipAppChecked", false));
    setReceipSmsChecked(loadStateFromLocalStorage("receipSmsChecked", false));
    setReceipEmailChecked(loadStateFromLocalStorage("receipEmailChecked", false));
  }, []);

  useEffect(() => {
    const saveStateToLocalStorage = (key, value) => {
      localStorage.setItem(key, JSON.stringify(value));
    };

    saveStateToLocalStorage("allAgreeChecked", allAgreeChecked);
    saveStateToLocalStorage("termsChecked", termsChecked);
    saveStateToLocalStorage("personalInfoChecked", personalInfoChecked);
    saveStateToLocalStorage("thirdPartyChecked", thirdPartyChecked);
    saveStateToLocalStorage("receipAllChecked", receipAllChecked);
    saveStateToLocalStorage("receipAppChecked", receipAppChecked);
    saveStateToLocalStorage("receipSmsChecked", receipSmsChecked);
    saveStateToLocalStorage("receipEmailChecked", receipEmailChecked);
  }, [
    allAgreeChecked,
    termsChecked,
    personalInfoChecked,
    thirdPartyChecked,
    receipAllChecked,
    receipAppChecked,
    receipSmsChecked,
    receipEmailChecked,
  ]);

  useEffect(() => {
    setReceipApp(receipAppChecked ? "Y" : "N");
    setReceipSms(receipSmsChecked ? "Y" : "N");
    setReceipEmail(receipEmailChecked ? "Y" : "N");
  }, [receipAppChecked, receipSmsChecked, receipEmailChecked]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {show_privacy ? (
        <div className="alert-modal terms">
          <div className="box">
            <div className="tit-box">
              <h2>{terms_title}</h2>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  set_show_privacy(false);
                }}
              ></button>
            </div>
            <div className="desc-box" style={{ whiteSpace: "pre-wrap", textOverflow: "clip" }}>
              <p>{terms_content}</p>
            </div>
            <div className="button-box">
              <button
                type="button"
                className="btn1 main-color"
                onClick={() => {
                  set_show_privacy(false);
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className="wrapper terms">
        <header>
          <div className="h-btn-box">
            <button type="button" className="back-btn" onClick={handleGoBack} />
          </div>
          <h1>회원가입</h1>
        </header>

        <div className="content">
          <ul className="indicator mt16 mb12">
            <li className="flex-center on">1</li>
            <li className="flex-center">2</li>
            <li className="flex-center">3</li>
            <li className="flex-center">4</li>
            <li className="flex-center">5</li>
            <li className="flex-center">6</li>
          </ul>

          <div className="title-box mb40">
            <h2>
              서비스 이용약관에
              <br />
              동의해 주세요.
            </h2>
          </div>

          <div className="agree-box">
            <label
              htmlFor="all-agree1"
              className={`all-agree mb30${
                allAgreeChecked &&
                termsChecked &&
                personalInfoChecked &&
                receipAllChecked &&
                receipAppChecked &&
                receipSmsChecked &&
                receipEmailChecked
                  ? " check"
                  : ""
              }`}
            >
              <input
                type="checkbox"
                id="all-agree1"
                checked={allAgreeChecked}
                onChange={() => {
                  const isChecked = !allAgreeChecked;
                  handleCheckboxChange("all", isChecked);
                }}
              />
              <span>이용약관 전체동의</span>
            </label>

            <ul className="main-agree">
              <li className="type2">
                <button
                  type="button"
                  onClick={() => {
                    handleAccountTerms("use_terms");
                  }}
                >
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree1">
                  <input
                    type="checkbox"
                    id="agree1"
                    checked={termsChecked}
                    onChange={() => {
                      const isChecked = !termsChecked;
                      setTermsChecked(isChecked);
                      handleCheckboxChange("terms", isChecked);
                    }}
                  />
                  <span>
                    이용약관<span className="main-color"> (필수)</span>
                  </span>
                </label>
              </li>
              <li className="type2">
                <button
                  type="button"
                  onClick={() => {
                    handleAccountTerms("personal_info_terms");
                  }}
                >
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree2">
                  <input
                    type="checkbox"
                    id="agree2"
                    checked={personalInfoChecked}
                    onChange={() => {
                      const isChecked = !personalInfoChecked;
                      setPersonalInfoChecked(isChecked);
                      handleCheckboxChange("personalInfo", isChecked);
                    }}
                  />
                  <span>
                    개인정보 처리방침<span className="main-color"> (필수)</span>
                  </span>
                </label>
              </li>
              <li className="type2">
                <button
                  type="button"
                  onClick={() => {
                    handleAccountTerms("thirdParty_terms");
                  }}
                >
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree3">
                  <input
                    type="checkbox"
                    id="agree3"
                    checked={thirdPartyChecked}
                    onChange={() => {
                      const isChecked = !thirdPartyChecked;
                      setThirdPartyChecked(isChecked);
                      handleCheckboxChange("thirdParty", isChecked);
                    }}
                  />
                  <span>
                    개인정보 제3자 제공
                    <span className="main-color"> (필수)</span>
                  </span>
                </label>
              </li>
              <li>
                <button type="button" className="type2">
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree4" className="type2">
                  <input
                    type="checkbox"
                    id="agree4"
                    checked={receipAllChecked}
                    onChange={() => {
                      const isChecked = !receipAllChecked;
                      setReceipAllChecked(isChecked);
                      handleCheckboxChange("receipAll", isChecked);
                    }}
                  />
                  <span>
                    마케팅 수신 동의<span className="main-color"> (선택)</span>
                  </span>
                </label>

                <ol className="sub-agree">
                  <li>
                    <label htmlFor="agree4-1">
                      <input
                        type="checkbox"
                        id="agree4-1"
                        checked={receipAppChecked}
                        onChange={() => {
                          const isChecked = !receipAppChecked;
                          handleCheckboxChange("receipApp", isChecked);
                        }}
                      />
                      <span>앱알림</span>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="agree4-2">
                      <input
                        type="checkbox"
                        id="agree4-2"
                        checked={receipSmsChecked}
                        onChange={() => {
                          const isChecked = !receipSmsChecked;
                          handleCheckboxChange("receipSms", isChecked);
                        }}
                      />
                      <span>문자</span>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="agree4-3">
                      <input
                        type="checkbox"
                        id="agree4-3"
                        checked={receipEmailChecked}
                        onChange={() => {
                          const isChecked = !receipEmailChecked;
                          handleCheckboxChange("receipEmail", isChecked);
                        }}
                      />
                      <span>이메일</span>
                    </label>
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${
                termsChecked && personalInfoChecked && thirdPartyChecked ? " main-color" : " disabled"
              }`}
              onClick={() => {
                if (!termsChecked || !personalInfoChecked || !thirdPartyChecked) {
                  setIsAlert(true);
                  setModalMsg("서비스 이용약관에 동의해 주세요.");
                } else {
                  handleGoNext();
                }
              }}
            >
              동의하고 계속하기
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Terms;
