import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

import { removeToken } from "components/shared/Api";
import { useNavigate } from "react-router-dom";

const SettingNavigateModal = ({ closeModal, isLogOut, userPw, modalMsg }) => {
  const navigate = useNavigate();
  const [isAlert, setIsAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  //유저탈퇴 api
  const handleUpdateUserOut = async () => {
    try {
      const response = await request(PATH.유저탈퇴, { userPw: userPw });
      console.log(response.data);
      if (response.data.code && response.data.code !== "0000" && response.data.code !== "B008") {
        setIsAlert(true);
        setAlertMsg(response.data.message);
      } else if (response.data.code === "B008") {
        setIsAlert(true);
        setAlertMsg("비밀번호가 일치하지 않습니다.\n비밀번호를 확인해 주세요.");
      } else if (response.data.code === "0000") {
        navigateToLogin();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToLogin = async () => {
    await removeToken();
    await localStorage.removeItem("userInfo");
    if (window.ReactNativeWebView) {
      const message = { navigate: "logout" };
      await window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
    navigate("/login");
  };

  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">{isAlert === true ? <p>{alertMsg}</p> : <p>{modalMsg}</p>}</div>
        <div className="button-box">
          {isLogOut || isAlert ? null : (
            <button
              type="button"
              className="btn1 sub-color"
              onClick={() => {
                closeModal(false);
              }}
            >
              취소
            </button>
          )}
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              if (isLogOut) {
                closeModal(false);
                navigateToLogin();
              } else if (isAlert) {
                closeModal(false);
              } else {
                handleUpdateUserOut();
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingNavigateModal;
