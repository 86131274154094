import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import EditNavigateModal from "components/ui/modal/EditNavigateModal";
import Header from "components/ui/common/Header";
import { isPasswordValid } from "views/pages/join/PwJoin";
import { useNavigate } from "react-router-dom";

const UserPwEdit = (props) => {
  const navigate = useNavigate();
  const { setIsUserInfoEdit, setIsUserPwEdit, twoWayGoBack, setTwoWayGoBack } = props;
  const [originPw, setOriginPw] = useState("");
  const [userPw, setUserPw] = useState("");
  const [confirmPw, setConfirmPw] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  // 비밀번호 재설정 api
  const handleUserPwEdit = async () => {
    if (originPw.length < 1) {
      setIsAlert(true);
      setModalMsg("사용중인 비밀번호를\n입력해 주세요.");
    } else if (confirmPw.length < 8) {
      setIsAlert(true);
      setModalMsg("새로운 비밀번호를\n입력해 주세요.");
    } else if (!isPasswordValid(confirmPw)) {
      setIsAlert(true);
      setModalMsg("비밀번호를 조합 조건에 따라\n다시 입력해 주세요.");
    } else if (userPw.length < 8) {
      setIsAlert(true);
      setModalMsg("비밀번호 확인을\n입력해 주세요.");
    } else if (userPw !== confirmPw) {
      setIsAlert(true);
      setModalMsg("새로운 비밀번호가\n일치하지 않습니다.");
    } else {
      try {
        const response = await request(PATH.유저비밀번호변경, {
          userPw: originPw,
          changePw: userPw,
        });
        if (response.data.code && response.data.code !== "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
        } else if (response.data.code === "0000") {
          setIsNavigate(true);
          setModalMsg(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleGoBack = () => {
    if (!twoWayGoBack) {
      setIsUserPwEdit(false);
      setIsUserInfoEdit(false);
      navigate("/userInfo");
    } else {
      setIsUserPwEdit(false);
      setIsUserInfoEdit(true);
      setTwoWayGoBack(false);
    }
  };

  const handlePwInput = (e, setInput) => {
    let input = e.target.value;

    // 한글 및 공백 제거, 나머지는 다 허용
    input = input.replace(/[ㄱ-ㅎ가-힣ㆍ\s]/g, "");

    const maxLength = 20;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setInput(input);
  };

  const handleOriginPwReset = () => {
    setOriginPw("");
    handleConfirmPwReset();
    handleUserPwReset();
  };

  const handleConfirmPwReset = () => {
    setConfirmPw("");
  };

  const handleUserPwReset = () => {
    setUserPw("");
  };

  return (
    <React.Fragment>
      {isAlert === true ? <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} /> : null}
      {isNavigate === true ? (
        <EditNavigateModal
          modalMsg={modalMsg}
          closeModal={setIsNavigate}
          setIsUserPwEdit={setIsUserPwEdit}
          setIsUserInfoEdit={setIsUserInfoEdit}
          twoWayGoBack={twoWayGoBack}
        />
      ) : null}
      <div className="wrapper edit-pw">
        <Header Title="비밀번호 변경" handleGoBack={handleGoBack} />
        <div className="content pt60">
          <div className="title-box mb60">
            <h2>
              비밀번호를 변경하시려면
              <br />
              사용중인 비밀번호를 입력해주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box focus`}>
                  <label htmlFor="origin-pw">사용중인 비밀번호</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="password"
                        id="origin-pw"
                        className="tf"
                        placeholder="사용중인 비밀번호 입력"
                        value={originPw}
                        onChange={(e) => handlePwInput(e, setOriginPw)}
                        maxLength={20}
                      />
                      {originPw ? <button type="button" className="remove-btn" onClick={handleOriginPwReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>

              {originPw ? (
                <div className="tf-box-wrap">
                  <div className={`tf-box focus`}>
                    <label htmlFor="new-pw">새로운 비밀번호</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="password"
                          id="new-pw"
                          className="tf"
                          placeholder="영어와 숫자를 조합한 8~20자"
                          value={confirmPw}
                          onChange={(e) => handlePwInput(e, setConfirmPw)}
                          maxLength={20}
                        />
                        {confirmPw ? (
                          <button type="button" className="remove-btn" onClick={handleConfirmPwReset} />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="validity-box-wrap">
                    <ul className={`validity-box mt12${/[A-Za-z]/.test(confirmPw) ? " check" : " "}`}>
                      {/[A-Za-z]/.test(confirmPw) ? (
                        <li>
                          영문<i className="ico-check"></i>
                        </li>
                      ) : (
                        <li>영문</li>
                      )}
                    </ul>
                    <ul className={`validity-box mt12${/\d/.test(confirmPw) ? " check" : " "}`}>
                      {/\d/.test(confirmPw) ? (
                        <li>
                          숫자<i className="ico-check"></i>
                        </li>
                      ) : (
                        <li>숫자</li>
                      )}
                    </ul>
                    <ul
                      className={`validity-box mt12${confirmPw.length >= 8 && confirmPw.length <= 20 ? " check" : " "}`}
                    >
                      {confirmPw.length >= 8 && confirmPw.length <= 20 ? (
                        <li>
                          8~20자 이내<i className="ico-check"></i>
                        </li>
                      ) : (
                        <li>8~20자 이내</li>
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}

              {originPw && confirmPw.length >= 8 ? (
                <div className="tf-box-wrap">
                  <div className={`tf-box focus`}>
                    <label htmlFor="pw-check">새로운 비밀번호 확인</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="password"
                          id="pw-check"
                          className="tf"
                          placeholder="비밀번호 입력"
                          value={userPw}
                          onChange={(e) => handlePwInput(e, setUserPw)}
                          maxLength={20}
                        />
                        {userPw ? <button type="button" className="remove-btn" onClick={handleUserPwReset} /> : null}
                      </div>
                    </div>
                  </div>
                  <ul className={`validity-box mt12${userPw && userPw === confirmPw ? " check" : " "}`}>
                    {userPw && userPw === confirmPw ? (
                      <li>
                        비밀번호 일치<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>비밀번호 일치</li>
                    )}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${confirmPw === userPw ? " main-color" : " disabled"}`}
              onClick={handleUserPwEdit}
            >
              변경
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserPwEdit;
