import React, { useState } from "react";

import AccountBill from "components/ui/bill/AccountBill";
import AnalyzeBill from "components/ui/bill/AnalyzeBill";
import DateBottomModal from "components/ui/modal/DateBottomModal";
import Header from "components/ui/common/Header";
import { numberFormat } from "utils/fmt";

const BillDetail = ({
  billList,
  averageBillList,
  selectedBill,
  selectedLastBill,
  selectedAverageBill,
  billDetailList,
  cardInfo,
  handleListClick,
  handleLastListClick,
  handleAverageListClick,
  setIsBillDetail,
  setIsInvociceMonths,
  setIsSummaryPayment,
}) => {
  const [activeTab, setActiveTab] = useState("account");
  const [isPopup, setIsPopup] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleGoBack = () => {
    setIsBillDetail(false);
  };

  return (
    <React.Fragment>
      {isPopup && (
        <DateBottomModal
          billList={billList}
          closeModal={setIsPopup}
          selectedBill={selectedBill}
          handleListClick={handleListClick}
          handleLastListClick={handleLastListClick}
          handleAverageListClick={handleAverageListClick}
        />
      )}
      <div>
        <div className="wrapper history-detail">
          <Header Title="관리비 청구내역" handleGoBack={handleGoBack} />
          <div className="content pb20">
            <div key={0} className="maintenance-cost-box round-box type4">
              <div className="justify-between mb24">
                <div
                  className="to-modal-btn2 items-center"
                  onClick={() => {
                    setIsPopup(true);
                  }}
                >
                  <button type="button">
                    {selectedBill.billMonth.substring(0, 4)}년 {selectedBill.billMonth.substring(4)}월
                  </button>
                  <i className="ico-down-arr"></i>
                </div>
                <span className={`state ${selectedBill.paymentYn === "Y" ? "blue" : "red"}`}>
                  {selectedBill.paymentYn === "Y" ? "수납완료" : "미납"}
                </span>
              </div>
              <p className="cost">
                <strong>{numberFormat(selectedBill.billSum)}</strong>원
              </p>
              <div className="due-date-bar mt16">
                <p className="tit">납부마감일</p>
                <p className="desc">
                  {selectedBill.billDeadline.substring(0, 4)}.{selectedBill.billDeadline.substring(4, 6)}.
                  {selectedBill.billDeadline.substring(6)}
                </p>
              </div>
            </div>

            <div className="detail-tab-box mt20">
              <div className="tab">
                <button
                  type="button"
                  className={`tab-button ${activeTab === "account" ? "active" : ""}`}
                  onClick={() => handleTabChange("account")}
                >
                  조회
                </button>
                <button
                  type="button"
                  className={`tab-button ${activeTab === "analyze" ? "active" : ""}`}
                  onClick={() => handleTabChange("analyze")}
                >
                  분석
                </button>
              </div>
              {activeTab === "account" ? (
                <AccountBill
                  selectedBill={selectedBill}
                  billDetailList={billDetailList}
                  setIsBillDetail={setIsBillDetail}
                  setIsInvociceMonths={setIsInvociceMonths}
                />
              ) : (
                <AnalyzeBill
                  billList={billList}
                  averageBillList={averageBillList}
                  selectedBill={selectedBill}
                  selectedLastBill={selectedLastBill}
                  selectedAverageBill={selectedAverageBill}
                />
              )}
              {selectedBill.paymentYn === "N" && (
                <div className="button-box">
                  <button
                    type="button"
                    className="btn1 main-color"
                    onClick={() => {
                      setIsBillDetail(false);
                      setIsSummaryPayment(true);
                    }}
                  >
                    관리비 결제
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BillDetail;
