import { PATH, request } from "components/shared/Api";
import React, { useRef, useState } from "react";
import { isValidBirth, validationCheckBizNo } from "utils/fmt";

import Header from "components/ui/common/Header";
import RegCardTermModal from "components/ui/modal/RegCardTermModal";
import { onChange } from "utils/Common";
import { useNavigate } from "react-router-dom";

const CardRegistration = ({
  setIsCardRegistration,
  setIsCardRegComplete,
  setCardInfoChange,
  setIsAlert,
  setModalMsg,
  set_is_loading,
}) => {
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState({
    cardNo1: "",
    cardNo2: "",
    cardNo3: "",
    cardNo4: "",
    cardValidity: "",
    cardHldr: "",
    cardHldrinfo: "",
    cardPassword: "",
  });
  const cardNoRef = useRef([]);
  const { cardNo1, cardNo2, cardNo3, cardNo4, cardValidity, cardHldr, cardHldrinfo, cardPassword } = cardInfo;
  const [isRegCardTermModal, setIsRegCardTermModal] = useState(false);

  const [show_privacy, set_show_privacy] = useState(false);
  const [terms_title, set_terms_title] = useState("");
  const [terms_content, set_terms_content] = useState("");

  const [terms_agree_temp, set_terms_agree_temp] = useState({
    use_terms: false,
    personal_info_terms: false,
    thirdParty_terms: false,
  });

  // 카드내역등록 api 호출
  const handleRegCardInfo = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.카드내역등록, {
        cardNo: cardNo1 + cardNo2 + cardNo3 + cardNo4,
        cardValidityMM: cardValidity.substring(0, 2),
        cardValidityYY: cardValidity.substring(2, 4),
        cardHldr: cardHldr,
        cardHldrinfo: cardHldrinfo,
        cardPassword: cardPassword,
      });
      if (response.data.code === "0000") {
        setCardInfoChange("regComplete");
        setIsCardRegistration(false);
        setIsCardRegComplete(true);
      } else {
        set_is_loading(false);
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //약관목록조회 api
  const handleAccountTerms = async (code) => {
    try {
      const response = await request(PATH.약관목록조회, {
        code: code,
      });
      if (response.data.code === "0000") {
        set_show_privacy(true);
        set_terms_title(response.data.terms_list.terms_title);
        set_terms_content(response.data.terms_list.terms_content);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsRegCardTermModal(false);
    }
  };

  const handleCardInfoReset = (name) => {
    if (name.includes("cardNo")) {
      setCardInfo({
        ...cardInfo,
        cardNo1: "",
        cardNo2: "",
        cardNo3: "",
        cardNo4: "",
      });
    } else {
      setCardInfo({ ...cardInfo, [name]: "" });
    }
  };

  const handleCardTerms = () => {
    let msg = "";

    if (cardNo1.length !== 4 || cardNo2.length !== 4 || cardNo3.length !== 4 || cardNo4.length !== 4) {
      msg = "카드번호를\n정확히 입력해 주세요.";
    } else if (cardValidity.length !== 4) {
      msg = "유효기간을\n정확히 입력해 주세요.";
    } else if (cardHldr.trim().length < 2) {
      msg = "소유자명(대표자명)을\n입력해 주세요.";
    } else if (!(cardHldrinfo.length === 6 || cardHldrinfo.length === 10)) {
      msg = "생년월일(사업자번호)를\n정확히 입력해 주세요.";
    } else if (cardPassword.length !== 2) {
      msg = "비밀번호 2자리를\n입력해 주세요.";
    } else if (cardHldrinfo.length === 10 && !validationCheckBizNo(cardHldrinfo)) {
      msg = "유효한 사업자번호가 아닙니다.";
    } else if (cardHldrinfo.length === 6 && !isValidBirth(cardHldrinfo)) {
      msg = "유효한 생년월일이 아닙니다.";
    }

    if (msg !== "") {
      setIsAlert(true);
      setModalMsg(msg);
    } else {
      setIsRegCardTermModal(true);
    }
  };

  const handleGoBack = () => {
    setIsCardRegistration(false);
    navigate("/paymentMethod");
  };

  return (
    <React.Fragment>
      {show_privacy && (
        <div className="alert-modal terms">
          <div className="box">
            <div className="tit-box">
              <h2>{terms_title}</h2>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  set_show_privacy(false);
                  setIsRegCardTermModal(true);
                }}
              ></button>
            </div>
            <div className="desc-box" style={{ whiteSpace: "pre-wrap", textOverflow: "clip" }}>
              <p>{terms_content}</p>
            </div>
            <div className="button-box">
              <button
                type="button"
                className="btn1 main-color"
                onClick={() => {
                  set_show_privacy(false);
                  setIsRegCardTermModal(true);
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
      {isRegCardTermModal && (
        <RegCardTermModal
          closeModal={setIsRegCardTermModal}
          onClickAllAgree={handleRegCardInfo}
          handleAccountTerms={handleAccountTerms}
          terms_agree_temp={terms_agree_temp}
          set_terms_agree_temp={set_terms_agree_temp}
        />
      )}
      <div className="wrapper card-registration">
        <Header Title="관리비 청구내역" handleGoBack={handleGoBack} />
        <div className="content pt60">
          <div className="title-box mb40">
            <h2>
              관리비를
              <br />
              결제할 카드를 입력해 주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box focus`}>
                  <label htmlFor="card-number">카드번호</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <div className="card-num-box items-center">
                        <input
                          type="tel"
                          id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo1"
                          value={cardNo1 || ""}
                          onChange={(e) => {
                            onChange(e, cardInfo, setCardInfo, 4, "number");
                            if (e.target.value.length === 4) {
                              cardNoRef.current[1].focus();
                            }
                          }}
                          ref={(el) => (cardNoRef.current[0] = el)}
                        />
                        <input
                          type="tel"
                          // id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo2"
                          value={cardNo2 || ""}
                          onChange={(e) => {
                            onChange(e, cardInfo, setCardInfo, 4, "number");
                            if (e.target.value.length === 4) {
                              cardNoRef.current[2].focus();
                            }
                          }}
                          ref={(el) => (cardNoRef.current[1] = el)}
                        />
                        <input
                          type="tel"
                          // id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo3"
                          value={cardNo3 || ""}
                          onChange={(e) => {
                            onChange(e, cardInfo, setCardInfo, 4, "number");
                            if (e.target.value.length === 4) {
                              cardNoRef.current[3].focus();
                            }
                          }}
                          ref={(el) => (cardNoRef.current[2] = el)}
                        />
                        <input
                          type="tel"
                          // id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo4"
                          value={cardNo4 || ""}
                          onChange={(e) => {
                            onChange(e, cardInfo, setCardInfo, 4, "number");
                          }}
                          ref={(el) => (cardNoRef.current[3] = el)}
                        />
                      </div>
                      {(cardNo1 || cardNo2 || cardNo3 || cardNo4) && (
                        <button type="button" className="remove-btn" onClick={() => handleCardInfoReset("cardNo")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {(cardNo1 + cardNo2 + cardNo3 + cardNo4).length === 16 && (
                <div className="tf-box-wrap">
                  <div className={`tf-box${cardValidity ? " focus" : ""}`}>
                    <label htmlFor="card-expi">유효기간</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="password"
                          id="card-expi"
                          className="tf"
                          placeholder="유효기간 입력"
                          name={"cardValidity"}
                          value={cardValidity || ""}
                          onChange={(e) => {
                            onChange(e, cardInfo, setCardInfo, 4, "number");
                          }}
                          pattern="[0-9]*"
                          inputMode="numeric"
                        />
                        {cardValidity && (
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => handleCardInfoReset("cardValidity")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="desc">월/년도(MMYY) 순서로 4자리 입력</p>
                </div>
              )}

              {cardValidity.length === 4 && (
                <div className="tf-box-wrap">
                  <div className="tf-box">
                    <label htmlFor="card-owner">소유자명(대표자명)</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="text"
                          id="card-owner"
                          className="tf"
                          placeholder="소유자명(대표자명) 입력"
                          name={"cardHldr"}
                          value={cardHldr || ""}
                          onChange={(e) => onChange(e, cardInfo, setCardInfo, 40, "name")}
                        />
                        {cardHldr && (
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => handleCardInfoReset("cardHldr")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {cardHldr && (
                <div className="tf-box-wrap">
                  <div className="tf-box">
                    <label htmlFor="birthday">생년월일(사업자번호)</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="tel"
                          id="birthday"
                          className="tf"
                          placeholder="생년월일 6자리 입력(법인인 경우 사업자번호)"
                          name={"cardHldrinfo"}
                          value={cardHldrinfo}
                          onChange={(e) => onChange(e, cardInfo, setCardInfo, 10, "number")}
                        />
                        {cardHldrinfo && (
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => handleCardInfoReset("cardHldrinfo")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {(cardHldrinfo.length === 6 || cardHldrinfo.length === 10) && (
                <div className="tf-box-wrap">
                  <div className="tf-box">
                    <label htmlFor="password">비밀번호 앞 2자리</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="password"
                          id="password"
                          className="tf"
                          placeholder="비밀번호 앞 2자리 입력"
                          name={"cardPassword"}
                          value={cardPassword || ""}
                          onChange={(e) => onChange(e, cardInfo, setCardInfo, 2, "number")}
                          pattern="[0-9]*"
                          inputMode="numeric"
                        />
                        {cardPassword && (
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => handleCardInfoReset("cardPassword")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            {cardPassword.length === 2 && (
              <button
                type="button"
                className={`btn1${cardPassword ? " main-color" : " disabled"}`}
                onClick={() => {
                  handleCardTerms();
                }}
              >
                다음
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardRegistration;
