import Header from "components/ui/common/Header";
import React from "react";
import { bizNoFomatter } from "utils/Common";
import { numberFormat } from "utils/fmt";
import { useNavigate } from "react-router-dom";

const PaymentHistoryDetail = ({ bldInfo, billDetailInfo, setIsPaymentHistoryDetail, selectedBill, is_loading }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    setIsPaymentHistoryDetail(false);
    navigate("/paymentHistory");
  };

  return (
    <React.Fragment>
      {selectedBill.length !== 0 && billDetailInfo.length !== 0 ? (
        <div className="wrapper history-detail-payment">
          <Header Title="결제상세정보" handleGoBack={handleGoBack} structure={"close"} />

          <div className="content p0">
            <div className="round-box box1 type4">
              <p className="txt1 mb16">
                <span className="date">
                  {selectedBill.billMonth.substring(0, 4)}년 {selectedBill.regDtm.substring(4, 6)}월{" "}
                </span>
                결제완료
              </p>
              <p className="txt2">
                <strong>{numberFormat(selectedBill.payAmount)}</strong>원
              </p>
            </div>

            <div className="list-box-wrap">
              <div className="list-box mb40">
                <div className="list">
                  <p className="tit">청구번호</p>
                  <p className="desc">NO{billDetailInfo[0].billHisNo}</p>
                </div>
                <div className="list">
                  <p className="tit">거래구분</p>
                  <p className="desc">{selectedBill.payType.includes("직") ? "직접수납" : selectedBill.payType}</p>
                </div>
              </div>

              <h2 className="type2 mb20">결제상세</h2>

              <div className="list-box">
                <div className="list">
                  <p className="tit">결제방법</p>
                  <p className="desc">{billDetailInfo[0].payType}</p>
                </div>
                {selectedBill.payType === "카드결제" ? (
                  <React.Fragment>
                    <div className="list">
                      <p className="tit">카드종류</p>
                      <p className="desc">{billDetailInfo[0].payName}</p>
                    </div>
                    <div className="list">
                      <p className="tit">거래종류</p>
                      <p className="desc">{billDetailInfo[0].payType}</p>
                    </div>
                    <div className="list">
                      <p className="tit">결제금액</p>
                      <p className="desc">{numberFormat(selectedBill.payAmount)}원</p>
                    </div>
                    <div className="list">
                      <p className="tit">할부개월</p>
                      <p className="desc">{billDetailInfo[0].inatllMonth}</p>
                    </div>
                    <div className="list">
                      <p className="tit">카드번호</p>
                      <p className="desc">{billDetailInfo[0].cardNumb}</p>
                    </div>
                    <div className="list">
                      <p className="tit">결제일시</p>
                      <p className="desc">{billDetailInfo[0].regDtm}</p>
                    </div>
                    <div className="list">
                      <p className="tit">승인번호</p>
                      <p className="desc">{billDetailInfo[0].tid}</p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="list">
                      <p className="tit">입금은행</p>
                      <p className="desc">{billDetailInfo[0].payName === "" ? "-" : billDetailInfo[0].payName}</p>
                    </div>
                    <div className="list">
                      <p className="tit">입금계좌(승인번호)</p>
                      <p className="desc">{billDetailInfo[0].tid}</p>
                    </div>
                    <div className="list">
                      <p className="tit">입금금액(결제금액)</p>
                      <p className="desc">{numberFormat(selectedBill.payAmount)}원</p>
                    </div>
                    <div className="list">
                      <p className="tit">예금주(수납인)</p>
                      <p className="desc">
                        {billDetailInfo[0].payType.includes("직")
                          ? billDetailInfo[0].receiver
                          : billDetailInfo[0].accountHolder}
                      </p>
                    </div>
                    <div className="list">
                      <p className="tit">입금일시(결제일시)</p>
                      <p className="desc">{billDetailInfo[0].regDtm}</p>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>

            <hr className="mt20 mb20"></hr>

            {bldInfo && bldInfo[0] ? (
              <div className="list-box-wrap">
                <h2 className="type2 mb20">이용정보</h2>
                <div className="list-box">
                  <div className="list">
                    <p className="tit">서비스 제공자</p>
                    <p className="desc">{bldInfo[0].bldBizNm}</p>
                  </div>
                  <div className="list">
                    <p className="tit">사업자등록번호</p>
                    <p className="desc">{bizNoFomatter(bldInfo[0].bldBizNo)}</p>
                  </div>
                  <div className="list">
                    <p className="tit">주소</p>
                    <p className="desc">{bldInfo[0].bldAddr}</p>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="round-box box2">
              <p className="desc">
                관리비 납입영수증 발급과 카드취소는 관리사무소로 문의해 주세요. 위 결제 내역은 법적 효력을 가지지
                않습니다.
              </p>
            </div>
          </div>
        </div>
      ) : selectedBill.length === 0 && billDetailInfo.length !== 0 && !is_loading ? (
        <div className="no-content type2">
          <i className="ico-no-content"></i>
          <p>조회하신 내역이 없습니다.</p>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default PaymentHistoryDetail;
