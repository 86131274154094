import "swiper/css/pagination";
import "swiper/css";

import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";
import { Pagination } from "swiper/modules";
import profile from "assets/images/icon/icon_user_profile.svg";

const BoardNoticeDetail = ({ is_loading, set_is_loading, noticeBoardCd, setNoticeBoardCd, setIsBoardNoticeDetail }) => {
  const [boardNoticeDetail, setBoardNoticeDetail] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  //게시판공지상세내역 api
  const handleGetBoardNoticeDetail = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.게시판공지내역, {
        boardCd: noticeBoardCd,
      });
      if (response.data.code === "0000") {
        setBoardNoticeDetail(response.data);
        setIsBoardNoticeDetail(true);
        setNoticeBoardCd("");
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const handleGoBack = () => {
    setIsBoardNoticeDetail(false);
  };

  useEffect(() => {
    handleGetBoardNoticeDetail();
  }, []);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {boardNoticeDetail.length !== 0 ? (
        <div className="wrapper community">
          <Header Title={boardNoticeDetail.boardNoticeDetail[0].boardNm} handleGoBack={handleGoBack} />
          <div className="content p0 pb120">
            <div className="community-detail">
              <div className="detail-img-box">
                <div className="mb25">
                  {boardNoticeDetail.boardNoticePic && boardNoticeDetail.boardNoticePic.length > 0 ? (
                    <Swiper
                      spaceBetween={10}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {boardNoticeDetail.boardNoticePic.map((boardPic_map, i) => (
                        <SwiperSlide key={`boardPic_map_` + i}>
                          <img src={boardPic_map.boardPic} alt="이미지" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : null}
                </div>

                <div className="post-box user-box">
                  <div className="user mb12">
                    <img src={profile} alt="user profile" />
                    <span>{boardNoticeDetail.boardNoticeDetail[0].userNm}</span>
                  </div>
                  <p className="txt1">{boardNoticeDetail.boardNoticeDetail[0].boardTitle}</p>
                  <div className="justify-between">
                    <p className="txt2">
                      {boardNoticeDetail.boardNoticeDetail[0].regDtm.substring(0, 4)}.
                      {boardNoticeDetail.boardNoticeDetail[0].regDtm.substring(5, 7)}.
                      {boardNoticeDetail.boardNoticeDetail[0].regDtm.substring(8, 10)}{" "}
                      {boardNoticeDetail.boardNoticeDetail[0].regDtm.substring(11, 16)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="detail-desc-box">
                <div className="desc-box">
                  <p>{boardNoticeDetail.boardNoticeDetail[0].boardContent}</p>
                  <ul className="num-box type2 mt20">
                    <li>
                      <i className="ico-view"></i>
                      {boardNoticeDetail.boardNoticeDetail[0].viewCount}
                    </li>
                    <li>
                      <i className="ico-comment"></i>
                      {boardNoticeDetail.boardNoticeDetail[0].refCount}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="fixed-button-box">
            <div className="button-box">
              <button type="button" className="btn1 main-color" onClick={handleGoBack}>
                목록
              </button>
            </div>
          </div>
        </div>
      ) : boardNoticeDetail.length === 0 && !is_loading ? (
        <div className="no-content type2">
          <i className="ico-no-content"></i>
          <p>조회하신 내역이 없습니다.</p>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default BoardNoticeDetail;
