import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";
import Loading from "components/active/Loading";
import QnaDetail from "components/ui/bld/QnaDetail";
import QnaReg from "components/ui/bld/QnaReg";
import { useInView } from "react-intersection-observer";

export default function Qna() {
  const [inViewRef, inView] = useInView();
  const [last_cd, set_last_cd] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [is_loading, set_is_loading] = useState(false);
  const [show_detail, set_show_detail] = useState(false);
  const [show_regpage, set_show_regpage] = useState(false);

  const [detail, set_detail] = useState({});
  const [list, set_list] = useState([]);

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const getList = async () => {
    if (is_loading || !hasMoreData) return;
    try {
      set_is_loading(true);
      const req = {
        lastQnaCd: last_cd,
      };
      const response = await request(PATH.getQnaList, req);
      console.log(response.data);
      if (response.data.code === "0000") {
        if (response.data.qnaList.length > 0) {
          set_list((prevPushList) => [...prevPushList, ...response.data.qnaList]);
          set_last_cd(response.data.qnaList[response.data.qnaList.length - 1].qnaCd);
        } else {
          setHasMoreData(false);
        }
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const getDetail = async (qna_seq) => {
    try {
      set_is_loading(true);
      const req = {
        qna_seq: qna_seq,
      };
      const response = await request(PATH.getQnaDetail, req);
      if (response.status === 200 && !response.data.code) {
        set_detail(response.data.qna_info);
      } else {
        setIsAlert(true);
        setModalMsg("잠시 후 다시 시도해 주세요.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const show_detail_change = async () => {
    try {
      set_show_detail(!show_detail);
    } catch (error) {
      console.error(error);
    }
  };

  const show_regpage_change = async () => {
    try {
      set_show_regpage(!show_regpage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (last_cd === 0) {
      set_last_cd("");
    }
  }, [last_cd]);

  useEffect(() => {
    if (!show_detail && !show_regpage) {
      set_list([]);
      setHasMoreData(true);
      set_last_cd(0);
      set_detail({});
    }
  }, [show_detail, show_regpage]);

  useEffect(() => {
    if (inView && !is_loading && hasMoreData) {
      getList();
    }
  }, [inView]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}

      {show_regpage ? (
        //1:1 문의 등록페이지
        <QnaReg
          detail={detail}
          show_regpage_change={show_regpage_change}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
        />
      ) : show_detail ? (
        is_loading ? (
          <Loading />
        ) : (
          <QnaDetail
            detail={detail}
            show_detail_change={show_detail_change}
            show_regpage_change={show_regpage_change}
            setIsAlert={setIsAlert}
            setModalMsg={setModalMsg}
          />
        )
      ) : (
        //빌딩공지 메인 페이지
        <div className="wrapper inquiry">
          <Header Title="1:1 문의" handleGoBack={"main"} />
          {is_loading && <Loading />}

          <div className="content p0 pb120">
            <div className="inq-list mt16">
              {list.length !== 0
                ? list.map((list_map, index) => {
                    return (
                      <div
                        className="list-box"
                        key={`list_map_${index}`}
                        onClick={() => {
                          set_show_detail(true);
                          getDetail(list_map.qnaCd);
                        }}
                      >
                        <div className="left-box">
                          <p className="tit txt1">{list_map.qnaTitle}</p>
                          <p className="txt2">{list_map.regDtm}</p>
                        </div>
                        <span className={list_map.resYn === "N" ? "state main-color" : "state"}>
                          {list_map.resYn === "N" ? "대기중" : "답변완료"}
                        </span>
                      </div>
                    );
                  })
                : null}
              {list.length === 0 && !is_loading && (
                <div className="no-content type2">
                  <i className="ico-no-content"></i>
                  <p>조회하신 내역이 없습니다.</p>
                </div>
              )}
            </div>
            <div ref={inViewRef} style={{ height: "1px" }} />
          </div>

          <div className="fixed-button-box">
            <div className="button-box">
              <button
                type="button"
                className="btn1 main-color"
                onClick={() => {
                  set_show_regpage(true);
                }}
              >
                1:1 문의
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
