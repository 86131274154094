import Header from "components/ui/common/Header";
import React from "react";
import { numberFormat } from "utils/fmt";
import { useNavigate } from "react-router-dom";

const PaymentHistoryView = ({
  billInfo,
  lastBillInfo,
  setIsPaymentHistoryDetail,
  setSelectedBill,
  set_show_bottom_sheet,
  inViewRef,
  dt,
  order_by,
  is_loading,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    const isCompleteOrNotPayment = JSON.parse(localStorage.getItem("isCompleteOrNotPayment"));
    if (isCompleteOrNotPayment) {
      navigate("/bill");
      localStorage.removeItem("isCompleteOrNotPayment");
    } else {
      if (window.ReactNativeWebView) {
        const message = { navigate: "main" };
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
      } else {
        navigate("/main");
      }
    }
  };

  return (
    <div className="wrapper payment-history">
      <Header Title="관리비 결제내역" handleGoBack={handleGoBack} />
      <div className="content p0 pb20">
        <div className="round-box type5 flex-center mt20 mb20">
          <div className="hidden-box">
            <div className="rolling-box">
              <p className="mb20">
                이번 달 관리비 납부일은
                <br />
                {lastBillInfo?.billDeadline ? (
                  <>
                    <span className="month">{lastBillInfo?.billDeadline.substring(4, 6)}</span>월{" "}
                    <span className="day">{lastBillInfo?.billDeadline.substring(6, 8)}</span>일 입니다.
                  </>
                ) : (
                  <span>날짜 정보가 없습니다.</span>
                )}
              </p>
              {lastBillInfo.paymentYn === "N" ? (
                <p>
                  현재
                  <br />
                  미납중인 관리비가 있습니다.
                </p>
              ) : (
                <p>
                  현재
                  <br />
                  미납중인 관리비가 없습니다.
                </p>
              )}
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="filter-bar">
            <div className="left-box">
              <ul className="txt-box">
                <li>{dt}</li>
                <li>{order_by === "DESC" ? "최신순" : "과거순"}</li>
              </ul>
            </div>
            <div className="right-box justify-between">
              <button
                type="button"
                className="ico-filter"
                onClick={() => {
                  set_show_bottom_sheet(true);
                }}
              />
            </div>
          </div>

          <div className="history-list-wrap">
            {billInfo.length > 0 ? (
              <div className="history-list">
                {billInfo.map((v, i) => (
                  <div
                    key={i}
                    className="list"
                    onClick={() => {
                      setSelectedBill(billInfo[i]);
                      setIsPaymentHistoryDetail(true);
                    }}
                  >
                    <div className="justify-between">
                      <p className="txt1 date">
                        {v.regDtm.substring(0, 4)}.{v.regDtm.substring(4, 6)}.{v.regDtm.substring(6)}
                      </p>
                      <p className="txt2">{v.payType}</p>
                    </div>
                    <div className="justify-between mt14">
                      <p className="txt3">결제완료</p>
                      <p className="txt4 cost">
                        <span>{numberFormat(v.payAmount)}</span>원
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : billInfo.length === 0 && !is_loading ? (
              <div className="no-content">
                <i className="ico-no-content"></i>
                <p>조회하신 내역이 없습니다.</p>
              </div>
            ) : null}

            <div ref={inViewRef} style={{ height: "1px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistoryView;
