import { PATH, request } from "components/shared/Api";
import React, { useRef, useState } from "react";
import { isValidBirth, validationCheckBizNo } from "utils/fmt";

import CardEditNavigateModal from "components/ui/modal/CardEditNavigateModal";
import Header from "components/ui/common/Header";
import { onChange } from "utils/Common";

const CardEdit = ({
  cardInfo,
  setIsCardEdit,
  setCardInfoChange,
  setIsAlert,
  modalMsg,
  setModalMsg,
  set_is_loading,
}) => {
  const [cardInputs, setCardInputs] = useState({
    cardNo1: "",
    cardNo2: "",
    cardNo3: "",
    cardNo4: "",
    cardValidity: "",
    cardHldr: cardInfo.cardHldr || "",
    cardHldrinfo: cardInfo.cardHldrinfo || "",
    cardPassword: "",
  });

  const cardNoRef = useRef([]);
  const { cardNo1, cardNo2, cardNo3, cardNo4, cardValidity, cardHldr, cardHldrinfo, cardPassword } = cardInputs;

  const [isComplete, setIsComplete] = useState(false);

  // 카드내역수정 api 호출
  const handleRegCardInfo = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.카드내역등록, {
        cardNo: cardNo1 + cardNo2 + cardNo3 + cardNo4,
        cardValidityMM: cardValidity.substring(0, 2),
        cardValidityYY: cardValidity.substring(2, 4),
        cardHldr: cardHldr,
        cardHldrinfo: cardHldrinfo,
        cardPassword: cardPassword,
      });

      if (response.data.code === "0000") {
        setCardInfoChange("editComplete");
        setIsComplete(true);
        setModalMsg("카드 정보가 수정되었습니다.");
      } else {
        console.error(response.data.message);
        set_is_loading(false);
        setIsAlert(true);
        setModalMsg("카드정보를 수정할 수 없습니다.\n카드정보를 확인 후\n다시 입력해 주세요.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardInfoReset = (name) => {
    if (name.includes("cardNo")) {
      setCardInputs({ ...cardInputs, cardNo1: "", cardNo2: "", cardNo3: "", cardNo4: "" });
    } else {
      setCardInputs({ ...cardInputs, [name]: "" });
    }
  };

  const handleCardInfoEdit = () => {
    let msg = "";

    if (cardNo1.length !== 4 || cardNo2.length !== 4 || cardNo3.length !== 4 || cardNo4.length !== 4) {
      msg = "카드번호를\n정확히 입력해 주세요.";
    } else if (cardValidity.length !== 4) {
      msg = "유효기간을\n정확히 입력해 주세요.";
    } else if (cardHldr.trim().length < 2) {
      msg = "소유자명(대표자명)을\n입력해 주세요.";
    } else if (!(cardHldrinfo.length === 6 || cardHldrinfo.length === 10)) {
      msg = "생년월일(사업자번호)를\n정확히 입력해 주세요.";
    } else if (cardPassword.length !== 2) {
      msg = "비밀번호 2자리를\n입력해 주세요.";
    } else if (cardHldrinfo.length === 10 && !validationCheckBizNo(cardHldrinfo)) {
      msg = "유효한 사업자번호가 아닙니다.";
    } else if (cardHldrinfo.length === 6 && !isValidBirth(cardHldrinfo)) {
      msg = "유효한 생년월일이 아닙니다.";
    }

    if (msg !== "") {
      setIsAlert(true);
      setModalMsg(msg);
    } else {
      handleRegCardInfo();
    }
  };

  const handleGoBack = () => {
    setIsCardEdit(false);
  };

  return (
    <React.Fragment>
      {isComplete && (
        <CardEditNavigateModal closeModal={setIsComplete} modalMsg={modalMsg} setIsCardEdit={setIsCardEdit} />
      )}
      <div className="wrapper payment-method">
        <Header Title="카드 정보 수정" handleGoBack={handleGoBack} />
        <div className="content pt60 pb120">
          <div className="title-box mb28">
            <h2>
              카드정보를
              <br />
              수정해 주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box focus`}>
                  <label htmlFor="card-number">카드번호</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <div className="card-num-box items-center">
                        <input
                          type="tel"
                          id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo1"
                          value={cardNo1 || ""}
                          onChange={(e) => {
                            onChange(e, cardInputs, setCardInputs, 4, "number");
                            if (e.target.value.length === 4) {
                              cardNoRef.current[1].focus();
                            }
                          }}
                          ref={(el) => (cardNoRef.current[0] = el)}
                        />
                        <input
                          type="tel"
                          // id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo2"
                          value={cardNo2 || ""}
                          onChange={(e) => {
                            onChange(e, cardInputs, setCardInputs, 4, "number");
                            if (e.target.value.length === 4) {
                              cardNoRef.current[2].focus();
                            }
                          }}
                          ref={(el) => (cardNoRef.current[1] = el)}
                        />
                        <input
                          type="tel"
                          // id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo3"
                          value={cardNo3 || ""}
                          onChange={(e) => {
                            onChange(e, cardInputs, setCardInputs, 4, "number");
                            if (e.target.value.length === 4) {
                              cardNoRef.current[3].focus();
                            }
                          }}
                          ref={(el) => (cardNoRef.current[2] = el)}
                        />
                        <input
                          type="tel"
                          // id="card-number"
                          className="tf"
                          placeholder="0000"
                          name="cardNo4"
                          value={cardNo4 || ""}
                          onChange={(e) => {
                            onChange(e, cardInputs, setCardInputs, 4, "number");
                          }}
                          ref={(el) => (cardNoRef.current[3] = el)}
                        />
                      </div>
                      {(cardNo1 || cardNo2 || cardNo3 || cardNo4) && (
                        <button type="button" className="remove-btn" onClick={() => handleCardInfoReset("cardNo")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tf-box-wrap">
                <div className={`tf-box focus`}>
                  <label htmlFor="card-expi">유효기간</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="password"
                        id="card-expi"
                        className="tf"
                        placeholder="유효기간 입력"
                        name={"cardValidity"}
                        value={cardValidity || ""}
                        onChange={(e) => {
                          onChange(e, cardInputs, setCardInputs, 4, "number");
                        }}
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                      {cardValidity && (
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() => handleCardInfoReset("cardValidity")}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <p className="desc">월/년도(MMYY) 순서로 4자리 입력</p>
              </div>
            </div>

            <div className="tf-box-wrap">
              <div className="tf-box focus">
                <label htmlFor="card-owner">소유자명(대표자명)</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input
                      type="text"
                      id="card-owner"
                      className="tf"
                      placeholder="소유자명(대표자명) 입력"
                      name={"cardHldr"}
                      value={cardHldr || ""}
                      onChange={(e) => onChange(e, cardInputs, setCardInputs, 40, "name")}
                    />
                    {cardHldr && (
                      <button type="button" className="remove-btn" onClick={() => handleCardInfoReset("cardHldr")} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="tf-box-wrap">
              <div className="tf-box focus">
                <label htmlFor="birthday">생년월일(사업자번호)</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input
                      type="tel"
                      id="birthday"
                      className="tf"
                      placeholder="생년월일 6자리 입력(법인인 경우 사업자번호)"
                      name={"cardHldrinfo"}
                      value={cardHldrinfo}
                      onChange={(e) => onChange(e, cardInputs, setCardInputs, 10, "number")}
                    />
                    {cardHldrinfo && (
                      <button
                        type="button"
                        className="remove-btn"
                        onClick={() => handleCardInfoReset("cardHldrinfo")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="tf-box-wrap">
              <div className="tf-box focus">
                <label htmlFor="password">비밀번호 앞 2자리</label>
                <div className="line-box">
                  <div className="flex-box">
                    <input
                      type="password"
                      id="password"
                      className="tf"
                      placeholder="비밀번호 앞 2자리 입력"
                      name={"cardPassword"}
                      value={cardPassword || ""}
                      onChange={(e) => onChange(e, cardInputs, setCardInputs, 2, "number")}
                      pattern="[0-9]*"
                      inputMode="numeric"
                    />
                    {cardPassword && (
                      <button
                        type="button"
                        className="remove-btn"
                        onClick={() => handleCardInfoReset("cardPassword")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box type2">
            <button type="button" className="btn1 sub-color narrow" onClick={handleGoBack}>
              취소
            </button>
            <button type="button" className="btn1 main-color wide" onClick={handleCardInfoEdit}>
              수정
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardEdit;
