import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
// import YnRouter from "./routes/YnRouter";
import Router from "./routes/Router";

ReactDOM.render(
  <BrowserRouter>
    {/* <YnRouter /> */}
    <Router />
  </BrowserRouter>,
  document.getElementById("root"),
);
