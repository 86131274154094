import { PATH, request } from "components/shared/Api";
import React, { useContext, useEffect, useRef, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import { JoinContext } from "components/shared/JoinContext";
import JoinNavigateModal from "components/ui/modal/JoinNavigateModal";
import { useNavigate } from "react-router-dom";

const PhoneVerification = () => {
  const navigate = useNavigate();
  const { joinProps, setJoinProps } = useContext(JoinContext);
  const [userNm, setUserNm] = useState(joinProps.userNm);
  const [userMobileNo, setUserMobileNo] = useState(joinProps.userMobileNo);
  const [value, setValue] = useState("");
  const [sendValue, setSendValue] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [code, setCode] = useState("");
  const verificationCodeInputRef = useRef(null);

  // 인증요청 api
  const handleMobileAuth = async () => {
    const regPhone = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

    if (userNm === "") {
      setIsAlert(true);
      setModalMsg("이름을 확인하세요.");
    } else if (userMobileNo.length < 10) {
      setIsAlert(true);
      setModalMsg("휴대폰 번호를 확인하세요.");
    } else if (!regPhone.test(userMobileNo)) {
      setIsAlert(true);
      setModalMsg("휴대폰 번호를\n정확히 입력해 주세요.");
    } else {
      try {
        const response = await request(PATH.휴대폰인증, {
          userNm: userNm,
          userMobileNo: userMobileNo,
          joinYN: "Y",
        });
        if (response.data.code && response.data.code !== "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
        } else if (response.data.code === "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
          setSendValue(true);
          setJoinProps((prevData) => ({
            ...prevData,
            userNm: userNm,
            userMobileNo: userMobileNo,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // 아이디,비밀번호 찾기 api
  const handlePhoneVerification = async () => {
    try {
      const response = await request(PATH.아이디비밀번호찾기, {
        userNm: userNm,
        userMobileNo: userMobileNo,
        value: value,
        joinYN: "Y",
      });
      // console.log("handlePhoneVerification", response.data);
      if (response.data.code && response.data.code !== "0000") {
        setCode(response.data.code);
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else if (response.data.code === "0000") {
        setJoinProps((prevData) => ({
          ...prevData,

          value: value,
        }));
        setIsNavigate(true);
        setModalMsg("인증이 완료되었습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNmReset = () => {
    setUserNm("");
  };

  const handleMobileNoReset = () => {
    setUserMobileNo("");
    setSendValue(false);
  };

  const handleValueReset = () => {
    setValue("");
  };

  useEffect(() => {
    console.log("Change setting join props:", joinProps);
  }, [joinProps]);

  const handleUserMobileNo = (e) => {
    let input = e.target.value;

    // 숫자만 허용, 공백 제거
    input = input.replace(/[^\d]/g, "");

    const maxLength = 11;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setUserMobileNo(input);
  };

  const handleUserNm = (e) => {
    let input = e.target.value;

    input = input.replace(/[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣ㆍ]/g, "");

    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setUserNm(input);
  };

  return (
    <React.Fragment>
      {isAlert === true ? (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            if (verificationCodeInputRef.current) {
              verificationCodeInputRef.current.focus();
            }
            if (code === "B000") {
              navigate("/phoneVerification");
            }
          }}
        />
      ) : null}
      {isNavigate === true ? <JoinNavigateModal modalMsg={modalMsg} closeModal={setIsNavigate} value={value} /> : null}
      <div className="wrapper verification">
        <header>
          <div className="h-btn-box">
            <button type="button" className="back-btn" onClick={handleGoBack} />
          </div>
          <h1>회원가입</h1>
        </header>

        <div className="content">
          <ul className="indicator mt16 mb12">
            <li className="flex-center">1</li>
            <li className="flex-center on">2</li>
            <li className="flex-center">3</li>
            <li className="flex-center">4</li>
            <li className="flex-center">5</li>
            <li className="flex-center">6</li>
          </ul>

          <div className="title-box mb40">
            <h2>
              휴대폰인증을
              <br />
              진행합니다.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              {/* 이름 입력 */}
              <div className="tf-box-wrap">
                <div className={`tf-box${userNm ? " focus" : ""}`}>
                  <label htmlFor="name-join">이름 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="name-join"
                        className="tf"
                        placeholder="이름 입력"
                        value={userNm}
                        onChange={handleUserNm}
                      />
                      {userNm ? <button type="button" className="remove-btn" onClick={handleNmReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* 휴대폰 번호 입력 */}
              <div className="tf-box-wrap">
                <div className={`tf-box${userMobileNo ? " focus" : ""}`}>
                  <label htmlFor="phone-join">휴대폰 번호</label>
                  <div className="line-box type2">
                    <div className="flex-box">
                      <input
                        type="tel"
                        id="phone-join"
                        className="tf"
                        placeholder="휴대폰 번호(-없이 입력)"
                        value={userMobileNo}
                        onChange={handleUserMobileNo}
                      />
                      {userMobileNo ? (
                        <button type="button" className="remove-btn" onClick={handleMobileNoReset} />
                      ) : null}
                    </div>
                    <button
                      type="button"
                      className={`btn-tf${userMobileNo ? " on" : " off"}`}
                      onClick={handleMobileAuth}
                      disabled={!userMobileNo}
                    >
                      {sendValue ? "다시요청" : "인증요청"}
                    </button>
                  </div>
                </div>
              </div>

              {/* 인증번호 입력 */}
              {sendValue && (
                <div className="tf-box-wrap">
                  <div className={`tf-box${value ? " focus" : ""}`}>
                    <label htmlFor="v-code">인증번호 입력</label>
                    <div className="line-box">
                      <div className="flex-box">
                        <input
                          type="tel"
                          id="v-code"
                          className="tf"
                          placeholder="인증번호 입력"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          maxLength={5}
                          disabled={!sendValue}
                          ref={verificationCodeInputRef}
                        />
                        {value ? <button type="button" className="remove-btn" onClick={handleValueReset} /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${value ? " main-color" : " disabled"}`}
              onClick={handlePhoneVerification}
            >
              인증하기
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PhoneVerification;
