import React from "react";

const AlertImage = ({ title, set_is_modal_img, setImage }) => {
  return (
    <div className="bottom-sheet-modal">
      <div className="box">
        <div className="tit-box justify-between">
          <h2 className="type2">{title}</h2>
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              set_is_modal_img(false);
            }}
          ></button>
        </div>

        <div className="con-box type3">
          <div className="button-box">
            <div className="file-btn-box items-center gap10">
              <label htmlFor="camera">사진 촬영</label>
              <input
                type="file"
                id="camera"
                capture="camera"
                accept=".jpg, .png, application/octet-stream"
                onChange={(e) => setImage(e)}
              />
              <label htmlFor="photo">앨범에서 선택</label>
              <input
                type="file"
                id="photo"
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                onChange={(e) => setImage(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertImage;
