import { PATH, request } from "components/shared/Api";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AlertModal from "components/ui/modal/AlertModal";
import FindNaivgateModal from "components/ui/modal/FindNaivgateModal";
import IdFind from "components/ui/find/IdFind";
import PwFind from "components/ui/find/PwFind";

const Find = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userNm, setUserNm] = useState("");
  const [userMobileNo, setUserMobileNo] = useState("");
  const [value, setValue] = useState("");
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [sendValue, setSendValue] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [responseItems, setResponseItems] = useState([]);
  const [activeTab, setActiveTab] = useState("id");
  const verificationCodeInputRef = useRef(null);
  const userNmInputRef = useRef(null);
  const userMobileNoInputRef = useRef(null);
  const userIdInputRef = useRef(null);

  // 인증요청 api
  const handleMobileAuth = async () => {
    if (userNm === "") {
      setIsAlert(true);
      setModalMsg("이름을 확인하세요.");
    } else {
      try {
        const response = await request(PATH.휴대폰인증, {
          userNm: userNm,
          userMobileNo: userMobileNo,
          userId: userId,
        });
        if (response.data.code && response.data.code !== "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
        } else if (response.data.code === "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
          setSendValue(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // 아이디,비밀번호 찾기 api
  const handleLoginFind = async () => {
    if (activeTab !== "id" && userId === "") {
      setIsAlert(true);
      setModalMsg("아이디를 확인하세요.");
    } else if (userNm === "") {
      setIsAlert(true);
      setModalMsg("이름을 확인하세요.");
    } else if (userMobileNo === "") {
      setIsAlert(true);
      setModalMsg("휴대폰번호를 확인하세요.");
    } else {
      try {
        const response = await request(PATH.아이디비밀번호찾기, {
          userNm: userNm,
          userMobileNo: userMobileNo,
          userId: userId,
          value: value,
          userPw: userPw,
        });
        if (response.data.code && response.data.code !== "0000") {
          setIsAlert(true);
          setModalMsg(response.data.message);
        } else if (response.data.code === "0000") {
          setResponseItems(response.data);
          if (!userId) {
            setIsNavigate(true);
            setModalMsg(response.data.message);
          } else {
            setIsNavigate(true);
            setModalMsg(response.data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCloseFind = () => {
    navigate("/login");
  };

  const handleUserIdReset = () => {
    setUserId("");
  };

  const handleNmReset = () => {
    setUserNm("");
  };

  const handleMobileNoReset = () => {
    setUserMobileNo("");
    setSendValue(false);
  };

  const handleValueReset = () => {
    setValue("");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setUserNm("");
    setUserMobileNo("");
    setValue("");
    setUserId("");
    setUserPw("");
    setSendValue(false);
  };

  useEffect(() => {
    const state = location.state;
    if (state && state.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [location.state]);

  return (
    <React.Fragment>
      {isAlert === true ? (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            if (verificationCodeInputRef.current) {
              verificationCodeInputRef.current.focus();
            } else if (activeTab === "pw" && userId === "") {
              userIdInputRef.current.focus();
            } else if (userNm === "") {
              userNmInputRef.current.focus();
            } else if (userMobileNo === "") {
              userMobileNoInputRef.current.focus();
            }
          }}
        />
      ) : null}
      {isNavigate === true ? (
        <FindNaivgateModal
          modalMsg={modalMsg}
          closeModal={setIsNavigate}
          responseItems={responseItems}
          userId={userId}
          handleLoginFind={handleLoginFind}
          userNm={userNm}
          userMobileNo={userMobileNo}
          value={value}
        />
      ) : null}
      <div>
        <div className="wrapper find">
          <header className="right">
            <h1>로그인 정보 찾기</h1>
            <div className="h-btn-box">
              <button type="button" className="close-btn" onClick={handleCloseFind} />
            </div>
          </header>

          <div className="content">
            <div className="tab">
              <button
                type="button"
                className={`tab-button ${activeTab === "id" ? "active" : ""}`}
                onClick={() => handleTabChange("id")}
              >
                아이디 찾기
              </button>
              <button
                type="button"
                className={`tab-button ${activeTab === "pw" ? "active" : ""}`}
                onClick={() => handleTabChange("pw")}
              >
                비밀번호 찾기
              </button>
            </div>

            {activeTab === "id" ? (
              <IdFind
                userNm={userNm}
                setUserNm={setUserNm}
                userMobileNo={userMobileNo}
                setUserMobileNo={setUserMobileNo}
                value={value}
                setValue={setValue}
                sendValue={sendValue}
                handleMobileAuth={handleMobileAuth}
                handleLoginFind={handleLoginFind}
                handleNmReset={handleNmReset}
                handleMobileNoReset={handleMobileNoReset}
                handleValueReset={handleValueReset}
                verificationCodeInputRef={verificationCodeInputRef}
                userNmInputRef={userNmInputRef}
                userMobileNoInputRef={userMobileNoInputRef}
              />
            ) : (
              <PwFind
                userNm={userNm}
                setUserNm={setUserNm}
                userMobileNo={userMobileNo}
                setUserMobileNo={setUserMobileNo}
                value={value}
                setValue={setValue}
                userId={userId}
                setUserId={setUserId}
                userPw={userPw}
                setUserPw={setUserPw}
                sendValue={sendValue}
                handleMobileAuth={handleMobileAuth}
                handleLoginFind={handleLoginFind}
                handleUserIdReset={handleUserIdReset}
                handleNmReset={handleNmReset}
                handleMobileNoReset={handleMobileNoReset}
                handleValueReset={handleValueReset}
                verificationCodeInputRef={verificationCodeInputRef}
                userNmInputRef={userNmInputRef}
                userMobileNoInputRef={userMobileNoInputRef}
                userIdInputRef={userIdInputRef}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Find;
