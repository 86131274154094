import React from "react";
import { numberFormat } from "utils/fmt";

const AccountBill = ({
  selectedBill,
  billDetailList,
  setIsBillDetail,
  setIsInvociceMonths,
}) => {
  const accountBillList = [
    { billName: "당월부과액", billAmount: selectedBill.billSum },
    { billName: "할인총계", billAmount: 0 },
    ...billDetailList.filter((item) => item.billName === "미납액"),
    { billName: "납기내금액", billAmount: selectedBill.billSum },
  ];

  return (
    <div className="tab-content">
      <div className="section-box section1 mb30">
        <div className="toggle-btn justify-between">
          <h2>결제요약정보</h2>
        </div>
        <div className="list-box">
          {accountBillList.map((item, index) => (
            <div className="list" key={index}>
              <p className="tit">{item.billName}</p>
              <p className="desc">{numberFormat(item.billAmount)}원</p>
            </div>
          ))}
        </div>
      </div>
      <div className="section-box section2 mb30">
        <button
          type="button"
          className="bar-btn justify-between"
          onClick={() => {
            setIsBillDetail(false);
            setIsInvociceMonths(true);
          }}
        >
          <h2>{selectedBill.billMonth.substring(4, 6)}월 상세내역</h2>
          <i className="ico-right-arr"></i>
        </button>
      </div>
    </div>
  );
};

export default AccountBill;
