import React, { useState } from "react";

const RegCardTermModal = ({
  closeModal,
  onClickAllAgree,
  handleAccountTerms,
  terms_agree_temp,
  set_terms_agree_temp,
}) => {
  const { use_terms, personal_info_terms, thirdParty_terms } = terms_agree_temp;

  const handleTermsAgree = (e) => {
    const { name } = e.target;

    set_terms_agree_temp({ ...terms_agree_temp, [name]: !terms_agree_temp[name] });
  };

  const handlerTermsAgreeReset = () => {
    set_terms_agree_temp({
      use_terms: false,
      personal_info_terms: false,
      thirdParty_terms: false,
    });
  };

  return (
    <div className="bottom-sheet-modal">
      <div className="box">
        <div className="tit-box justify-between">
          <h2 className="type2">결제 카드 등록 동의</h2>
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
              handlerTermsAgreeReset();
            }}
          />
        </div>

        <div className="con-box type2">
          <div className="agree-box">
            <ul className="main-agree">
              <li className="type2">
                <button
                  type="button"
                  onClick={() => {
                    handleAccountTerms("use_terms");
                  }}
                >
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree1">
                  <input
                    type="checkbox"
                    id="agree1"
                    name={"use_terms"}
                    value={use_terms}
                    checked={use_terms}
                    onChange={(e) => handleTermsAgree(e)}
                  />
                  <span>
                    이용약관 <span className="main-color">(필수)</span>
                  </span>
                </label>
              </li>
              <li className="type2">
                <button
                  type="button"
                  onClick={() => {
                    handleAccountTerms("personal_info_terms");
                  }}
                >
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree2">
                  <input
                    type="checkbox"
                    id="agree2"
                    name={"personal_info_terms"}
                    value={personal_info_terms}
                    checked={personal_info_terms}
                    onChange={(e) => handleTermsAgree(e)}
                  />
                  <span>
                    개인정보 처리방침 <span className="main-color">(필수)</span>
                  </span>
                </label>
              </li>
              <li className="type2">
                <button
                  type="button"
                  onClick={() => {
                    handleAccountTerms("thirdParty_terms");
                  }}
                >
                  <i className="ico-right-arr-2"></i>
                </button>
                <label htmlFor="agree3">
                  <input
                    type="checkbox"
                    id="agree3"
                    name={"thirdParty_terms"}
                    value={thirdParty_terms}
                    checked={thirdParty_terms}
                    onChange={(e) => handleTermsAgree(e)}
                  />
                  <span>
                    개인정보 제3자 제공 <span className="main-color">(필수)</span>
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              handlerTermsAgreeReset();
              onClickAllAgree();
              closeModal(false);
            }}
          >
            전체 동의하고 저장
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RegCardTermModal);
