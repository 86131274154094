import "assets/css/common.css";
import "assets/css/component.css";

import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Ad from "views/pages/ad/Ad";
import AppleLogin from "loginTest/AppleLogin";
import Bill from "views/pages/payment/Bill";
import BldInfo from "views/pages/bld/BldInfo";
import Community from "views/pages/community/Community";
import DetailJoin from "views/pages/join/DetailJoin";
import Find from "views/pages/login/Find";
import FindResult from "views/pages/login/FindResult";
import IdJoin from "views/pages/join/IdJoin";
import Join from "views/pages/join/Join";
import JoinComplete from "views/pages/join/JoinComplete";
import { JoinProvider } from "components/shared/JoinContext";
import KakaoLogin from "loginTest/KakaoLogin";
import Login from "views/pages/login/Login";
import Main from "views/pages/Main";
import Menu from "views/pages/afterLogin/Menu";
import NaverLogin from "loginTest/NaverLogin";
import Notice from "views/pages/afterLogin/Notice";
import NoticeList from "views/pages/bld/NoticeList";
import PaymentHistory from "views/pages/payment/PaymentHistory";
import PaymentMethod from "views/pages/payment/PaymentMethod";
import PhoneVerification from "views/pages/join/PhoneVerification";
import Privacy from "views/pages/etc/Privacy";
import PwJoin from "views/pages/join/PwJoin";
import Qna from "views/pages/bld/Qna";
import ResetPw from "views/pages/login/ResetPw";
import RoomJoin from "views/pages/join/RoomJoin";
import Setting from "views/pages/afterLogin/Setting";
import Terms from "views/pages/join/Terms";
import UserInfo from "views/pages/afterLogin/UserInfo";
import { loadToken } from "components/shared/Api";

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadToken(navigate, location);
  }, [navigate, location]);

  const NotFound = () => {
    return <div>Not Found</div>; // You can customize this component as needed
  };

  return (
    <JoinProvider>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/main" element={<Main />} />

        {/* 로그인 */}
        <Route path="/login" element={<Login />} />
        <Route path="/login/kakaologin" element={<KakaoLogin />} />
        <Route path="/login/naverlogin" element={<NaverLogin />} />
        <Route path="/login/applelogin" element={<AppleLogin />} />
        <Route path="/find" element={<Find />} />
        <Route path="/findResult" element={<FindResult />} />
        <Route path="/resetPw" element={<ResetPw />} />

        {/* 회원가입 */}
        <Route path="/join" element={<Join />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/phoneVerification" element={<PhoneVerification />} />
        <Route path="/idJoin" element={<IdJoin />} />
        <Route path="/pwJoin" element={<PwJoin />} />
        <Route path="/roomJoin" element={<RoomJoin />} />
        <Route path="/detailJoin" element={<DetailJoin />} />
        <Route path="/joinComplete" element={<JoinComplete />} />

        {/* 로그인 후 홈 */}
        <Route path="/setting" element={<Setting />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/userInfo" element={<UserInfo />} />

        {/* 관리비 청구내역 */}
        <Route path="/bill" element={<Bill />} />

        {/* 관리비 결제내역(납부내역) */}
        <Route path="/paymentHistory" element={<PaymentHistory />} />

        {/* 관리수단 관리 */}
        <Route path="/paymentMethod" element={<PaymentMethod />} />

        {/* 커뮤니티 */}
        <Route path="/community" element={<Community />} />

        {/* 관리실 */}
        <Route path="/qna" element={<Qna />} />
        <Route path="/noticelist" element={<NoticeList />} />
        <Route path="/bldinfo" element={<BldInfo />} />

        {/* 업체광고 */}
        <Route path="/ad" element={<Ad />} />

        {/* 개인정보처리방침 */}
        <Route path="/privacy" element={<Privacy />} />

        <Route path="*" element={<Main />} />
      </Routes>
    </JoinProvider>
  );
};

export default Router;
