import React from "react";

const NoCardInfo = ({ userInfoFromStorage, setIsCardRegistration }) => {
  return (
    <div className="content pt60 pb20">
      <div className="method-box no-regi mb60">
        <div className="title-box mb20">
          <h2>관리비 결제카드</h2>
        </div>

        <div className="card-box no-card round-box flex-center mb20">
          <div className="txt-box">
            <i className="ico-warning"></i>
            <p>등록된 카드가 없습니다.</p>
          </div>
        </div>

        <div className="button-box flex-center">
          <button
            type="button"
            className="btn1 type3 items-center"
            onClick={() => {
              setIsCardRegistration(true);
            }}
          >
            <i className="ico-plus" />
            결제카드 등록
          </button>
        </div>
      </div>

      <div className="round-box-wrap">
        <h4 className="mb16">관리비 결제 가상계좌</h4>
        <div className="round-box">
          <div className="list-box">
            {/* <div className="list">
              <p className="tit">예금주</p>
              <p className="desc">홍길동</p>
            </div> */}
            <div className="list">
              <p className="tit">은행</p>
              <p className="desc">{userInfoFromStorage.virtuaAccountNm}은행</p>
            </div>
            <div className="list">
              <p className="tit">계좌번호</p>
              <p className="desc">{userInfoFromStorage.virtuaAccountNumber}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoCardInfo;
