import React, { useState } from "react";

import DeleteCardModal from "components/ui/modal/DeleteCardModal";
import { formatCardNumber } from "utils/fmt";

const CardInfoExists = ({
  userInfoFromStorage,
  cardInfo,
  setIsCardEdit,
  setIsAutoPayReg,
  setIsAutoPayDel,
  setCardInfoChange,
}) => {
  const [isNavigate, setIsNavigate] = useState(false);

  return (
    <React.Fragment>
      {isNavigate && <DeleteCardModal closeModal={setIsNavigate} setCardInfoChange={setCardInfoChange} />}
      <div className="content pt60 pb20">
        <div className="method-box mb60">
          <div className="title-box mb20">
            <h2>관리비 결제카드</h2>
          </div>

          <div className="card-box round-box type3 mb20">
            <div className="card-info-box wh-color">
              <p className="com">{cardInfo.cardNm}</p>
              <p className="num">{formatCardNumber(cardInfo.cardNo)}</p>
              <p className="expi-date">
                <span className="month">{cardInfo.cardValidityMM}</span>/
                <span className="day">{cardInfo.cardValidityYY}</span>
              </p>
            </div>
            <button
              type="button"
              className="remove-btn"
              onClick={() => {
                setIsNavigate(true);
              }}
            />
          </div>

          <div className="button-box type2">
            <button
              type="button"
              className="btn1 narrow type3 sub-color"
              onClick={() => {
                setIsCardEdit(true);
              }}
            >
              수정
            </button>

            <button
              type="button"
              className={`btn1 wide type3${cardInfo.cardAuto === "N" ? " main-color" : " cancel"}`}
              onClick={() => {
                if (cardInfo.cardAuto === "N") {
                  setIsAutoPayReg(true);
                } else {
                  setIsAutoPayDel(true);
                }
              }}
            >
              자동 결제카드 {cardInfo.cardAuto === "N" ? "등록" : "해지"}
            </button>
          </div>
        </div>

        <div className="round-box-wrap">
          <h4 className="mb16">관리비 결제 가상계좌</h4>
          <div className="round-box">
            <div className="list-box">
              {/* <div className="list">
                <p className="tit">예금주</p>
                <p className="desc">홍길동</p>
              </div> */}
              <div className="list">
                <p className="tit">은행</p>
                <p className="desc">{userInfoFromStorage.virtuaAccountNm}은행</p>
              </div>
              <div className="list">
                <p className="tit">계좌번호</p>
                <p className="desc">{userInfoFromStorage.virtuaAccountNumber}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardInfoExists;
