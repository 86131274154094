import { PATH, request } from "components/shared/Api";
import React, { useContext, useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import { JoinContext } from "components/shared/JoinContext";
import { signValidation } from "utils/Common";
import { useNavigate } from "react-router-dom";
import { validationCheckBizNo } from "utils/fmt";

const DetailJoin = () => {
  const navigate = useNavigate();
  const { joinProps } = useContext(JoinContext);
  const [roomRprsnNm, setRoomRprsnNm] = useState("");
  const [roomNm, setRoomNm] = useState("");
  const [roomBizNo, setRoomBizNo] = useState("");
  const [roomMail, setRoomMail] = useState("");
  const [roomTel, setRoomTel] = useState("");
  const [nickName, setNickName] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  //빌딩 조회 api
  const handleJoin = async () => {
    if (roomBizNo.length !== 10) {
      setIsAlert(true);
      setModalMsg("사업자번호를 확인해주세요.");
    } else if (!validationCheckBizNo(roomBizNo)) {
      setIsAlert(true);
      setModalMsg("유효한 사업자번호가 아닙니다.");
    } else if (roomTel && roomTel.length <= 8) {
      setIsAlert(true);
      setModalMsg("담당자 연락처를 확인해주세요.");
    } else if (roomMail !== "" && !signValidation("email", roomMail)) {
      setIsAlert(true);
      setModalMsg("이메일 형식을 확인해 주세요.");
    } else {
      try {
        const response = await request(PATH.사용자회원가입, {
          userId: joinProps.userId,
          userPw: joinProps.userPw,
          joinType: "mobile",
          bldCd: joinProps.bldCd,
          roomCd: joinProps.roomCd,
          userNm: joinProps.userNm,
          userMobileNo: joinProps.userMobileNo,
          nickName: nickName,
          receipApp: joinProps.receipApp,
          receipSms: joinProps.receipSms,
          receipEmail: joinProps.receipEmail,
          roomRprsnNm: roomRprsnNm,
          roomNm: roomNm,
          roomTel: roomTel,
          roomMail: roomMail,
          roomBizNo: roomBizNo,
          value: joinProps.value,
        });
        if (response.data.code === "0000") {
          navigate("/joinComplete");
        } else {
          setIsAlert(true);
          setModalMsg(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleRoomRprsnNmReset = () => {
    setRoomRprsnNm("");
  };

  const handleRoomNmReset = () => {
    setRoomNm("");
  };

  const handleRoomBizNoReset = () => {
    setRoomBizNo("");
  };

  const handleRoomMailReset = () => {
    setRoomMail("");
  };

  const handleRoomTelReset = () => {
    setRoomTel("");
  };

  const handleNickNameReset = () => {
    setNickName("");
  };

  const handleRoomRprsnNmChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣ㆍ]/g, "");
    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }
    setRoomRprsnNm(input);
  };

  const handleRoomNmChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^\dㄱ-ㅎㅏ-ㅣ가-힣ㆍa-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }
    setRoomNm(input);
  };

  const handleRoomBizNo = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");
    if (input.length > 10) {
      input = input.slice(0, 10);
    }
    setRoomBizNo(input);
  };

  const handleRoomMailChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^\dㄱ-ㅎㅏ-ㅣ가-힣ㆍa-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }
    setRoomMail(input);
  };

  const handleRoomTel = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");

    if (input.length > 11) {
      input = input.slice(0, 11);
    }
    setRoomTel(input);
  };

  const handleNickNameChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣ㆍ]/g, "");
    const maxLength = 30;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }
    setNickName(input);
  };

  useEffect(() => {
    console.log("Change setting join props:", joinProps);
  }, [joinProps]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      <div className="wrapper detail-join">
        <header>
          <div className="h-btn-box">
            <button type="button" className="back-btn" onClick={handleGoBack} />
          </div>
          <h1>회원가입</h1>
        </header>

        <div className="content">
          <ul className="indicator mt16 mb12">
            <li className="flex-center">1</li>
            <li className="flex-center">2</li>
            <li className="flex-center">3</li>
            <li className="flex-center">4</li>
            <li className="flex-center">5</li>
            <li className="flex-center on">6</li>
          </ul>

          <div className="title-box mb40">
            <h2>
              입주자 정보를
              <br />
              입력해 주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box${roomRprsnNm ? " focus" : ""}`}>
                  <label htmlFor="detail-join1">대표자명 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="detail-join1"
                        className="tf"
                        placeholder="대표자명 입력(필수)"
                        value={roomRprsnNm}
                        onChange={handleRoomRprsnNmChange}
                      />
                      {roomRprsnNm ? (
                        <button type="button" className="remove-btn" onClick={handleRoomRprsnNmReset} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className={`tf-box${roomNm ? " focus" : ""}`}>
                  <label htmlFor="detail-join2">업체명 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="detail-join2"
                        className="tf"
                        placeholder="업체명 입력(필수)"
                        value={roomNm}
                        onChange={handleRoomNmChange}
                      />
                      {roomNm ? <button type="button" className="remove-btn" onClick={handleRoomNmReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className={`tf-box${roomBizNo ? " focus" : ""}`}>
                  <label htmlFor="detail-join3">사업자번호 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="tel"
                        id="detail-join3"
                        className="tf"
                        placeholder="사업자번호 입력(필수)"
                        value={roomBizNo}
                        onChange={handleRoomBizNo}
                      />
                      {roomBizNo ? (
                        <button type="button" className="remove-btn" onClick={handleRoomBizNoReset} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-box">
              <h3 className="mb20">추가 정보를 입력해 주세요.</h3>

              <div className="tf-box-wrap">
                <div className={`tf-box${roomMail ? " focus" : ""}`}>
                  <label htmlFor="detail-join4">이메일 주소 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="detail-join4"
                        className="tf"
                        placeholder="이메일 주소 입력(선택)"
                        value={roomMail}
                        onChange={handleRoomMailChange}
                      />
                      {roomMail ? <button type="button" className="remove-btn" onClick={handleRoomMailReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className={`tf-box${roomTel ? " focus" : ""}`}>
                  <label htmlFor="detail-join5">담당자 연락처 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="tel"
                        id="detail-join5"
                        className="tf"
                        placeholder="담당자 연락처 입력(선택)"
                        value={roomTel}
                        onChange={handleRoomTel}
                      />
                      {roomTel ? <button type="button" className="remove-btn" onClick={handleRoomTelReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tf-box-wrap">
                <div className={`tf-box${nickName ? " focus" : ""}`}>
                  <label htmlFor="detail-join6">사용하실 별칭 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="text"
                        id="detail-join6"
                        className="tf"
                        placeholder="사용하실 별칭 입력(선택)"
                        value={nickName}
                        onChange={handleNickNameChange}
                        maxLength={30}
                      />
                      {nickName ? <button type="button" className="remove-btn" onClick={handleNickNameReset} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${roomRprsnNm && roomNm && roomBizNo ? " main-color" : " disabled"}`}
              onClick={() => {
                if (!roomRprsnNm || !roomNm || !roomBizNo) {
                } else {
                  handleJoin();
                }
              }}
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailJoin;
