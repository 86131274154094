import React from "react";
import { useNavigate } from "react-router-dom";

const JoinNavigateModal = ({ closeModal, modalMsg, value, userId }) => {
  const navigate = useNavigate();

  const navigateToIdJoin = () => {
    navigate("/idJoin");
  };

  const navigateToPwJoin = () => {
    navigate("/pwJoin");
  };

  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          {/* <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          /> */}
        </div>
        <div className="desc">
          <p>{modalMsg}</p>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              if (value) {
                navigateToIdJoin();
              } else if (userId) {
                navigateToPwJoin();
              }
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinNavigateModal;
