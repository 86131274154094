import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import PhoneUserInfo from "components/ui/userInfo/PhoneUserInfo";
import UserInfoEdit from "components/ui/userInfo/UserInfoEdit";
import UserInfoView from "components/ui/userInfo/UserInfoView";
import UserPwEdit from "components/ui/userInfo/UserPwEdit";

export default function UserInfo() {
  const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));
  const [userList, setUserList] = useState([]);
  const [isPhoneUserInfo, setIsPhoneUserInfo] = useState(false);
  const [isUserInfoEdit, setIsUserInfoEdit] = useState(false);
  const [isUserPwEdit, setIsUserPwEdit] = useState(false);
  const [twoWayGoBack, setTwoWayGoBack] = useState(false);

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  //사용자회원목록 api
  const handleUserInfo = async () => {
    try {
      const response = await request(PATH.사용자회원목록, {});
      if (response.data.code === "0000") {
        setUserList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleUserInfo();
  }, []);
  console.log("isUserInfoEdit", isUserInfoEdit);
  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {isPhoneUserInfo ? (
        <PhoneUserInfo setIsPhoneUserInfo={setIsPhoneUserInfo} setIsAlert={setIsAlert} setModalMsg={setModalMsg} />
      ) : isUserInfoEdit ? (
        <UserInfoEdit
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          userList={userList}
          setUserList={setUserList}
          setIsUserInfoEdit={setIsUserInfoEdit}
          setIsUserPwEdit={setIsUserPwEdit}
          setTwoWayGoBack={setTwoWayGoBack}
        />
      ) : isUserPwEdit ? (
        <UserPwEdit
          setIsUserInfoEdit={setIsUserInfoEdit}
          setIsUserPwEdit={setIsUserPwEdit}
          twoWayGoBack={twoWayGoBack}
          setTwoWayGoBack={setTwoWayGoBack}
        />
      ) : (
        <UserInfoView
          userInfoFromStorage={userInfoFromStorage}
          userList={userList}
          setIsPhoneUserInfo={setIsPhoneUserInfo}
          setIsUserPwEdit={setIsUserPwEdit}
          setIsUserInfoEdit={setIsUserInfoEdit}
        />
      )}
    </React.Fragment>
  );
}
