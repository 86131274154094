import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import Header from "components/ui/common/Header";

const MyBoard = ({
  boardNm,
  setBoardNm,
  boardType,
  setDetailBoardCd,
  setIsMyBoard,
  setIsCommonBoardDetail,
  setIsAlert,
  setModalMsg,
  is_loading,
  set_is_loading,
}) => {
  const [myBoardList, setMyBoardList] = useState([]);

  //내가쓴글 api
  const handleGetMyBoard = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.내가쓴글, { boardNm: boardNm, lastBoardCd: "" });
      if (response.data.code === "0000") {
        setMyBoardList(response.data.myBoard);
      } else {
        if (boardNm) {
          setIsAlert(true);
          setModalMsg(response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const handleGoBack = () => {
    setIsMyBoard(false);
  };

  useEffect(() => {
    if (boardNm === "") {
      setBoardNm("벼룩시장");
    }
  }, []);

  useEffect(() => {
    handleGetMyBoard();
  }, [boardNm, boardType]);

  return (
    <div className="wrapper community">
      <Header Title="내가 등록한 글" handleGoBack={handleGoBack} />
      <div className="content p0">
        <div className="tab-community">
          <button
            type="button"
            className={`${boardNm === "벼룩시장" ? "active" : ""}`}
            onClick={() => {
              setBoardNm("벼룩시장");
            }}
          >
            벼룩시장
          </button>
          <button
            type="button"
            className={`${boardNm === "정보공유" ? "active" : ""}`}
            onClick={() => {
              setBoardNm("정보공유");
            }}
          >
            정보공유
          </button>
          <button
            type="button"
            className={`${boardNm === "구인구직" ? "active" : ""}`}
            onClick={() => {
              setBoardNm("구인구직");
            }}
          >
            구인구직
          </button>
          <button
            type="button"
            className={`${boardNm === "자유게시판" ? "active" : ""}`}
            onClick={() => {
              setBoardNm("자유게시판");
            }}
          >
            자유게시판
          </button>
        </div>

        <div className="community-list community-list1">
          {myBoardList.length > 0 ? (
            myBoardList.map((v, i) => (
              <div
                key={i}
                className="list-box"
                onClick={() => {
                  setDetailBoardCd(v.boardCd);
                  // setIsMyBoard(false);
                  setIsCommonBoardDetail(true);
                }}
              >
                <p className="tit txt1">{v.boardTitle}</p>
                <p className="txt2">
                  {v.regDtm.substring(0, 4)}.{v.regDtm.substring(5, 7)}.{v.regDtm.substring(8, 10)}{" "}
                  {v.regDtm.substring(11, 16)}
                </p>
              </div>
            ))
          ) : myBoardList.length === 0 && !is_loading ? (
            <div className="no-content type2">
              <i className="ico-no-content"></i>
              <p>조회하신 내역이 없습니다.</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MyBoard;
