import { PATH, request } from "components/shared/Api";
import React, { useContext, useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import { JoinContext } from "components/shared/JoinContext";
import JoinNavigateModal from "components/ui/modal/JoinNavigateModal";
import { useNavigate } from "react-router-dom";

const IdJoin = () => {
  const navigate = useNavigate();
  const { joinProps, setJoinProps } = useContext(JoinContext);
  const [joinId, setJoinId] = useState(joinProps.userId);
  const [isAlert, setIsAlert] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  //아이디중복조회 api
  const handleAccountId = async () => {
    try {
      const response = await request(PATH.아이디중복조회, {
        userId: joinId,
      });
      if (response.data.code && response.data.code !== "0000") {
        setIsAlert(true);
        setModalMsg(response.data.message);
      } else if (response.data.code === "0000") {
        setJoinProps((prevData) => ({
          ...prevData,
          userId: joinId,
        }));
        setIsNavigate(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleIdReset = () => {
    setJoinId("");
  };

  const handleIdInput = (e) => {
    let input = e.target.value;

    // 영문과 숫자만 허용, 나머지는 제거
    input = input.replace(/[^A-Za-z0-9]/g, "");

    const maxLength = 20;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setJoinId(input);
  };

  useEffect(() => {
    console.log("Change setting join props:", joinProps);
  }, [joinProps]);

  return (
    <React.Fragment>
      {isAlert === true ? <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} /> : null}
      {isNavigate === true ? (
        <JoinNavigateModal modalMsg={modalMsg} closeModal={setIsNavigate} userId={joinId} />
      ) : null}
      <div className="wrapper id-join">
        <header>
          <div className="h-btn-box">
            <button type="button" className="back-btn" onClick={handleGoBack} />
          </div>
          <h1>회원가입</h1>
        </header>

        <div className="content">
          <ul className="indicator mt16 mb12">
            <li className="flex-center">1</li>
            <li className="flex-center">2</li>
            <li className="flex-center on">3</li>
            <li className="flex-center">4</li>
            <li className="flex-center">5</li>
            <li className="flex-center">6</li>
          </ul>

          <div className="title-box mb40">
            <h2>
              사용하실
              <br />
              아이디를 입력해 주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box${joinId ? " focus" : ""}`}>
                  <label htmlFor="id-join">아이디 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      {joinProps.social === "Y" ? (
                        <input
                          type="text"
                          id="id-join"
                          className="tf"
                          placeholder="아이디 입력(20자 이내)"
                          defaultValue={joinId}
                        />
                      ) : (
                        <input
                          type="text"
                          id="id-join"
                          className="tf"
                          placeholder="아이디 입력(20자 이내)"
                          value={joinId}
                          onChange={handleIdInput}
                        />
                      )}
                      {joinId && joinProps.social !== "Y" ? (
                        <button type="button" className="remove-btn" onClick={handleIdReset} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <ul className={`validity-box mt12${joinId.length > 0 && joinId.length <= 20 ? " check" : " "}`}>
                  {joinId.length > 0 && joinId.length <= 20 ? (
                    <li>
                      20자 이내<i className="ico-check"></i>
                    </li>
                  ) : (
                    <li>20자 이내</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${joinId ? " main-color" : " disabled"}`}
              onClick={() => {
                if (!joinId) {
                  setIsAlert(true);
                  setModalMsg("아이디를 입력해 주세요.");
                } else {
                  handleAccountId();
                }
              }}
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IdJoin;
