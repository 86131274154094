import React from "react";

const AlertModal = ({ closeModal, modalMsg, onClick, isCancel }) => {
  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">
          <p>{modalMsg}</p>
        </div>
        <div className="button-box">
          {isCancel && (
            <button
              type="button"
              className="btn1 sub-color"
              onClick={() => {
                closeModal(false);
              }}
            >
              취소
            </button>
          )}

          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              if (onClick) onClick();
              closeModal(false);
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
