import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AlertModal from "components/ui/modal/AlertModal";
import profile from "assets/images/icon/icon_user_profile.svg";

const Menu = () => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  //사용자회원목록 api
  const handleUserInfo = async () => {
    try {
      const response = await request(PATH.사용자회원목록, {});
      // console.log(response.data);
      if (response.data.code === "0000") {
        setUserList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={"인증완료 후 이용할 수 있습니다."} closeModal={setIsAlert} />}
      <div className="wrapper menu">
        <header>
          <div className="h-btn-box">
            <button
              type="button"
              className="setting-btn"
              onClick={() => {
                navigate("/setting");
              }}
            />
          </div>
          <h1>전체 메뉴</h1>
          <div className="h-btn-box">
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                if (searchParams.get("page")) {
                  navigate(searchParams.get("page"));
                } else {
                  if (window.ReactNativeWebView) {
                    const message = { navigate: "main" };
                    window.ReactNativeWebView.postMessage(JSON.stringify(message));
                  } else {
                    navigate("/main");
                  }
                }
              }}
            ></button>
          </div>
        </header>

        <div className="content pt40 pb20">
          <div className="user-profile-box mb20">
            {userInfoFromStorage.userPic === "" ? (
              <img src={profile} alt="유저 이미지" />
            ) : (
              <img src={userInfoFromStorage.userPic} alt="유저 이미지" />
            )}

            <button
              type="button"
              onClick={() => {
                navigate("/userInfo");
              }}
            >
              {userInfoFromStorage.socialType === "kakao" ? (
                <i className="ico-round-kakao"></i>
              ) : userInfoFromStorage.socialType === "naver" ? (
                <i className="ico-round-naver"></i>
              ) : userInfoFromStorage.socialType === "apple" ? (
                <i className="ico-round-apple"></i>
              ) : null}
              <span className="txt">
                {userInfoFromStorage.nickName ? userInfoFromStorage.nickName : userList.roomNm}
              </span>
              <i className="ico-right-arr"></i>
            </button>
          </div>

          <div className="menu-box-wrap">
            <div className="menu-box">
              <h4>관리비 납부관리</h4>
              <ul>
                <li className="main-menu menu1-1">
                  <button
                    type="button"
                    onClick={() => {
                      if (userInfoFromStorage.userStts === "normal") {
                        navigate("/bill");
                      } else {
                        setIsAlert(true);
                      }
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>관리비 청구내역
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
                <li className="main-menu menu1-2">
                  <button
                    type="button"
                    onClick={() => {
                      if (userInfoFromStorage.userStts === "normal") {
                        navigate("/paymentHistory");
                      } else {
                        setIsAlert(true);
                      }
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>관리비 결제내역(납부내역)
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
                <li className="main-menu menu1-3">
                  <button
                    type="button"
                    onClick={() => {
                      if (userInfoFromStorage.userStts === "normal") {
                        navigate("/paymentMethod");
                      } else {
                        setIsAlert(true);
                      }
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>결제수단 관리
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div className="menu-box">
              <h4>커뮤니티</h4>
              <ul>
                <li className="main-menu menu2-1">
                  <span className="no-btn">
                    <span>
                      <i className="ico-menu"></i>벼룩시장
                    </span>
                    <i className="ico-right-arr"></i>
                  </span>
                  <ol>
                    <li className="sub-menu">
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/community", {
                            state: { category: "팝니다" },
                          });
                        }}
                      >
                        팝니다
                      </button>
                    </li>
                    <li className="sub-menu">
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/community", {
                            state: { category: "삽니다" },
                          });
                        }}
                      >
                        삽니다
                      </button>
                    </li>
                  </ol>
                </li>
                <li className="main-menu menu2-2">
                  <span className="no-btn">
                    <span>
                      <i className="ico-menu"></i>정보공유
                    </span>
                    <i className="ico-right-arr"></i>
                  </span>
                  <ol>
                    <li className="sub-menu">
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/community", {
                            state: { category: "지식산업센터" },
                          });
                        }}
                      >
                        지식산업센터
                      </button>
                    </li>
                    <li className="sub-menu">
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/community", {
                            state: { category: "오피스텔빌딩" },
                          });
                        }}
                      >
                        오피스텔, 빌딩
                      </button>
                    </li>
                    <li className="sub-menu">
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/community", {
                            state: { category: "기타" },
                          });
                        }}
                      >
                        기타
                      </button>
                    </li>
                  </ol>
                </li>
                <li className="main-menu menu2-3">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/community", {
                        state: { category: "구인구직" },
                      });
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>구인, 구직
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
                <li
                  className="main-menu menu2-4"
                  onClick={() => {
                    navigate("/community", {
                      state: { category: "자유게시판" },
                    });
                  }}
                >
                  <button type="button">
                    <span>
                      <i className="ico-menu"></i>자유게시판
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="menu-box">
              <h4>관리실</h4>
              <ul>
                <li className="main-menu menu3-1">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/qna");
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>문의하기
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
                <li className="main-menu menu3-2">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/noticelist");
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>공지사항
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
                <li className="main-menu menu3-3">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/bldinfo");
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>관리실 정보
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div className="menu-box">
              <h4>업체광고</h4>
              <ul>
                <li className="main-menu menu4-1">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/ad");
                    }}
                  >
                    <span>
                      <i className="ico-menu"></i>등록 업체 광고
                    </span>
                    <i className="ico-right-arr"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="round-box">
            <p className="txt1">관리비 입금 가상계좌번호</p>
            <p className="txt2">
              {userInfoFromStorage.virtuaAccountNumber}
              <span> {userInfoFromStorage.virtuaAccountNm}은행</span>
            </p>
          </div>

          <div className="f-info">
            상호명 : 에스에이치커뮤니케이션
            <br />
            본사 : 경기도 구리시 갈매중앙로 190, 씨2032호, 씨2033호 (갈매동, 휴밸나인지식산업센터 1동)
            <br />
            대표이사 : 김정환 사업자 번호 : 293-87-03124
            <br />
            문의 : 031-528-2245
            <br />
            이메일 : lion907@sh-data.co.kr
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Menu;
