import moment from "moment";
import { numberFormat } from "utils/fmt";

// 생년월일 format
export function birthFomatter(birth) {
  let formatBirth = moment(birth).format("YYYY-MM-DD");
  return formatBirth;
}

// 휴대폰번호 format
export function phoneFomatter(num, type) {
  var formatNum = "";
  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    if (num.indexOf("02") === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3");
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }
  }
  return formatNum;
}

export function getToday() {
  let date = new Date();
  return date;
}

export function calcDay(target, day) {
  let date = new Date(target);
  date.setDate(date.getDate() + day);
  return date;
}

export function dateFomatter(target) {
  let date = moment(target);
  return date.format("YYYYMMDD");
}

// 사업자 등록번호 포맷팅
export function bizNoFomatter(bizNo, type) {
  let formatBizNo = bizNo;
  if (bizNo.length === 10) {
    if (type === 0) {
      formatBizNo = bizNo.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-*****");
    } else {
      formatBizNo = bizNo.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
    }
  }
  return formatBizNo;
}
// 카드번호 포맷팅
export function cdNoFomatter(cdNo) {
  // cdNo가 undefined인 경우 빈 문자열 반환
  if (typeof cdNo === "undefined" || cdNo === null) {
    return "";
  }

  let targetNo = cdNo.replace(/[^0-9]/g, ""); // 수정: [^0-16] -> [^0-9]
  var formatCdNo = "";
  formatCdNo += targetNo.substr(0, 4);
  formatCdNo += "-********-";
  formatCdNo += targetNo.substr(-4); // 수정: targetNo.substr(12, 4) -> targetNo.substr(-4)
  return formatCdNo;
}

// res data useState set
export const convertArray = async (dataList, key, callback) => {
  let resArray = [];
  for (let i = 0; i < dataList.length; i++) {
    let item = dataList[i];
    let formattedCd = cdNoFomatter(item[key]); // 카드번호 포맷팅 함수 호출
    resArray.push(formattedCd);
  }
  callback(resArray);
};
// 회원 이름 마스킹
export function userNmMasking(userNm) {
  var maskingNm = "";
  if (userNm !== null || userNm !== "") {
    if (userNm.length === 3) {
      maskingNm += userNm.substr(0, 1);
      maskingNm += "*";
      maskingNm += userNm.substr(2, 1);
    } else if (userNm.length === 2) {
      maskingNm += userNm.substr(0, 1);
      maskingNm += "*";
    } else {
      maskingNm += userNm.substr(0, 1);
      for (let i = 0; i < userNm.length - 2; i++) {
        maskingNm += "*";
      }
      maskingNm += userNm.substr(userNm.length - 1, 1);
    }
  }

  return maskingNm;
}

export const regex = {
  number: /^[0-9]+$/,
  hangle: /^[ㄱ-ㅎ가-힣ㆍ\s]+$/,
  special: /^[a-zA-Z0-9-_@.]+$/,
  name: /^[ㄱ-ㅎ가-힣ㆍa-zA-Z\s]+$/,
  id: /^[a-zA-Z0-9]+$/,
  pw: /^[a-zA-Z0-9`!@#$%^&*~()_|+\-=?;:'",.<>\{\}\[\]\\\/]+$/,
  double: /^[0-9.]+$/,
};

//빌딩 공지 구분값
export const NOTICE_SEARCH_TYPE_LIST = [
  { value: "all", name: "전체" },
  { value: "pay", name: "결제" },
  { value: "use", name: "이용" },
  { value: "err", name: "오류" },
  { value: "alarm", name: "알림" },
  { value: "etc", name: "기타" },
];

export const QNA_TYPE_LIST = [
  { value: "pay", name: "결제관련" },
  { value: "err", name: "오류관련" },
  { value: "cancle", name: "취소관련" },
  { value: "use", name: "이용관련" },
  { value: "etc", name: "기타" },
];

/**
 * inputs 관리 onChange
 * @param {string} e            e 이벤트
 * @param {string} inputs       input 관리 객체
 * @param {string} inputsSet    input 입력시 동작할 함수명
 * @param {number} maxLength    최대 입력글자수
 * @param {string} type         정규식 체크할 타입(hangle, number, id, special, name)
 * @param {function} setIsAlert setIsAlert 함수
 * @param {function} setModalMsg setModalMsg 함수
 */
export const onChange = (e, inputs, inputsSet, maxLength, type, setIsAlert, setModalMsg) => {
  let { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
  if (value === "") {
    inputsSet({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value, // name 키를 가진 값을 value 로 설정
    });
    return;
  }

  if (maxLength && value.length > maxLength) {
    if (setIsAlert) {
      setIsAlert(true);
      setModalMsg(`최대 ${maxLength}자까지만 입력 가능합니다.`);
    }
    value = value.slice(0, maxLength);
    // return;
  }

  if (type === "comma") {
    value = value.replace(/,/gi, "");
  }

  if (type === "hangle" && !regex.hangle.test(value)) {
    // setIsAlert(true);
    // setModalMsg(`한글만 입력해 주세요.`);
    return;
  } else if (type === "number" && !regex.number.test(value)) {
    // setIsAlert(true);
    // setModalMsg(`숫자만 입력해 주세요.`);
    return;
  } else if (type === "id" && !regex.id.test(value)) {
    // setIsAlert(true);
    // setModalMsg(`영문과 숫자만 입력해 주세요.`);
    return;
  } else if (type === "special" && !regex.special.test(value)) {
    // setIsAlert(true);
    // setModalMsg(`한글을 제외하고 입력해 주세요.`);
    return;
  } else if (type === "name" && !regex.name.test(value)) {
    // setIsAlert(true);
    // setModalMsg(`한글과 영문만 입력해 주세요.`);
    return;
  } else if (type === "pw" && !regex.pw.test(value)) {
    // setIsAlert(true);
    // setModalMsg(`비밀번호 규칙을 확인해 주세요.`);
    return;
  } else if (type === "double" && !regex.double.test(value)) {
    return;
  }

  if (type === "comma") {
    value = numberFormat(value);
  }

  inputsSet({
    ...inputs,
    [name]: value,
  });
};

/**
 * inputs 관리 onChange2
 * @param {object} e            e 이벤트
 * @param {string} input        input 값
 * @param {function} setInput   input 입력시 동작할 함수명
 * @param {function} setIsAlert setIsAlert 함수
 * @param {function} setModalMsg setModalMsg 함수
 * @param {number} maxLength    최대 입력글자수
 * @param {string} type         정규식 체크할 타입(hangle, number, id, special, name)
 */
export const onChange2 = (e, input, setInput, setIsAlert, setModalMsg, maxLength, type) => {
  let value = e.target.value;

  if (value === "") {
    setInput(input);
    return;
  }

  if (maxLength && value.length > maxLength) {
    setIsAlert(true);
    setModalMsg(`최대 ${maxLength}자까지만 입력 가능합니다.`);
    value = value.slice(0, maxLength);
  }

  if (type === "comma") {
    value = value.replace(/,/gi, "");
  }

  if (type === "hangle" && !regex.hangle.test(value)) {
    setIsAlert(true);
    setModalMsg(`한글만 입력해 주세요.`);
    return;
  } else if (type === "number" && !regex.number.test(value)) {
    setIsAlert(true);
    setModalMsg(`숫자만 입력해 주세요.`);
    return;
  } else if (type === "id" && !regex.id.test(value)) {
    setIsAlert(true);
    setModalMsg(`영문과 숫자만 입력해 주세요.`);
    return;
  } else if (type === "special" && !regex.special.test(value)) {
    setIsAlert(true);
    setModalMsg(`한글을 제외하고 입력해 주세요.`);
    return;
  } else if (type === "name" && !regex.name.test(value)) {
    setIsAlert(true);
    setModalMsg(`한글과 영문만 입력해 주세요.`);
    return;
  } else if (type === "pw" && !regex.pw.test(value)) {
    setIsAlert(true);
    setModalMsg(`비밀번호 규칙을 확인해 주세요.`);
    return;
  } else if (type === "double" && !regex.double.test(value)) {
    return;
  }

  if (type === "comma") {
    value = numberFormat(value);
  }

  setInput(value);
};

/**
 * @description 회원가입시 유효성 검사
 * @param {String} validType
 * @param {String} value
 * @returns {Boolean} true/false
 */
export const signValidation = (validType, value) => {
  let regExp = "";
  switch (validType) {
    case "mobile":
      regExp = /^(010|011|016|017|018|019)-\d{3,4}-\d{4}$/g;
      break;
    case "email":
      regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
      break;
    case "name":
      regExp = /^[가-힣ㆍa-zA-Z]+$/;
      break;
    case "id":
      regExp = /^[0-9a-zA-Z_-]{5,20}$/;
      break;
    case "password":
      regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@.!%*#?&_-]{8,20}$/;
      break;
    case "bizNo":
      regExp = /^[0-9]{10}$/;
      break;
    default:
      return;
  }

  return regExp.test(value);
};

export const isEmpty = (object) => !Object.values(object).every((x) => x !== null && x !== "");
