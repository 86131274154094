import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import BottomSheet from "components/ui/common/BottomSheet";
import CommonBoard from "components/ui/community/CommonBoard";
import CommonBoardDetail from "components/ui/community/CommonBoardDetail";
import CommunityView from "components/ui/community/CommunityView";
import EnrollBoard from "components/ui/community/EnrollBoard";
import Loading from "components/active/Loading";
import MyBoard from "components/ui/community/MyBoard";
import SearchBox from "components/ui/common/SearchBox";
import SellBuy from "components/ui/community/SellBuy";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

export default function Community() {
  const location = useLocation();
  const receivedState = location.state;
  const [boardMainInfo, setBoardMainInfo] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [boardListDetail, setBoardListDetail] = useState({});
  const [title, setTitle] = useState("");
  const [boardNm, setBoardNm] = useState("");
  const [boardType, setBoardType] = useState("");
  const [detailBoardCd, setDetailBoardCd] = useState("");
  const [checkEdit, setCheckEdit] = useState(false);
  const [isMyBoard, setIsMyBoard] = useState(false);
  const [isEnrollBoard, setIsEnrollBoard] = useState(false);
  const [isCommonBoardDetail, setIsCommonBoardDetail] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const [inViewRef, inView] = useInView();
  const [last_cd, set_last_cd] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [is_loading, set_is_loading] = useState(false);

  const [show_bottom_sheet, set_show_bottom_sheet] = useState(false);
  const [show_search_box, set_show_search_box] = useState("");

  const order_by_list = [
    { name: "최신순", value: "DESC" },
    { name: "과거순", value: "ASC" },
  ];
  const [order_by, set_order_by] = useState("DESC");
  const [api_order_by, set_api_order_by] = useState("DESC");

  const area_list = [
    { value: "", name: "전체" },
    { value: "서울", name: "서울" },
    { value: "경기", name: "경기" },
    { value: "강원도", name: "강원도" },
    { value: "충청도", name: "충청도" },
    { value: "경상도", name: "경상도" },
    { value: "전라도", name: "전라도" },
    { value: "제주도", name: "제주도" },
  ];

  const center_list = [
    { value: "", name: "전체" },
    { value: "주식", name: "주식" },
    { value: "IT", name: "IT" },
    { value: "자료실", name: "자료실" },
    { value: "취업", name: "취업" },
    { value: "취미", name: "취미" },
    { value: "지식", name: "지식" },
    { value: "생활", name: "생활" },
    { value: "기타", name: "기타" },
  ];

  const job_list = [
    { value: "", name: "전체" },
    { value: "구인", name: "구인" },
    { value: "구직", name: "구직" },
  ];

  const free_list = [
    { value: "", name: "전체" },
    { value: "자유", name: "자유" },
    { value: "유머", name: "유머" },
    { value: "스포츠", name: "스포츠" },
    { value: "게임", name: "게임" },
    { value: "연애", name: "연애" },
  ];

  const [type_name, set_type_name] = useState("지역");
  const [type_list, set_type_list] = useState(area_list);
  const [type, set_type] = useState("");
  const [api_type, set_api_type] = useState("");
  const [category_name, set_category_name] = useState("분류");
  const [category_list, set_category_list] = useState(center_list);
  const [category, set_category] = useState("");
  const [api_category, set_api_category] = useState("");

  const dt_list = [
    { name: "최근7일", str_dt: moment().subtract(7, "day").format("YYYYMMDD"), end_dt: moment().format("YYYYMMDD") },
    {
      name: "최근1개월",
      str_dt: moment().subtract(1, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    {
      name: "최근3개월",
      str_dt: moment().subtract(3, "month").format("YYYYMMDD"),
      end_dt: moment().format("YYYYMMDD"),
    },
    { name: "월별", str_dt: "", end_dt: "" },
    { name: "기간설정", str_dt: "", end_dt: "" },
  ];

  const [dt, set_dt] = useState("최근7일");
  const [str_dt, set_str_dt] = useState(dt_list[0].str_dt);
  const [end_dt, set_end_dt] = useState(dt_list[0].end_dt);
  const [api_str_dt, set_api_str_dt] = useState(dt_list[0].str_dt);
  const [api_end_dt, set_api_end_dt] = useState(dt_list[0].end_dt);

  const [search_text, set_search_text] = useState("");
  const [api_search_text, set_api_search_text] = useState("");

  const research = async () => {
    set_api_order_by(order_by);
    set_api_type(type);
    set_api_category(category);
    set_api_str_dt(str_dt);
    set_api_end_dt(end_dt);
    set_api_search_text(search_text);

    setBoardList([]);
    setHasMoreData(true);
    set_last_cd(0);
    set_show_bottom_sheet(false);
    set_show_search_box(false);
  };

  //게시판메인 api
  const handleGetBoardMain = async () => {
    try {
      const response = await request(PATH.게시판메인, {});
      if (response.data.code === "0000") {
        setBoardMainInfo(response.data);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //게시판내역조회 api
  const handleGetBoardList = async () => {
    if (is_loading || !hasMoreData || boardNm === "") return;
    try {
      set_is_loading(true);
      let req_boardtype = boardType;
      let req_boardcategory = api_category;
      if (title === "구인구직") {
        req_boardcategory = "";
        req_boardtype = api_category;
      }
      const response = await request(PATH.게시판내역조회, {
        boardNm: boardNm,
        boardType: req_boardtype,
        orderBy: api_order_by,
        strDt: api_str_dt,
        endDt: api_end_dt,
        area: api_type,
        boardCategory: req_boardcategory,
        lastBoardCd: last_cd,
        searchText: api_search_text,
      });
      if (response.data.code === "0000") {
        if (response.data.boardList.length > 0) {
          setBoardList((prevPushList) => [...prevPushList, ...response.data.boardList]);
          set_last_cd(response.data.boardList[response.data.boardList.length - 1].boardCd);
        } else {
          setHasMoreData(false);
        }
      } else {
        setBoardList([]);
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
      set_search_text("");
      set_api_search_text("");
    }
  };

  const handleMainListClick = (index, boardType) => {
    let clickedBill;
    if (boardType === "newBoard") {
      clickedBill = boardMainInfo.newBoard[index].boardCd;
    } else if (boardType === "viewsTop") {
      clickedBill = boardMainInfo.viewsTop[index].boardCd;
    }
    setDetailBoardCd(clickedBill);
    setIsCommonBoardDetail(true);
  };

  useEffect(() => {
    handleGetBoardMain();
  }, []);

  useEffect(() => {
    set_order_by("DESC");
    set_str_dt(dt_list[0].str_dt);
    set_end_dt(dt_list[0].end_dt);
    set_search_text("");
    set_type("");
    set_category("");
    set_dt("최근7일");

    set_api_order_by("DESC");
    set_api_str_dt(dt_list[0].str_dt);
    set_api_end_dt(dt_list[0].end_dt);
    set_api_search_text("");
    set_api_type("");
    set_api_category("");

    if (title === "삽니다" || title === "팝니다") {
      set_type_list([]);
      set_category_list([]);
    } else if (title === "지식산업센터" || title === "오피스텔빌딩" || title === "기타") {
      set_type_list(area_list);
      set_category_list(center_list);
    } else if (title === "구인구직") {
      set_type_list(area_list);
      set_category_list(job_list);
    } else if (title === "자유게시판") {
      set_type_list(area_list);
      set_category_list(free_list);
    }
    set_last_cd(0);
  }, [title]);

  useEffect(() => {
    let isMounted = true;
    if (!isEnrollBoard) {
      if (isMounted) {
        set_last_cd(0);
      }
    }
    handleGetBoardMain();
    return () => {
      isMounted = false;
    };
  }, [isEnrollBoard]);

  useEffect(() => {
    let isMounted = true;
    if (!isCommonBoardDetail) {
      if (isMounted) {
        set_last_cd(0);
      }
    }
    handleGetBoardMain();
    return () => {
      isMounted = false;
    };
  }, [isCommonBoardDetail]);

  useEffect(() => {
    if (last_cd === 0) {
      setBoardList([]);
      setHasMoreData(true);
      set_last_cd("");
    } else if (last_cd === "") {
      handleGetBoardList();
    }
  }, [last_cd]);

  useEffect(() => {
    if (inView && !is_loading && hasMoreData) {
      handleGetBoardList();
    }
  }, [inView]);

  useEffect(() => {
    if (receivedState) {
      setTitle(receivedState.category);
    }
  }, [receivedState]);

  const renderItems = () => {
    let result;
    if (isEnrollBoard) {
      result = (
        <EnrollBoard
          boardNm={boardNm}
          boardType={boardType}
          setBoardType={setBoardType}
          boardListDetail={boardListDetail}
          checkEdit={checkEdit}
          setCheckEdit={setCheckEdit}
          setIsEnrollBoard={setIsEnrollBoard}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          detailBoardCd={detailBoardCd}
          set_is_loading={set_is_loading}
        />
      );
    } else if (isCommonBoardDetail) {
      result = (
        <CommonBoardDetail
          boardListDetail={boardListDetail}
          setBoardListDetail={setBoardListDetail}
          detailBoardCd={detailBoardCd}
          setIsCommonBoardDetail={setIsCommonBoardDetail}
          setIsEnrollBoard={setIsEnrollBoard}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          setCheckEdit={setCheckEdit}
          set_is_loading={set_is_loading}
          boardType={boardType}
        />
      );
    } else if (title === "삽니다" || title === "팝니다") {
      result = (
        <SellBuy
          receivedState={receivedState}
          title={title}
          setTitle={setTitle}
          boardNm={boardNm}
          setBoardNm={setBoardNm}
          boardType={boardType}
          setBoardType={setBoardType}
          boardList={boardList}
          setDetailBoardCd={setDetailBoardCd}
          setIsEnrollBoard={setIsEnrollBoard}
          setIsCommonBoardDetail={setIsCommonBoardDetail}
          setCheckEdit={setCheckEdit}
          set_show_bottom_sheet={set_show_bottom_sheet}
          set_show_search_box={set_show_search_box}
          inViewRef={inViewRef}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          dt={dt}
          order_by={order_by}
          is_loading={is_loading}
          set_is_loading={set_is_loading}
        />
      );
    } else if (
      title === "지식산업센터" ||
      title === "오피스텔빌딩" ||
      title === "구인구직" ||
      title === "자유게시판" ||
      title === "기타"
    ) {
      result = (
        <CommonBoard
          receivedState={receivedState}
          title={title}
          setTitle={setTitle}
          boardNm={boardNm}
          setBoardNm={setBoardNm}
          boardType={boardType}
          setBoardType={setBoardType}
          boardList={boardList}
          setDetailBoardCd={setDetailBoardCd}
          setIsEnrollBoard={setIsEnrollBoard}
          setIsCommonBoardDetail={setIsCommonBoardDetail}
          setCheckEdit={setCheckEdit}
          set_show_bottom_sheet={set_show_bottom_sheet}
          set_show_search_box={set_show_search_box}
          inViewRef={inViewRef}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          dt={dt}
          order_by={order_by}
          type={type}
          category={category}
          is_loading={is_loading}
          set_is_loading={set_is_loading}
        />
      );
    } else if (isMyBoard) {
      result = (
        <MyBoard
          boardNm={boardNm}
          setBoardNm={setBoardNm}
          boardType={boardType}
          setDetailBoardCd={setDetailBoardCd}
          setIsMyBoard={setIsMyBoard}
          setIsCommonBoardDetail={setIsCommonBoardDetail}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          is_loading={is_loading}
          set_is_loading={set_is_loading}
        />
      );
    } else {
      result = (
        <CommunityView
          setBoardType={setBoardType}
          boardMainInfo={boardMainInfo}
          setIsMyBoard={setIsMyBoard}
          handleMainListClick={handleMainListClick}
          setTitle={setTitle}
        />
      );
    }

    return result;
  };

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      {is_loading && <Loading />}
      {show_search_box && (
        <SearchBox
          search_text={search_text}
          set_search_text={set_search_text}
          set_show_search_box={set_show_search_box}
          research={research}
        />
      )}
      {show_bottom_sheet && (
        <BottomSheet
          set_show_bottom_sheet={set_show_bottom_sheet}
          order_by_list={order_by_list}
          order_by={order_by}
          set_order_by={set_order_by}
          dt_list={dt_list}
          dt={dt}
          set_dt={set_dt}
          str_dt={str_dt}
          set_str_dt={set_str_dt}
          end_dt={end_dt}
          set_end_dt={set_end_dt}
          type_name={type_name}
          type_list={type_list}
          type={type}
          set_type={set_type}
          category_name={category_name}
          category_list={category_list}
          category={category}
          set_category={set_category}
          research={research}
          boardType={boardType}
          title={title}
        />
      )}
      {renderItems()}
    </React.Fragment>
  );
}
