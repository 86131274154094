import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AlertModal from "components/ui/modal/AlertModal";
import BillDetail from "components/ui/bill/BillDetail";
import BillView from "components/ui/bill/BillView";
import CardCheckPayment from "components/ui/payment/CardCheckPayment";
import CompleteOrNotPayment from "components/ui/payment/CompleteOrNotPayment";
import InvoiceMonths from "components/ui/bill/InvoiceMonths";
import Loading from "components/active/Loading";
import SummaryPayment from "components/ui/payment/SummaryPayment";
import { useInView } from "react-intersection-observer";

export default function Bill() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userList, setUserList] = useState([]);
  const [billList, setBillList] = useState([]);
  const [lastBillList, setLastBillList] = useState([]);
  const [billDetailList, setBillDetailList] = useState([]);
  const [averageBillList, setAverageBillList] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);
  const [isBillDetail, setIsBillDetail] = useState(false);
  const [isInvociceMonths, setIsInvociceMonths] = useState(false);
  const [isSummaryPayment, setIsSummaryPayment] = useState(false);
  const [isCardCheckPayment, setIsCardCheckPayment] = useState(false);
  const [isCompleteOrNotPayment, setIsCompleteOrNotPayment] = useState(false);
  const [twoWayGoBack, setTwoWayGoBack] = useState(false);
  const [frominVoiceMonths, setFrominVoiceMonths] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [selectedLastBill, setSelectedLastBill] = useState(null);
  const [selectedAverageBill, setSelectedAverageBill] = useState({});
  const [billInfoChange, setBillInfoChange] = useState("");
  const [fristOpen, setFristOpen] = useState(true);
  const [paymentOpen, setPaymentOpen] = useState(false);

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [is_loading, set_is_loading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [inViewRef, inView] = useInView();

  //사용자회원목록 api
  const handleUserInfo = async () => {
    try {
      const response = await request(PATH.사용자회원목록, {});
      if (response.data.code === "0000") {
        setUserList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 청구목록조회 api 호출
  const handleGetBillList = async () => {
    if (is_loading || !hasMoreData) return;
    set_is_loading(true);
    try {
      const lastBillMonth = billList.length > 0 ? billList[billList.length - 1].billMonth : "";
      const response = await request(PATH.청구목록조회, { lastBillMonth: lastBillMonth });
      if (response.data.code === "0000") {
        setBillList((prevPushList) => [...prevPushList, ...response.data.billList]);
        setLastBillList((prevPushList) => [...prevPushList, ...response.data.billList]);
      } else {
        setHasMoreData(false);
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  // 청구상세조회 api 호출
  const handleGetBillDetail = async () => {
    try {
      set_is_loading(true);
      const response = await request(PATH.청구상세조회, { billMonth: selectedBill?.billMonth || "" });
      if (response.data.code === "0000") {
        setBillDetailList(response.data.billDetail);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  // 카스평균청구목록조회 api 호출
  const handleGetCasBillList = async () => {
    try {
      set_is_loading(true);
      // console.log(response.data);
      const response = await request(PATH.카스평균청구목록조회, { lastBillMonth: selectedBill?.billMonth || "" });
      if (response.data.code === "0000") {
        setAverageBillList(response.data.casBillList);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  // 카드내역조회 api 호출
  const handleGetCardInfo = async () => {
    try {
      set_is_loading(true);
      const response = await request(PATH.카드내역조회, {});
      if (response.data.code === "0000") {
        setCardInfo(response.data);
      } else {
        console.log("등록된 카드가 없습니다.");
        // console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const handleListClick = (index) => {
    const clickedBill = billList[index];
    setSelectedBill(clickedBill);
    setIsBillDetail(true);
  };

  const handleLastListClick = (index) => {
    const clickedBill = lastBillList[index];
    setSelectedLastBill(clickedBill);
  };

  const handleAverageListClick = (index) => {
    let selectedBillList = billList[index];
    const clickedBill = averageBillList.find((value) => value.billMonth === selectedBillList.billMonth);

    setSelectedAverageBill({ ...clickedBill });
  };

  const pageChange = (page) => {
    if (window.ReactNativeWebView) {
      const message = { navigate: page };
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      navigate("/" + page);
    }
  };

  const handleGoMain = () => {
    pageChange("main");
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    if (inView && !is_loading && hasMoreData) {
      handleGetBillList();
    }
  }, [inView]);

  useEffect(() => {
    handleGetCasBillList();
  }, [selectedAverageBill]);

  useEffect(() => {
    handleGetBillList();
    handleGetBillDetail();
    handleGetCardInfo();
  }, [selectedBill, selectedLastBill, billInfoChange]);

  useEffect(() => {
    if (billList.length > 0 && fristOpen === true) {
      setFristOpen(false);
      // console.log(searchParams.toString());
      if (searchParams.get("page")) {
        const index = searchParams.get("index");
        handleListClick(index);
        handleLastListClick(index + 1);
        handleAverageListClick(index);
        if (searchParams.get("page") === "payment") {
          setPaymentOpen(true);
        }
      }
      if (searchParams.get("message")) {
        setIsAlert(true);
        setModalMsg(searchParams.get("message"));
      }
    }
  }, [billList]);

  useEffect(() => {
    if (paymentOpen) {
      setPaymentOpen(false);
      if (selectedBill.paymentYn === "N") {
        setIsBillDetail(false);
        setIsSummaryPayment(true);
      }
    }
  }, [cardInfo]);

  const renderItems = () => {
    let result;
    if (isBillDetail) {
      result = (
        <BillDetail
          billList={billList}
          averageBillList={averageBillList}
          selectedBill={selectedBill}
          selectedLastBill={selectedLastBill}
          selectedAverageBill={selectedAverageBill}
          billDetailList={billDetailList}
          cardInfo={cardInfo}
          handleListClick={handleListClick}
          handleLastListClick={handleLastListClick}
          handleAverageListClick={handleAverageListClick}
          setIsBillDetail={setIsBillDetail}
          setIsInvociceMonths={setIsInvociceMonths}
          setIsSummaryPayment={setIsSummaryPayment}
        />
      );
    } else if (isInvociceMonths) {
      result = (
        <InvoiceMonths
          userList={userList}
          billList={billList}
          twoWayGoBack={twoWayGoBack}
          setTwoWayGoBack={setTwoWayGoBack}
          frominVoiceMonths={frominVoiceMonths}
          setFrominVoiceMonths={setFrominVoiceMonths}
          selectedBill={selectedBill}
          billDetailList={billDetailList}
          setIsBillDetail={setIsBillDetail}
          setIsInvociceMonths={setIsInvociceMonths}
          setIsSummaryPayment={setIsSummaryPayment}
          handleListClick={handleListClick}
        />
      );
    } else if (isSummaryPayment) {
      result = (
        <SummaryPayment
          userList={userList}
          selectedBill={selectedBill}
          billDetailList={billDetailList}
          setTwoWayGoBack={setTwoWayGoBack}
          setIsBillDetail={setIsBillDetail}
          setIsInvociceMonths={setIsInvociceMonths}
          setIsSummaryPayment={setIsSummaryPayment}
          setIsCardCheckPayment={setIsCardCheckPayment}
        />
      );
    } else if (isCardCheckPayment) {
      result = (
        <CardCheckPayment
          cardInfo={cardInfo}
          selectedBill={selectedBill}
          setIsSummaryPayment={setIsSummaryPayment}
          setIsCardCheckPayment={setIsCardCheckPayment}
          setIsCompleteOrNotPayment={setIsCompleteOrNotPayment}
          setBillInfoChange={setBillInfoChange}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
        />
      );
    } else if (isCompleteOrNotPayment) {
      result = (
        <CompleteOrNotPayment
          handleGoMain={handleGoMain}
          cardInfo={cardInfo}
          selectedBill={selectedBill}
          setIsBillDetail={setIsBillDetail}
          setIsSummaryPayment={setIsSummaryPayment}
          setIsCardCheckPayment={setIsCardCheckPayment}
          setIsCompleteOrNotPayment={setIsCompleteOrNotPayment}
          billInfoChange={billInfoChange}
        />
      );
    } else {
      result = (
        <BillView
          billList={billList}
          handleListClick={handleListClick}
          handleLastListClick={handleLastListClick}
          handleAverageListClick={handleAverageListClick}
          inViewRef={inViewRef}
          is_loading={is_loading}
        />
      );
    }

    return result;
  };

  return (
    <React.Fragment>
      {isAlert && (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            navigate("/bill");
          }}
        />
      )}
      {is_loading && <Loading />}
      {renderItems()}
    </React.Fragment>
  );
}
