import { PATH, request } from "components/shared/Api";
import React, { useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import Header from "components/ui/common/Header";
import { cdNoFomatter } from "utils/Common";
import { numberFormat } from "utils/fmt";

const CardCheckPayment = ({
  cardInfo,
  selectedBill,
  setIsSummaryPayment,
  setIsCardCheckPayment,
  setIsCompleteOrNotPayment,
  setBillInfoChange,
  setIsAlert,
  setModalMsg,
}) => {
  const [is_loading, set_is_loading] = useState(false);

  // 카드결제 api 호출
  const handlePayMent = async () => {
    if (is_loading) {
    } else {
      try {
        set_is_loading(true);
        const response = await request(PATH.카드결제, { billMonth: selectedBill?.billMonth || "" });
        if (response.data.code !== "0000") {
          //여기는 예외처리를 알럿창 대신 페이지로 처리 (기획서 참조)
          setIsAlert(true);
          setModalMsg(response.data.message);
          setIsCardCheckPayment(false);
          setBillInfoChange("PayFailed");
          setIsCompleteOrNotPayment(true);
        } else if (response.data.code === "0000") {
          setIsCardCheckPayment(false);
          setBillInfoChange("PayComplete");
          setIsCompleteOrNotPayment(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        set_is_loading(false);
      }
    }
  };

  const handleGoBack = () => {
    setIsCardCheckPayment(false);
    setBillInfoChange("PayReCount");
    setIsSummaryPayment(true);
  };

  return (
    <React.Fragment>
      <div className="wrapper payment-detail">
        <Header Title="관리비 결제" handleGoBack={handleGoBack} />
        <div className="content">
          <div className="title-box mt60 mb44">
            <span className="img-payment"></span>
            <h2>
              <strong className="main-color">{numberFormat(selectedBill.billSum)}</strong>원을
              <br />
              카드로 결제합니다.
            </h2>
          </div>

          <div className="card-box-wrap">
            <h3 className="type2 mb18">결제수단</h3>
            <div className="card-box round-box type3 mb20">
              <div className="card-info-box wh-color">
                <p className="com">{cardInfo.cardNm}</p>
                <p className="num">{cdNoFomatter(cardInfo.cardNo)}</p>
                <p className="expi-date">
                  <span className="month">{cardInfo.cardValidityMM}</span>/
                  <span className="day">{cardInfo.cardValidityYY}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button type="button" className="btn1 main-color" onClick={handlePayMent}>
              결제하기
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardCheckPayment;
