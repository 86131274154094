import React from "react";

const DateBottomModal = ({
  billList,
  handleListClick,
  handleLastListClick,
  handleAverageListClick,
  closeModal,
  setIsBillDetail,
  frominVoiceMonths,
  setIsInvociceMonths,
}) => {
  return (
    <div className="bottom-sheet-modal type1">
      <div className="box">
        <div className="tit-box justify-between">
          <h2 className="type2">년월 선택</h2>
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>

        <div className="con-box">
          <ul className="mod-list-box">
            {billList.map((v, i) => (
              <li key={i}>
                <button
                  type="button"
                  onClick={() => {
                    if (!frominVoiceMonths) {
                      handleListClick(i);
                      handleLastListClick(i + 1);
                      handleAverageListClick(i);
                      closeModal(false);
                    } else {
                      handleListClick(i);
                      closeModal(false);
                      setIsBillDetail(false);
                      setIsInvociceMonths(true);
                    }
                  }}
                >
                  {v.billMonth.substring(0, 4)}년 {v.billMonth.substring(4)}월
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DateBottomModal;
