// @ts-nocheck

//비밀번호 유효성 체크
export const isValidPassword = (password) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;
  return regex.test(password);
};

export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return Math.round(n * k) / k;
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

const DIGITS = "0123456789ABCDEF";
export const getRandomColor = () => {
  let result = "";
  for (let i = 0; i < 6; ++i) {
    const index = Math.floor(16 * Math.random());
    result += DIGITS[index];
  }
  return "#" + result;
};

export const validationCheckBizNo = (number) => {
  let numberMap = number
    .replace(/-/gi, "")
    .split("")
    .map(function (d) {
      return parseInt(d, 10);
    });

  // if (number.substr(3, 1) < 80) {
  if (numberMap.length === 10) {
    let keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;

    keyArr.forEach(function (d, i) {
      chk += d * numberMap[i];
    });

    chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }
  // }

  return false;
};

export const fixedFuc = (bool) => {
  if (!bool) {
    document.body.style.cssText = `position:fixed; height:100%;width:100%;  overflow:hidden; touch-action: none; -webkit-overflow-scrolling: none; overscroll-behavior: none; -webkit-backface-visibility:hidden;`;
    console.log("document.body.children[0]", document.body.children[0]);
    document.body.children[0].style.cssText = `position:fixed; height:100%;width:100%;  overflow:hidden; touch-action: none; -webkit-overflow-scrolling: none; overscroll-behavior: none; -webkit-backface-visibility:hidden;`;
  } else {
    document.body.removeAttribute("style", "");
    document.body.children[0].removeAttribute("style", "");
  }
};

export const salesPaddingFixed = (bool) => {
  let visualBox = document.getElementsByClassName("fsaupX")[0];
  if (!bool) {
    visualBox.style.cssText = "padding: 3.2rem 1.6rem 2rem";
  } else {
    visualBox.style.cssText = "padding: 0";
  }
};

export const topClick = (e) => {
  e.target.offsetParent.children[0].children[1].scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export const serviceBtn = () => {};

/**
 * 사업자번호 10자리 문자열을 받아 -를 붙여서 반환한다.
 * bsnnNo 사업자번호
 */
export const formatBsnnNo = (bsnnNo) => {
  if (typeof bsnnNo !== "string" && String(bsnnNo).length !== 10) {
    return "";
  }
  return `${bsnnNo.substr(0, 3)}-${bsnnNo.substr(3, 2)}-${bsnnNo.substr(5, 5)}`;
};

/**
 * 휴대전화번호 11자리 문자열을 받아 -를 붙여서 반환한다.
 * mobileNo 휴대전화번호
 */
export const formatMobileNo = (mobileNo) => {
  if (typeof mobileNo !== "string" && String(mobileNo).length !== 11) {
    return mobileNo + "";
  }
  return `${mobileNo.substr(0, 3)}-${mobileNo.substr(3, 4)}-${mobileNo.substr(7, 4)}`;
};

/**
 * 휴대전화번호 11자리 문자열을 받아 -를 붙여서 반환한다.
 * mobileNo 휴대전화번호시크릿
 */
export const formatMobileNoSecret = (mobileNo) => {
  if (typeof mobileNo !== "string" && String(mobileNo).length !== 11) {
    return mobileNo + "";
  }
  return `${mobileNo.substr(0, 3)}-****-${mobileNo.substr(7, 4)}`;
};

/**
 * 전화번호를 받아  -를 붙여서 반환한다.
 * telNo 전화번호
 */
export const formatTel = (telNo) => {
  let formatText = "";
  if (telNo == null) return "";
  switch (String(telNo).length) {
    case 8:
      formatText = telNo.substr(0, 4) + "-" + telNo.substr(4, 4);
      break;
    case 9:
      formatText = telNo.substr(0, 2) + "-" + telNo.substr(2, 3) + "-" + telNo.substr(5, 4);
      break;
    case 10:
      if (telNo.substr(0, 2) === "02") {
        formatText = telNo.substr(0, 2) + "-" + telNo.substr(2, 4) + "-" + telNo.substr(6, 4);
      } else {
        formatText = telNo.substr(0, 3) + "-" + telNo.substr(3, 3) + "-" + telNo.substr(6, 4);
      }
      break;
    case 11:
      formatText = telNo.substr(0, 3) + "-" + telNo.substr(3, 4) + "-" + telNo.substr(7, 4);
      break;
    default:
      formatText = telNo;
  }
  return formatText;
};

/**
 * 배열과 밸류값을 받아서 해당 밸류값이 같으면 이름을 반환
 * arr, value
 */
export const filterValue = (arr, targetValue) => {
  let result = "";
  if (arr.length === 0) return;
  if (targetValue === undefined || targetValue === null || targetValue === "") return;

  result = arr.filter((item) => item.value === targetValue);

  return result[0].name;
};

export const formatCardNumber = (cardNumber, sep) => {
  if (cardNumber) {
    let separator = sep ? sep : "-";
    let tmp = `${cardNumber.substr(0, 4)}${separator}${cardNumber.substr(4, 4)}${separator}${cardNumber.substr(
      8,
      4
    )}${separator}${cardNumber.substr(12, 4)}`;
    cardNumber = tmp.replace(/(\d{4})-(\d{4})-(\d{4})-(\d{4})/, "$1-$2-$3-$4");
  }

  return cardNumber;
};

//생년월일 유효성 체크
export const isValidBirth = (birth) => {
  const regex = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/;
  return regex.test(birth);
};
