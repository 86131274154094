import React from "react";

const SearchBox = ({ search_text, set_search_text, set_show_search_box, research }) => {
  return (
    <div className="search-box-wrap">
      <div className="search-box">
        <div className="sch-btn-box">
          <button
            type="button"
            className="back-btn"
            onClick={() => {
              set_show_search_box(false);
            }}
          />
        </div>

        <div className="flex-box items-center">
          <input
            type="text"
            placeholder="검색"
            value={search_text}
            onChange={(e) => set_search_text(e.target.value)}
            maxLength={40}
          />
          {search_text !== "" ? (
            <button
              type="button"
              className="remove-btn"
              onClick={() => {
                set_search_text("");
              }}
            />
          ) : null}
        </div>

        <div className="sch-btn-box">
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              research();
            }}
          >
            검색
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
