import "swiper/css";
import "swiper/css/pagination";

import { PATH, request } from "components/shared/Api";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import BoardBottomModal from "components/ui/modal/BoardBottomModal";
import DelBoardModal from "components/ui/modal/DelBoardModal";
import Header from "components/ui/common/Header";
import { Pagination } from "swiper/modules";
import SelectBottomSheet from "components/ui/common/SelectBottomSheet";
import { numberFormat } from "utils/fmt";
import profile from "assets/images/icon/icon_user_profile.svg";

const CommonBoardDetail = ({
  boardListDetail,
  setBoardListDetail,
  detailBoardCd,
  setIsCommonBoardDetail,
  setIsEnrollBoard,
  setModalMsg,
  setIsAlert,
  setCheckEdit,
  set_is_loading,
  boardType,
}) => {
  const [clickNestReply, setClickNestReply] = useState([]);
  const [isEditBottomSheet, setIsEditBottomSheet] = useState(false);
  const [isRegBottomSheet, setIsRegBottomSheet] = useState(false);
  const [isdelModal, setIsDelModal] = useState(false);
  const [clickedNestReplyIndex, setClickedNestReplyIndex] = useState(null);
  const [clickEdit, setClickEdit] = useState("등록");
  const [refSeq, setRefSeq] = useState("");
  const [refStep, setRefStep] = useState("");
  const [refLevel, setRefLevel] = useState("");
  const [replyContent, setReplyContent] = useState("");
  const [nestReplyContent, setNestReplyContent] = useState("");
  const [propsIndex, setPropsIndex] = useState("");
  const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));

  const [show_accusation, set_show_accusation] = useState("");
  const [accusation_reply, set_accusation_reply] = useState("");

  //게시판상세내역 api
  const regBoardAccusation = async () => {
    try {
      const response = await request(PATH.regBoardAccusation, {
        cd: detailBoardCd,
        reply: accusation_reply,
      });

      console.log(response.data);
      if (response.data.code === "0000") {
        setIsAlert(true);
        setModalMsg("신고가 완료되었습니다.");
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const accusation_list = [
    {
      name: "신고",
      value: () => {
        regBoardAccusation();
        set_show_accusation(false);
      },
    },
  ];

  //게시판상세내역 api
  const handleGetBoardDetail = async () => {
    set_is_loading(true);
    try {
      const response = await request(PATH.게시판상세내역, {
        boardCd: detailBoardCd,
      });
      if (response.data.code === "0000") {
        const { boardDetail, boardPic, boardReply } = response.data;
        setBoardListDetail({
          boardDetail: boardDetail[0],
          boardPic: [...boardPic],
          boardReply: [...boardReply],
        });
        setIsCommonBoardDetail(true);
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
        setIsCommonBoardDetail(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  //리플추가 api
  const handleRegRef = async (index) => {
    try {
      const trimmedContent = replyContent.trim();
      const trimmedContent2 = nestReplyContent.trim();
      if ((replyContent && trimmedContent === "") || (replyContent && replyContent === "")) {
        setIsAlert(true);
        setModalMsg("댓글을 입력해 주세요.");
        return;
      } else if ((nestReplyContent && trimmedContent2 === "") || (nestReplyContent && nestReplyContent === "")) {
        setIsAlert(true);
        setModalMsg("답글을 입력해 주세요.");
        return;
      }
      const response = await request(PATH.리플추가, {
        boardCd: detailBoardCd,
        refSeq: index === undefined ? "" : refSeq,
        refStep: index === undefined ? "" : refStep,
        refLevel: index === undefined ? "" : refLevel,
        replyContent: replyContent.length > 0 ? replyContent : nestReplyContent,
      });

      setClickNestReply((prevClickNestReply) => {
        const updatedArray = [...prevClickNestReply];
        updatedArray[index] = false;
        return updatedArray;
      });
      if (response.data.code === "0000") {
        setIsAlert(true);
        setModalMsg(response.data.message);
        handleGetBoardDetail();
      } else {
        setIsAlert(true);
        if (response.data.code === "B900") {
          setModalMsg("댓글을 입력해 주세요.");
        } else {
          setModalMsg(response.data.message);
        }
        handleToggleNestReply(propsIndex);
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleReset();
    }
  };

  //리플수정 api
  const handleUpdateRef = async (index) => {
    try {
      const trimmedContent = replyContent.trim();
      const trimmedContent2 = nestReplyContent.trim();
      if ((replyContent && trimmedContent === "") || (replyContent && replyContent === "")) {
        setIsAlert(true);
        setModalMsg("댓글을 입력해 주세요.");
        return;
      } else if ((nestReplyContent && trimmedContent2 === "") || (nestReplyContent && nestReplyContent === "")) {
        setIsAlert(true);
        setModalMsg("답글을 입력해 주세요.");
        return;
      }
      const response = await request(PATH.리플수정, {
        boardCd: detailBoardCd,
        refSeq: refSeq,
        refStep: refStep,
        refLevel: refLevel,
        replyContent: nestReplyContent,
      });
      setClickNestReply((prevClickNestReply) => {
        const updatedArray = [...prevClickNestReply];
        updatedArray[index] = false;
        return updatedArray;
      });
      if (response.data.code === "0000") {
        setIsAlert(true);
        setModalMsg(response.data.message);
        handleGetBoardDetail();
      } else {
        setIsAlert(true);
        setModalMsg(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleReset();
    }
  };

  const handleToggleNestReply = (index) => {
    setClickNestReply((prevClickNestReply) => {
      const updatedArray = [...prevClickNestReply];
      if (clickedNestReplyIndex !== null && clickedNestReplyIndex !== index) {
        updatedArray[clickedNestReplyIndex] = false;
        setNestReplyContent("");
      }
      updatedArray[index] = !updatedArray[index];
      setClickedNestReplyIndex(updatedArray[index] ? index : null);
      return updatedArray;
    });
  };

  const handleGoBack = () => {
    setIsCommonBoardDetail(false);
  };

  const handleReset = () => {
    setRefSeq("");
    setRefStep("");
    setRefLevel("");
    setReplyContent("");
    setNestReplyContent("");
    setClickEdit("등록");
  };

  useLayoutEffect(() => {
    handleGetBoardDetail();
    return () => {
      setBoardListDetail({});
    };
  }, []);

  useEffect(() => {
    if (clickEdit && clickEdit === "수정") {
      setNestReplyContent(boardListDetail.boardReply[propsIndex].replyContent);
    }
  }, [clickEdit]);

  return (
    <React.Fragment>
      {isdelModal && (
        <DelBoardModal
          closeModal={setIsDelModal}
          detailBoardCd={detailBoardCd}
          handleGoBack={handleGoBack}
          refLevel={refLevel}
          refSeq={refSeq}
          refStep={refStep}
          handleGetBoardDetail={handleGetBoardDetail}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
        />
      )}
      {isEditBottomSheet && (
        <BoardBottomModal
          closeModal={setIsEditBottomSheet}
          setIsEnrollBoard={setIsEnrollBoard}
          setCheckEdit={setCheckEdit}
          setIsDelModal={setIsDelModal}
          isEditBottomSheet={isEditBottomSheet}
          boardListDetail={boardListDetail}
        />
      )}
      {isRegBottomSheet && (
        <BoardBottomModal
          closeModal={setIsRegBottomSheet}
          setIsEnrollBoard={setIsEnrollBoard}
          setCheckEdit={setCheckEdit}
          setIsDelModal={setIsDelModal}
          isRegBottomSheet={isRegBottomSheet}
          setClickEdit={setClickEdit}
          propsIndex={propsIndex}
          boardListDetail={boardListDetail}
          setBoardListDetail={setBoardListDetail}
          refLevel={refLevel}
        />
      )}

      {show_accusation && (
        <SelectBottomSheet
          title={"게시글 신고"}
          set_is_bottomsheet={set_show_accusation}
          bottomsheet_list={accusation_list}
        />
      )}

      {boardListDetail.boardDetail && (
        <div className="wrapper community">
          <Header Title={boardListDetail.boardDetail.boardNm} handleGoBack={handleGoBack} />
          <div className="content p0 pb120">
            <div className="community-detail">
              <div className="detail-img-box">
                <div className="mb25">
                  {boardListDetail.boardPic.length > 0 ? (
                    <Swiper
                      spaceBetween={10}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {boardListDetail.boardPic.map((boardPic_map, i) => (
                        <SwiperSlide key={`boardPic_map_` + i}>
                          <img src={boardPic_map.pic_url} alt="이미지" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : null}
                </div>

                <div className="post-box user-box">
                  <div className="user mb20">
                    {boardListDetail.boardDetail.userPic === "" ? (
                      <img src={profile} alt="유저 이미지" />
                    ) : (
                      <img src={boardListDetail.boardDetail.userPic} alt="유저 이미지" />
                    )}
                    <span>{boardListDetail.boardDetail.userNm}</span>
                  </div>
                  <p className="txt1">{boardListDetail.boardDetail.boardTitle}</p>
                  <div className="justify-between">
                    <p className="txt2">
                      {boardListDetail.boardDetail.regDtm.substring(0, 4)}.
                      {boardListDetail.boardDetail.regDtm.substring(5, 7)}.
                      {boardListDetail.boardDetail.regDtm.substring(8, 10)}{" "}
                      {boardListDetail.boardDetail.regDtm.substring(11, 16)}
                    </p>
                    {userInfoFromStorage.userId === boardListDetail.boardDetail.userId ? (
                      <button
                        type="button"
                        className="edit-btn"
                        onClick={() => {
                          setCheckEdit(true);
                          setIsEditBottomSheet(true);
                        }}
                      />
                    ) : (
                      <button
                        type="button"
                        className="edit-btn"
                        onClick={() => {
                          set_accusation_reply("-");
                          set_show_accusation(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="detail-desc-box">
                <div className="desc-box">
                  {boardListDetail.boardDetail.boardNm === "팝니다" && (
                    <strong className="price">{numberFormat(boardListDetail.boardDetail.boardAmount)}원</strong>
                  )}
                  <p>{boardListDetail.boardDetail.boardContent}</p>
                  <ul className="num-box type2 mt20">
                    <li>
                      <i className="ico-view"></i>
                      {boardListDetail.boardDetail.viewCount}
                    </li>
                    <li>
                      <i className="ico-comment"></i>
                      {boardListDetail.boardDetail.refCount}
                    </li>
                  </ul>
                </div>

                <div className="regit-box mt40">
                  <label htmlFor="comm">댓글쓰기({userInfoFromStorage.nickName})</label>
                  <textarea
                    id="comm"
                    placeholder="댓글을 입력해 주세요.(100자내)"
                    value={replyContent}
                    onChange={(e) => {
                      setReplyContent(e.target.value);
                    }}
                    maxLength={100}
                  />
                  <div className="btn-box">
                    <button
                      type="button"
                      onClick={() => {
                        handleRegRef();
                      }}
                    >
                      등록
                    </button>
                  </div>
                </div>
              </div>
              <div className="comment-box-wrap">
                <div className="comment-top-box">
                  <i className="ico-comment2"></i>
                  <span>댓글 {boardListDetail.boardDetail.refCount}</span>
                </div>
              </div>
              {/* 댓글 박스 */}
              {boardListDetail.boardReply.map((reply, index) => {
                if (reply.refLevel === 0) {
                  return (
                    <div className="comment-box-wrap" key={index}>
                      <div className="comment-box user-box">
                        <div className="justify-between mb20">
                          <div className="user">
                            {boardListDetail.boardReply[index].userPic === "" ? (
                              <img src={profile} alt="유저 이미지" />
                            ) : (
                              <img src={boardListDetail.boardReply[index].userPic} alt="유저 이미지" />
                            )}
                            <span>{reply.userNm}</span>
                          </div>
                          {userInfoFromStorage.userId === boardListDetail.boardReply[index].regId ? (
                            <button
                              type="button"
                              className="edit-btn"
                              onClick={() => {
                                setRefSeq(String(reply.refSeq));
                                setRefStep(String(reply.refStep));
                                setRefLevel(String(reply.refLevel));
                                setPropsIndex(index);
                                setNestReplyContent("");
                                setIsRegBottomSheet(true);
                              }}
                            />
                          ) : (
                            <button
                              type="button"
                              className="edit-btn"
                              onClick={() => {
                                set_accusation_reply(String(reply.refSeq) + "/" + String(reply.refStep));
                                set_show_accusation(true);
                              }}
                            />
                          )}
                        </div>
                        <p className="txt1">{reply.replyContent}</p>
                        {reply.write && reply.write === "true" && clickEdit === "수정" ? (
                          <p className="txt2 mt14 mb18">수정 중...</p>
                        ) : null}
                        <div className="justify-between mb20">
                          <p className="txt2">
                            {reply.regDtm.substring(0, 4)}.{reply.regDtm.substring(5, 7)}.
                            {reply.regDtm.substring(8, 10)} {reply.regDtm.substring(11, 16)}
                          </p>
                        </div>
                        <button
                          type="button"
                          className="reply-btn"
                          onClick={() => {
                            setRefSeq(reply.refSeq);
                            setRefStep(reply.refStep);
                            setRefLevel(reply.refLevel);
                            handleToggleNestReply(index);
                            setClickEdit("등록");
                            let new_boardListDetail = boardListDetail;
                            new_boardListDetail.boardReply[index].write = "true";
                            setBoardListDetail({
                              ...new_boardListDetail,
                            });
                            setNestReplyContent("");
                          }}
                        >
                          답글
                        </button>

                        {reply.write && reply.write === "true" && (
                          <div className="regit-box mt10">
                            <textarea
                              placeholder="답글을 입력해 주세요.(100자내)"
                              value={nestReplyContent}
                              onChange={(e) => {
                                setNestReplyContent(e.target.value);
                              }}
                              maxLength={100}
                            />
                            <div className="btn-box type2 items-center">
                              <button
                                type="button"
                                onClick={() => {
                                  setClickEdit("등록");
                                  handleToggleNestReply(index);
                                  let new_boardListDetail = boardListDetail;
                                  new_boardListDetail.boardReply[index].write = "false";
                                  setBoardListDetail({
                                    ...new_boardListDetail,
                                  });
                                }}
                              >
                                취소
                              </button>
                              <button
                                type="button"
                                className="main"
                                onClick={() => {
                                  if (clickEdit === "등록") {
                                    if (nestReplyContent !== "") {
                                      handleRegRef(index);
                                    } else {
                                      setIsAlert(true);
                                      setModalMsg("답글을 입력 해주세요.");
                                    }
                                  } else {
                                    if (nestReplyContent !== "") {
                                      handleUpdateRef(index);
                                    } else {
                                      setIsAlert(true);
                                      setModalMsg("답글을 입력 해주세요.");
                                    }
                                  }
                                }}
                              >
                                등록
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                } else if (reply.refLevel === 1) {
                  return (
                    <div className="reply-box" key={index}>
                      <div className="flex-box">
                        <div className="left-box">
                          <div className="icon-box">
                            <i className="icon-reply"></i>
                          </div>
                          <div className="user-box">
                            <div className="user mb20">
                              {boardListDetail.boardReply[index].userPic === "" ? (
                                <img src={profile} alt="유저 이미지" />
                              ) : (
                                <img src={boardListDetail.boardReply[index].userPic} alt="유저 이미지" />
                              )}
                              <span>{reply.userNm}</span>
                            </div>
                            <p className="txt1">{reply.replyContent}</p>
                            {reply.write && reply.write === "true" ? <p className="txt2 mt20">수정 중...</p> : null}
                          </div>
                        </div>
                        {userInfoFromStorage.userId === reply.regId ? (
                          <button
                            type="button"
                            className="edit-btn"
                            onClick={() => {
                              setRefSeq(reply.refSeq);
                              setRefStep(reply.refStep);
                              setRefLevel(reply.refLevel);
                              setPropsIndex(index);
                              setIsRegBottomSheet(true);
                            }}
                          />
                        ) : null}
                      </div>
                      {reply.write && reply.write === "true" ? (
                        <div className="regit-box mt10">
                          <textarea
                            placeholder="답글을 입력해 주세요.(100자내)"
                            value={nestReplyContent}
                            onChange={(e) => {
                              setNestReplyContent(e.target.value);
                            }}
                            maxLength={100}
                          />
                          <div className="btn-box type2 items-center">
                            <button
                              type="button"
                              onClick={() => {
                                setClickEdit("등록");
                                let new_boardListDetail = boardListDetail;
                                new_boardListDetail.boardReply[index].write = "false";
                                setBoardListDetail({ ...new_boardListDetail });
                              }}
                            >
                              취소
                            </button>
                            <button
                              type="button"
                              className="main"
                              onClick={() => {
                                if (nestReplyContent !== "") {
                                  handleUpdateRef(index);
                                } else {
                                  setIsAlert(true);
                                  setModalMsg("답글을 입력 해주세요.");
                                }
                              }}
                            >
                              등록
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <div className="fixed-button-box">
            <div className="button-box">
              <button type="button" className="btn1 main-color" onClick={handleGoBack}>
                목록
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CommonBoardDetail;
