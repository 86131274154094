import { PATH, request } from "components/shared/Api";

import React from "react";

const DeleteCardModal = ({ closeModal, setCardInfoChange }) => {
  // 카드내역삭제 api 호출
  const handleDelCardInfo = async () => {
    try {
      const response = await request(PATH.카드내역삭제, {});
      if (response.data.code === "0000") {
        setCardInfoChange("cardDel");
        closeModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="alert-modal">
      <div className="box">
        <div className="top">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              closeModal(false);
            }}
          />
        </div>
        <div className="desc">
          <p>
            등록된 카드를 삭제합니다.
            <br />
            카드 삭제시 신청한 자동 결제 내역도 함께 삭제됩니다.
          </p>
        </div>
        <div className="button-box">
          <button
            type="button"
            className="btn1 sub-color"
            onClick={() => {
              closeModal(false);
            }}
          >
            취소
          </button>
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              closeModal(false);
              handleDelCardInfo();
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCardModal;
