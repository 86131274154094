import React from "react";

const SelectBottomSheet = ({ title, set_is_bottomsheet, bottomsheet_list }) => {
  return (
    <React.Fragment>
      {bottomsheet_list.length > 3 ? (
        <div className="bottom-sheet-modal type1">
          <div className="box">
            <div className="tit-box justify-between">
              <h2 className="type2">{title}</h2>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  set_is_bottomsheet(false);
                }}
              ></button>
            </div>

            <div className="con-box">
              <ul className="mod-list-box">
                {bottomsheet_list.map((bottomsheet_list_map, index) => {
                  return (
                    <li key={`bottomsheet_list_map_${index}`}>
                      <button
                        type="button"
                        onClick={() => {
                          bottomsheet_list_map.value();
                        }}
                      >
                        {bottomsheet_list_map.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : bottomsheet_list.length > 1 ? (
        <div className="bottom-sheet-modal type2">
          <div className="box">
            <div className="tit-box justify-between">
              <h2 className="type2">{title}</h2>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  set_is_bottomsheet(false);
                }}
              ></button>
            </div>

            <div className="con-box">
              <ul className="mod-list-box">
                {bottomsheet_list.map((bottomsheet_list_map, index) => {
                  return (
                    <li key={`bottomsheet_list_map_${index}`}>
                      <button
                        type="button"
                        onClick={() => {
                          bottomsheet_list_map.value();
                        }}
                      >
                        {bottomsheet_list_map.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="bottom-sheet-modal type3">
          <div className="box">
            <div className="tit-box justify-between">
              <h2 className="type2">{title}</h2>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  set_is_bottomsheet(false);
                }}
              ></button>
            </div>

            <div className="con-box">
              <ul className="mod-list-box">
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      bottomsheet_list[0].value();
                    }}
                  >
                    {bottomsheet_list[0].name}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectBottomSheet;
