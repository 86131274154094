import { PATH, request } from "components/shared/Api";
import React, { useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import AutoPayCancel from "components/ui/paymentMethod/AutoPayCancel";
import AutoPayRegistration from "components/ui/paymentMethod/AutoPayRegistration";
import CardEdit from "components/ui/paymentMethod/CardEdit";
import CardRegistration from "components/ui/paymentMethod/CardRegistration";
import Loading from "components/active/Loading";
import PaymentMethodView from "components/ui/paymentMethod/PaymentMethodView";
import RegistrationComplete from "components/ui/paymentMethod/RegistrationComplete";

export default function PaymentMethod() {
  const [userList, setUserList] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);
  const [isCardRegistration, setIsCardRegistration] = useState(false);
  const [isCardRegComplete, setIsCardRegComplete] = useState(false);
  const [isCardEdit, setIsCardEdit] = useState(false);
  const [isAutoPayReg, setIsAutoPayReg] = useState(false);
  const [isAutoPayDel, setIsAutoPayDel] = useState(false);
  const [cardInfoChange, setCardInfoChange] = useState("");

  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [alertOnClickName, setAlertOnClickName] = useState(null);
  const [is_loading, set_is_loading] = useState(false);

  //사용자회원목록 api
  const handleUserInfo = async () => {
    try {
      set_is_loading(true);
      const response = await request(PATH.사용자회원목록, {});
      setUserList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  // 카드내역조회 api 호출
  const handleGetCardInfo = async () => {
    try {
      set_is_loading(true);
      const response = await request(PATH.카드내역조회, {});
      if (response.data.code === "0000") {
        setCardInfo(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set_is_loading(false);
    }
  };

  const handleAutoRegGoBack = () => {
    setIsAutoPayReg(false);
    setCardInfoChange("autoPayReg");
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    handleGetCardInfo();
  }, [cardInfoChange]);

  const renderItems = () => {
    let result;
    if (isCardEdit) {
      result = (
        <CardEdit
          cardInfo={cardInfo}
          setIsCardEdit={setIsCardEdit}
          setCardInfoChange={setCardInfoChange}
          setIsAlert={setIsAlert}
          modalMsg={modalMsg}
          setModalMsg={setModalMsg}
          set_is_loading={set_is_loading}
        />
      );
    } else if (isCardRegistration) {
      result = (
        <CardRegistration
          userList={userList}
          cardInfo={cardInfo}
          setIsCardRegistration={setIsCardRegistration}
          setIsCardRegComplete={setIsCardRegComplete}
          setCardInfoChange={setCardInfoChange}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          set_is_loading={set_is_loading}
        />
      );
    } else if (isCardRegComplete) {
      result = (
        <RegistrationComplete setIsCardRegComplete={setIsCardRegComplete} setCardInfoChange={setCardInfoChange} />
      );
    } else if (isAutoPayReg) {
      result = (
        <AutoPayRegistration
          userList={userList}
          cardInfo={cardInfo}
          setIsAutoPayReg={setIsAutoPayReg}
          setIsAlert={setIsAlert}
          setModalMsg={setModalMsg}
          set_is_loading={set_is_loading}
          setAlertOnClickName={setAlertOnClickName}
        />
      );
    } else if (isAutoPayDel) {
      result = (
        <AutoPayCancel
          userList={userList}
          cardInfo={cardInfo}
          setIsAutoPayDel={setIsAutoPayDel}
          setCardInfoChange={setCardInfoChange}
          set_is_loading={set_is_loading}
        />
      );
    } else {
      result = (
        <PaymentMethodView
          cardInfo={cardInfo}
          setIsCardEdit={setIsCardEdit}
          setIsCardRegistration={setIsCardRegistration}
          setIsAutoPayReg={setIsAutoPayReg}
          setIsAutoPayDel={setIsAutoPayDel}
          setCardInfoChange={setCardInfoChange}
        />
      );
    }

    return result;
  };

  return (
    <React.Fragment>
      {isAlert && (
        <AlertModal
          modalMsg={modalMsg}
          closeModal={setIsAlert}
          onClick={() => {
            if (alertOnClickName === "isAuto") handleAutoRegGoBack();
          }}
        />
      )}
      {is_loading && <Loading />}
      {renderItems()}
    </React.Fragment>
  );
}
