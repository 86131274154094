import CardInfoExists from "components/ui/paymentMethod/CardInfoExists";
import Header from "components/ui/common/Header";
import NoCardInfo from "components/ui/paymentMethod/NoCardInfo";
import React from "react";

const PaymentMethodView = ({
  userList,
  cardInfo,
  setIsCardRegistration,
  setIsCardEdit,
  setIsAutoPayReg,
  setIsAutoPayDel,
  setCardInfoChange,
}) => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <React.Fragment>
      <div className="wrapper payment-method">
        <Header Title="결제 수단 관리" handleGoBack={"main"} />
        {cardInfo.cardNo ? (
          <CardInfoExists
            userInfoFromStorage={userInfoFromStorage}
            userList={userList}
            cardInfo={cardInfo}
            setIsCardEdit={setIsCardEdit}
            setIsAutoPayReg={setIsAutoPayReg}
            setIsAutoPayDel={setIsAutoPayDel}
            setCardInfoChange={setCardInfoChange}
          />
        ) : (
          <NoCardInfo
            userInfoFromStorage={userInfoFromStorage}
            setIsCardRegistration={setIsCardRegistration}
            setCardInfoChange={setCardInfoChange}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default PaymentMethodView;
