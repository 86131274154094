//////// 소셜로그인 ////////
let location = window.location.href;
location = location.substring(0, location.indexOf("/login"));

const NAVER_CLIENT_ID = "MNrtSMca96y17wvtvrzS";
const REDIRECT_URI = location + "/login/naverlogin";
const STATE = "bldCAS";
const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

const KAKAO_KEY = "eff32a6eead77a715aeb8f9dd12905c0";
const KAKAO_REDIRECT_URI = location + "/login/kakaologin";
const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}`;

export const naverLogin = () => {
  window.location.href = NAVER_AUTH_URL;
  // return NAVER_AUTH_URL;
};

export const kakaoLogin = () => {
  window.location.href = KAKAO_AUTH_URL;
};

export const appleLogin = () => {
  const client_id = "com.h-brick.cas";
  // const redirect_uri = location + "/login/applelogin"; // Service IDs 생성할 때 등록한 Return URLs
  let redirect_uri = "https://cas.shcommunication.co.kr/login/applelogin";

  if (window.location.origin === "https://casappdev.shcommunication.co.kr") {
    redirect_uri = "https://casdev.shcommunication.co.kr/login/applelogin"; // Service IDs 생성할 때 등록한 Return URLs
  }

  // response_type (required) → 응답 유형을 선택, "code" or "code id_token"
  // response_mode (required) → 응답 모드를 선택, "query" or "fragment" or "form_post"
  // scope → 로그인을 통해 확인하고 싶은 정보를 선택, "email" or "name" or "email name" (scope를 사용할 경우 response_mode는 form_post가 필수)
  window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&response_mode=form_post&scope=email%20name&client_id=${client_id}&redirect_uri=${redirect_uri}`;
};
//////// 소셜로그인 ////////
