import { useLocation, useNavigate } from "react-router-dom";

import React from "react";

const FindResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { responseItems } = location.state || {};
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}.${month}.${day}`;

  const handleNavigateToFind = () => {
    navigate("/find", { state: { activeTab: "pw", responseItems: responseItems } });
  };

  console.log("responseItems", responseItems);
  return (
    <div className="wrapper find-result">
      <header className="right">
        <h1>로그인 정보 찾기</h1>
        <div className="h-btn-box">
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              navigate("/login");
            }}
          ></button>
        </div>
      </header>

      <div className="content pt60">
        <div className="title-box mb30">
          <h2>
            회원님의 휴대폰 번호로
            <br />
            가입된 아이디가 있습니다.
          </h2>
        </div>

        <div className="round-box type2">
          <p className="txt1">ID</p>
          <strong>{responseItems.id}</strong>
          <p className="txt2">{"본인인증 : " + formattedDate}</p>
        </div>
      </div>

      <div className="fixed-button-box">
        <p className="flex-center gap16 mb14">
          비밀번호가 기억나지 않으세요?
          <button
            type="button"
            onClick={() => {
              handleNavigateToFind();
            }}
          >
            비밀번호 찾기
          </button>
        </p>
        <div className="button-box">
          <button
            type="button"
            className="btn1 main-color"
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인
          </button>
        </div>
      </div>
    </div>
  );
};

export default FindResult;
