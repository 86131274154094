import React, { useContext, useEffect, useState } from "react";

import AlertModal from "components/ui/modal/AlertModal";
import { JoinContext } from "components/shared/JoinContext";
import { useNavigate } from "react-router-dom";

export const isPasswordValid = (password) => {
  const hasLetter = /[A-Za-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const isLengthValid = password.length >= 8 && password.length <= 20;

  return hasLetter && hasNumber && isLengthValid;
};

const PwJoin = () => {
  const navigate = useNavigate();
  const { joinProps, setJoinProps } = useContext(JoinContext);
  const [joinPw, setJoinPw] = useState("");
  const [confirmPw, setConfirmPw] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleConfirmPwReset = () => {
    setConfirmPw("");
  };

  const handleJoinPwReset = () => {
    setJoinPw("");
  };

  const handleGoNext = () => {
    if (!isPasswordValid(confirmPw)) {
      setIsAlert(true);
      setModalMsg("비밀번호 조건이 맞지 않습니다.");
    } else if (!isPasswordValid(joinPw)) {
      setIsAlert(true);
      setModalMsg("비밀번호 조건이 맞지 않습니다.");
    } else if (confirmPw !== joinPw) {
      setIsAlert(true);
      setModalMsg("비밀번호가 일치 하지 않습니다.");
    } else {
      setJoinProps((prevData) => ({
        ...prevData,
        userPw: joinPw,
      }));
      navigate("/roomJoin");
      // navigate("/roomJoin", {
      //   state: { trigger: "->" },
      // });
    }
  };

  const handlePwInput = (e, setInput) => {
    let input = e.target.value;

    // 한글 및 공백 제거, 나머지는 다 허용
    input = input.replace(/[ㄱ-ㅎ가-힣ㆍ\s]/g, "");

    const maxLength = 20;
    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setInput(input);
  };

  useEffect(() => {
    if (joinProps.social === "Y") {
      setJoinProps((prevData) => ({
        ...prevData,
        userPw: "",
      }));
      navigate("/roomJoin");
    } else {
      setConfirmPw(joinProps.userPw);
      setJoinPw(joinProps.userPw);
    }
  }, []);

  useEffect(() => {
    console.log("Change setting join props:", joinProps);
  }, [joinProps]);

  return (
    <React.Fragment>
      {isAlert && <AlertModal modalMsg={modalMsg} closeModal={setIsAlert} />}
      <div className="wrapper id-join">
        <header>
          <div className="h-btn-box">
            <button type="button" className="back-btn" onClick={handleGoBack} />
          </div>
          <h1>회원가입</h1>
        </header>

        <div className="content">
          <ul className="indicator mt16 mb12">
            <li className="flex-center">1</li>
            <li className="flex-center">2</li>
            <li className="flex-center">3</li>
            <li className="flex-center on">4</li>
            <li className="flex-center">5</li>
            <li className="flex-center">6</li>
          </ul>

          <div className="title-box mb40">
            <h2>
              사용하실
              <br />
              비밀번호를 입력해 주세요.
            </h2>
          </div>

          <div className="form-box-wrap">
            <div className="form-box">
              <div className="tf-box-wrap">
                <div className={`tf-box${confirmPw ? " focus" : ""}`}>
                  <label htmlFor="pw-join">비밀번호 입력</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="password"
                        id="pw-join"
                        className="tf"
                        placeholder="영어와 숫자를 조합한 8~20자"
                        value={confirmPw}
                        onChange={(e) => handlePwInput(e, setConfirmPw)}
                        maxLength={20}
                      />
                      {confirmPw ? (
                        <button type="button" className="remove-btn" onClick={handleConfirmPwReset} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="validity-box-wrap">
                  <ul className={`validity-box mt12${/[A-Za-z]/.test(confirmPw) ? " check" : " "}`}>
                    {/[A-Za-z]/.test(confirmPw) ? (
                      <li>
                        영문<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>
                        영문<i className="ico-check"></i>
                      </li>
                    )}
                  </ul>
                  <ul className={`validity-box mt12${/\d/.test(confirmPw) ? " check" : " "}`}>
                    {/\d/.test(confirmPw) ? (
                      <li>
                        숫자<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>
                        숫자<i className="ico-check"></i>
                      </li>
                    )}
                  </ul>
                  <ul
                    className={`validity-box mt12${confirmPw.length >= 8 && confirmPw.length <= 20 ? " check" : " "}`}
                  >
                    {confirmPw.length >= 8 && confirmPw.length <= 20 ? (
                      <li>
                        8~20자 이내<i className="ico-check"></i>
                      </li>
                    ) : (
                      <li>
                        8~20자 이내<i className="ico-check"></i>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="tf-box-wrap">
                <div className={`tf-box${joinPw ? " focus" : ""}`}>
                  <label htmlFor="pw-check">비밀번호 확인</label>
                  <div className="line-box">
                    <div className="flex-box">
                      <input
                        type="password"
                        id="pw-check"
                        className="tf"
                        placeholder="비밀번호 입력"
                        value={joinPw}
                        onChange={(e) => handlePwInput(e, setJoinPw)}
                        maxLength={20}
                      />
                      {joinPw ? <button type="button" className="remove-btn" onClick={handleJoinPwReset} /> : null}
                    </div>
                  </div>
                </div>
                <ul className={`validity-box mt12${joinPw && joinPw === confirmPw ? " check" : " "}`}>
                  {joinPw && joinPw === confirmPw ? (
                    <li>
                      비밀번호 일치<i className="ico-check"></i>
                    </li>
                  ) : (
                    <li>
                      비밀번호 일치<i className="ico-check"></i>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-button-box">
          <div className="button-box">
            <button
              type="button"
              className={`btn1${confirmPw === joinPw ? " main-color" : " disabled"}`}
              onClick={handleGoNext}
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PwJoin;
